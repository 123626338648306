import { created, shopify, default_option_name, ulsemo } from "../others_constants"
import { distance_units, weight_units } from "../units"

/**
 * @var {String} compare_at_price
 * @var {String} price
 * @var {String} sku
 * @var {Number} inventory_quantity
 * @var {String | null} option1
 * @var {String | null} option2
 * @var {String | null} option3
 * @var {Number} image_id
 */

export default (
  id,
  { weight = 0, length = 0, width = 0, height = 0 } = {},
  languages,
  requires_shipping = false,
  pre_filled_data = {},
  price = 0,
) => ({
  sku: "",
  inventory_quantity: 0,
  image_id: null,
  change_type: created,
  inventory_management: id ? ulsemo : shopify,
  requires_shipping,
  weight,
  length,
  width,
  height,
  distance_unit: distance_units[0], // default to cm
  weight_unit: weight_units[0], // default to g
  ...(id && { id }),
  ...(languages && { translations: {
    ...languages.reduce((tot, lang) => ({
      ...tot,
      [lang]: {
        option1: default_option_name,
        option2: null,
        option3: null,
        compare_at_price: "",
        price
      }
    }), {})
  }}),
  ...(!languages && !pre_filled_data.translations && {
    option1: default_option_name,
    option2: null,
    option3: null,
    compare_at_price: "",
    price
  }),
  ...pre_filled_data
})
