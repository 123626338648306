import { default_language } from "../constants/other";

export default (item, key, languages = [], allow_other_values = true, get_language, searched_object_key, alternative_key, get_value_key = false) => {
  if (!item) return ""
  if (!item[searched_object_key || "translations"] && !get_language) return item[alternative_key || key] || ""

  let available_language;

  [...languages, default_language, Object?.keys(item[searched_object_key || "translations"] || {})[0]].forEach(
    language => available_language =
      available_language ||
      (get_language && item[searched_object_key || "translations"][language] ? language : "") ||
      (item[searched_object_key || "translations"][language]?.[key] ? language : "")
  )

  if (get_language) return available_language

  const actual_value = (
    allow_other_values ?
      (
        item[searched_object_key || "translations"][languages[0]]?.[key] ?
          item[searched_object_key || "translations"][languages[0]] : (
            item[searched_object_key || "translations"][available_language] ||
            item
          ) ||
        {}
      ) : // If it is not necessary to have the correct language
      (item[searched_object_key || "translations"]?.[languages[0]] || {}) // If the value must be in the specified language
  )[key]

  if (get_value_key) return actual_value.value

  return actual_value === 0 ? 0 : (actual_value || item[key] || (item[key] === 0 ? 0 : ""))
}
