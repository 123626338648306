export default {
  single_product_homepages: {
    buy_now: 'Koupit!',
    message_bottom: 'Výchozí text',
    message_middle: 'Výchozí text',
    message_top: 'Výchozí text',
    title: 'Nadpis',
    to_product_button: 'K Produktu',
    to_product_or_variants: 'Další varianty produktu'
  },
  couple_products_homepages: {
    message_bottom: 'Výchozí text',
    message_middle: 'Výchozí text',
    message_top: 'Výchozí text',
    title: 'Nadpis',
  }
}
