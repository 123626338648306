import { initial_state } from "./constants"
import mutations from "./mutations"
import actions from "./actions"
import getters from "./getters"

export default {
  namespaced: true,
  state: { ...initial_state },
  mutations,
  actions,
  getters
}
