import axios from "axios"
import {
  project_server_location,
  project_name
} from "../../../../../functions/api_specific_config"
import check_if_user_in_admin from "../check_if_user_in_admin"
import get_cookie from "../get_cookie"
import { user_cookie } from "../../constants/other"
import is_user_in_global_admin from "../is_user_in_global_admin"
import store from "../../../../store"
import { ADMIN, USER } from "../../../Admin/constants/others_constants"
import check_if_user_in_store_preview from "../check_if_user_in_store_preview"
import get_store_preview_store_id from "../get_store_preview_store_id"

const dev_route = `http://localhost:5001/${project_name}/${project_server_location}`
const prod_route = `https://${project_server_location}-${project_name}.cloudfunctions.net/`
const is_dev_environment = process.env.NODE_ENV === "development" && !JSON.parse(process.env.VUE_APP_TESTING)
const instance = axios.create({
  baseURL: is_dev_environment ? dev_route : prod_route,
  timeout: 60000,
})

instance.interceptors.request.use(config => ({
  ...config,
  ...(check_if_user_in_admin() && {
    headers: {
      ...config.headers,
      admin_cookie: get_cookie(user_cookie),
      ...(is_user_in_global_admin() && { store_id: store.state[ADMIN][USER].selected_project })
    }
  }),
  ...(check_if_user_in_store_preview() && {
    headers: {
      ...config.headers,
      store_id: get_store_preview_store_id()
    }
  })
}), (error) => {
  console.log(error)
  return Promise.reject(error)
})

instance.interceptors.response.use(
  response => {
    if (response.data.message) throw new Error(response.data.message)

    return response
  },
  error => {
    console.log(error)
    return error
  }
)

export const api = instance
