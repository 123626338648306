export const size = "size"
export const transparent = "transparent"
export const half_hour_in_ms = 1.8e+6
export const home = "home"
export const image_dimension_names = {
  desktop: "desktop", tablet: "tablet", mobile: "mobile"
}
export const max_number_of_options = 3

export const description_from_message_splitter = "|message-end|"

export const single_page_translations = {
  "buy_now" : "Buy now!",
  "message_bottom" : "Default text",
  "message_middle" : "Default text",
  "message_top" : "Default text",
  "title" : "Default Title",
  "to_product_button" : "Go to product",
  "to_product_or_variants" : "Checkout out product or variants"
}

export const page_types = {
  couple_products_homepages: "couple_products_homepages",
  single_product_homepages: "single_product_homepages"
}

export const couple_page_translations = {
  title: "Default Title",
}

export const user_stay_signed_in = "user_stay_signed_in"
export const user_liked_products = "user_liked_products"
export const user_basket_items = "user_basket_items"
export const basket_update_timestamp = "basket_update_timestamp"
export const user_selected_language = "user_selected_language"
export const user_viewed_products = "user_viewed_products"
export const user_admin_language = "user_admin_language"
export const update_admin_language = "update_admin_language"
export const user_admin_store_id = "user_admin_store_id"
export const user_admin_seen_mobile_message = "user_admin_seen_mobile_message"
export const countries_requiring_states = ["US", "CA"]
export const default_variant_title = "Default Title"

export const stores_preview_domain = "stores.ulsemo.com"
export const admin_domain = "admin.ulsemo.com"
