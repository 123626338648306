import {
  UPDATE_HEADER_HEIGHT,
  UPDATE_IMPORTANT_MESSAGE_BAR_HEIGHT
} from './constants';

export default {
  [UPDATE_HEADER_HEIGHT](state, new_height) {
    state.header_height = new_height
  },
  [UPDATE_IMPORTANT_MESSAGE_BAR_HEIGHT](state, new_height) {
    state.important_message_bar_height = new_height
  }
}