import {
  UPDATE_PRODUCTS,
  UPDATE_SELECTED_PRODUCT,
  UPDATE_POPULAR_PRODUCTS,
  UPDATE_CATEGORIES
} from "./constants"

export default {
  [UPDATE_PRODUCTS](state, products) {
    state.products = products
  },
  [UPDATE_POPULAR_PRODUCTS](state, popular_products) {
    state.popular_products = popular_products
  },
  [UPDATE_SELECTED_PRODUCT](state, product = null) {
    state.selected_product = product
  },
  [UPDATE_CATEGORIES](state, categories) {
    state.categories = categories
  }
}
