import { image_dimension_names } from "../../../data/other_constants"
import { default_image_src, default_logo_src } from "../constants/other"

export default (image = {}, quality = image_dimension_names.mobile, getting_icon_image = false) => {
  const mobile_image = image.thumb_src || image.thumb_url || image.mobile_src || image?.[quality] || image.src?.[quality] || image.src || image?.image?.[quality]
  const desktop_image = image.src?.[quality] || image?.image?.[quality] || image.src || image.image_url
  const default_image = getting_icon_image ? default_logo_src : default_image_src

  return (quality === image_dimension_names.mobile ? mobile_image : desktop_image) || default_image
}
