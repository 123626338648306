import {
  UPDATE_SHOPIFY_PRODUCTS,
  UPDATE_SHOPIFY_COLLECTIONS,
  UPDATE_PRODUCT_ID_IN_COLLECTION,
  UPDATE_IMPORTED_PRODUCTS,
  UPDATE_IMPORTED_COLLECTIONS,
  FETCH_IMPORTED_PRODUCTS,
  UPDATE_IMPORTED_PRODUCTS_DATA,
  UPDATE_INVENTORY_CHECKS,
  UPDATE_POPULAR_OR_HIGHLIGHTED_PRODUCTS,
  UPDATED_CONTENT_KEYS,
  CLEAR_UPDATED_CONTENT_KEYS,
  SAVE_UPDATED_CONTENT
} from "./constants"
import {
  created, deleted, updated, shopify, removed, added, ADMIN, CONFIGURATION_STORE, CONTENT_STORE, PRODUCTS, CONTENT, CONFIGURATION, imported
} from "../../../constants/others_constants"
import {
  create_new_product,
  update_existing_product,
  delete_variant,
  create_variant,
  delete_image,
  create_image,
  update_variant,
  create_new_collection,
  update_existing_collection,
  delete_collection,
  delete_product,
  delete_collect,
  get_variant_inventory_info,
  update_variant_quantity
} from "../../../constants/endpoints/shopify"
import {
  UPDATE_LOADER,
  FETCH_SHOPIFY_DATA,
  SHOPIFY_DATA_UPDATED,
  IMPORTED_DATA_UPDATED,
  ADD_GLOBAL_ERROR,
  UPDATE_SMALL_LOADER,
  FETCH_CONFIGURATION_DATA,
  UPDATE_IMAGES_FOR_DELETE,
  CLEAR_IMAGES_FOR_DELETE,
  SET_LOADING_MESSAGE,
} from "../constants"
import { update_product, update_collection, fetch_products_data, update_inventory_checks } from "../../../constants/endpoints/firebase"
import un_bind from "../../../../Shared/methods/un_bind"
import {
  FETCH_CONTENT_DATA, UPDATE_HIGHLIGHTED_PRODUCTS, UPDATE_POPULAR_PRODUCTS, UPDATE_CONTENT_DATA, SAVE_CONTENT,
} from "../content/constants"
import remove_storage_file from "../../../methods/remove_storage_file"
import { toggle_product_to_store_assignment } from "../../../constants/endpoints/other"
import upload_product_images from "../../../methods/upload_product_images"
import { translate } from "../../../../Shared/translator"
import filter_out_empty_options from "../../../methods/filter_out_empty_options"

export default {
  /**
   * Imported/Ulsemo data actions
   */
  async [UPDATE_IMPORTED_PRODUCTS]({ state, commit, dispatch, rootState }, [products]) {
    commit(`${ADMIN}/${UPDATE_LOADER}`, true, { root: true })
    let responses = []
    let deleting_product = false

    try {
      commit(`${ADMIN}/${SET_LOADING_MESSAGE}`, await translate("loading_messages.uploading_product_images"), { root: true })
      const products_with_updated_images = await upload_product_images(products)
      commit(`${ADMIN}/${SET_LOADING_MESSAGE}`, await translate("loading_messages.uploading_products"), { root: true })

      const product_update_promises = products_with_updated_images
        .reduce((total, product, index) => {
          // filter out product option values of value null
          product.options = filter_out_empty_options(product.options)

          if (
            product.change_type === updated ||
            product.change_type === created ||
            product.variants.some(({ change_type }) => change_type) ||
            (product.images && product.images.some(({ change_type }) => change_type))
          ) {

            // Filter out deleted variants and remove their options from product options
            if (product.variants.some(({ change_type }) => change_type === deleted)) {
              product.variants = product.variants.filter(({ change_type }) => change_type !== deleted)
              product.options = product.options.map(option => {
                return {
                  ...option,
                  values: option?.values?.filter(val =>
                    product.variants.some(({ option1, option2, option3 }) => option1 === val || option2 === val || option3 === val )
                  ),
                  ...(option.translations && {
                    ...Object
                      .entries(option.translations)
                      .reduce((tot, [key, value]) => ({
                        ...tot,
                        [key]: {
                          ...value,
                          values: value.values?.filter(val =>
                            product.variants.some(({ option1, option2, option3 }) => option1 === val || option2 === val || option3 === val )
                          )
                        }
                      }), {})
                  })
                }
              })
            }
            // Filter out deleted translation keys
            const available_language_keys = Object.keys(rootState[ADMIN][CONFIGURATION].translations)
            product.translations = product.translations && Object
              .entries(product.translations)
              .reduce((translations, [lang, value]) => ({
                ...translations,
                [lang]: available_language_keys.includes(lang) ? value : undefined
              }), {})
            product.variants = product.variants.map(variant => ({
              ...variant,
              ...(
                variant.translations &&
                {
                  translations: Object
                    .entries(variant.translations)
                    .reduce((translations, [lang, value]) => ({
                      ...translations,
                      [lang]: available_language_keys.includes(lang) ? value : undefined
                    }), {})
                }
              )
            }))
            product.options = product.options.map(option => ({
              ...option,
              ...(
                option.translations &&
                {
                  translations: Object
                    .entries(option.translations)
                    .reduce((translations, [lang, value]) => ({
                      ...translations,
                      [lang]: available_language_keys.includes(lang) ? value : undefined
                    }), {})
                }
              )
            }))

            // Log cross product assignment to store
            if (
              product.change_type === created &&
              product.project_id
            ) toggle_product_to_store_assignment({
              product_id: product.id,
              assigning: true
            })
    
            total.push(update_product(index, product))
          }
          else if (product.change_type === deleted) {
            total.push(update_product(index, product, true))
            deleting_product = true

            const product_images = product
              ?.images
              .filter(({ src }) => typeof src === "object" || !src?.includes("/static/user_guide/"))
              ?.flatMap(({ src, thumb_src }) => thumb_src ? [src, thumb_src] : [...Object.values(src)])
            
            // Log cross product un-assignment from store
            if (product.project_id) toggle_product_to_store_assignment({
              product_id: product.id,
              assigning: false
            })

            // Add all product images to get deleted
            commit(
              `${ADMIN}/${UPDATE_IMAGES_FOR_DELETE}`,
              { images: product_images, store_type: PRODUCTS },
              { root: true }
            )
            if (rootState[ADMIN][CONTENT].highlighted_products.includes(product.id)) dispatch(
              UPDATE_POPULAR_OR_HIGHLIGHTED_PRODUCTS, [product.id, false]
            )
            if (rootState[ADMIN][CONTENT].popular_products.includes(product.id)) dispatch(
              UPDATE_POPULAR_OR_HIGHLIGHTED_PRODUCTS, [product.id]
            )
          }
    
          return total
        }, [])
  
      responses = await Promise.all(product_update_promises)

      // Save Content store data if updated during product editing
      if (state.updated_content_keys.length) dispatch(SAVE_UPDATED_CONTENT)
    } catch (error) {
      commit(`${ADMIN}/${ADD_GLOBAL_ERROR}`, error, { root: true })
    }

    // Re-fetch content and config data because when deleting a product, its occurrences are being deleted across the entire store
    if (deleting_product) {
      await Promise.all([
        await dispatch(`${CONTENT_STORE}/${FETCH_CONTENT_DATA}`, false, { root: true }),
        await dispatch(`${CONFIGURATION_STORE}/${FETCH_CONFIGURATION_DATA}`, false, { root: true })
      ])
    }

    // Delete all removed images
    state.images_to_delete.forEach(async image_url => {
      await remove_storage_file(image_url)
      commit(`${ADMIN}/${UPDATE_IMAGES_FOR_DELETE}`, { images: [image_url], store_type: PRODUCTS }, { root: true })
    });

    if (responses.some(response => !response)) {
      commit(`${ADMIN}/${UPDATE_LOADER}`, false, { root: true })
      commit(`${ADMIN}/${SET_LOADING_MESSAGE}`, "", { root: true })
    }
  },
  async [UPDATE_IMPORTED_COLLECTIONS]({ commit, dispatch, state }, [collections, fetch_data]) {
    commit(`${ADMIN}/${UPDATE_LOADER}`, true, { root: true })
    let responses = []

    try {
      const imported_collections_promises = collections.reduce((total, collection, index) => {
        // filter out product option values of value null
        collection.products = (collection.products || []).map(product => ({
          ...product,
          options: filter_out_empty_options(product.options)
        }))

        if (collection.change_type === updated || collection.change_type === created) {
          total.push(update_collection(index, collection))
        }
        else if (collection.change_type === deleted) total.push(update_collection(index, collection, true))
  
        return total
      }, [])
  
      responses = await Promise.all(imported_collections_promises)
    }  catch (error) {

      commit(`${ADMIN}/${ADD_GLOBAL_ERROR}`, error, { root: true })
    }

    state.images_to_delete.forEach(async image_url => {
      await remove_storage_file(image_url)
      commit(`${ADMIN}/${UPDATE_IMAGES_FOR_DELETE}`, { images: [image_url], store_type: PRODUCTS }, { root: true })
    });

    if (responses.every(state => state) || !responses.length) {
      if (fetch_data) await dispatch(FETCH_IMPORTED_PRODUCTS)
      commit(`${ADMIN}/${IMPORTED_DATA_UPDATED}`, false, { root: true })
    }
    commit(`${ADMIN}/${UPDATE_LOADER}`, false, { root: true })
  },
  /**
   * Shopify actions
   */
  async [UPDATE_SHOPIFY_PRODUCTS]({ commit, state, dispatch }, [products, is_last_call = true]) {
    commit(`${ADMIN}/${UPDATE_LOADER}`, true, { root: true })

    const product_update_promises = products
      .reduce((total, product) => {
        const deleted_variants = product.variants.filter(({ change_type }) => change_type === deleted)
        const created_variants = product.variants.filter(({ change_type }) => change_type === created)
        const updated_variants = product.variants.filter(({ change_type }) => change_type === updated)
        const deleted_images = product.images.filter(({ change_type }) => change_type === deleted)
        const created_images = product.images.filter(({ change_type }) => change_type === created)
        const removed_collections = product.collections.filter(({ collection_change_type }) => collection_change_type === removed)

        if (product.change_type === created) {
          // filter out product option values of value null
          product.options = product.options.map(option => ({ ...option, values: option.values.filter(val => val !== null) }))

          // Create product
          const product_creation_promise = new Promise(async (resolve) => {
            const { data: { product: incoming_product }} = await create_new_product({ ...product, id: undefined })
            // We need to asign the newly created product to the collection, so that it can be updated with it (if it is in any)
            await Promise.all(created_images.map(image =>
              create_image(incoming_product.id, { ...image, attachment: image.attachment.split("base64,")[1]})
            ))

            product.variants.forEach((variant, index) => {
              if (variant.update_quantity) {
                const variant_quantity_update_promise = new Promise(async (resolve) => {
                  const correct_id = incoming_product.variants[index].inventory_item_id
                  const { data } = await get_variant_inventory_info(correct_id)
                  const corrent_variant = data.find(({ inventory_item_id }) => inventory_item_id === correct_id)
                  
                  await update_variant_quantity(corrent_variant, variant.inventory_quantity)
                  resolve()
                })
                total.push(variant_quantity_update_promise)
              }
            })

            product.collections.forEach(({ id: collection_id }) => 
              commit(UPDATE_PRODUCT_ID_IN_COLLECTION, [shopify, { ...product, id: incoming_product.id, old_id: product.id }, collection_id])
            )
            resolve()
          })
          total.push(product_creation_promise)
        }
        else {
          // Removing separately updated product keys and re-mapping object to not include key: undefined
          // product = un_bind({ ...product, variants: undefined, options: undefined, images: undefined })
          product = un_bind({ ...product, images: undefined })

          if (product.change_type === updated) total.push(update_existing_product(product))
          else if (product.change_type === deleted) total.push(delete_product(product))

          if (product.change_type !== deleted) {
            // Update variants
            if (deleted_variants.length) deleted_variants.forEach(({ id }) => total.push(delete_variant(product.id, id)))
            if (created_variants.length) created_variants.forEach(variant => {
              delete variant.inventory_quantity
              total.push(create_variant(product.id, variant))
            })
            if (updated_variants.length) updated_variants.forEach(variant => {
              total.push(update_variant({ ...variant, inventory_quantity: undefined, old_inventory_quantity: undefined}))

              if (variant.update_quantity) {
                const variant_quantity_update_promise = new Promise(async (resolve) => {
                  const { data } = await get_variant_inventory_info(variant.inventory_item_id)
                  const corrent_variant = data.find(({ inventory_item_id }) => inventory_item_id === variant.inventory_item_id)
                  
                  await update_variant_quantity(corrent_variant, variant.inventory_quantity)
                  resolve()
                })
                total.push(variant_quantity_update_promise)
              }
            })
            
            // Update images
            if (deleted_images.length) deleted_images.forEach(({ id }) => total.push(delete_image(product.id, id)))
            if (created_images.length) created_images.forEach(image => total.push(
              create_image(product.id, { ...image, attachment: image.attachment.split("base64,")[1]})
            ))
          }
        }

        // Update collects
        if (removed_collections.length) removed_collections.forEach(({ id: coll_id }) => {
          state.shopify_collects
            .filter(({ collection_id, product_id }) => collection_id === coll_id && product_id === product.id)
            .forEach(collect => total.push(delete_collect(collect)))
        })

        return total
      }, [])

    try {
      await Promise.all(product_update_promises)

      // Save Content store data if updated during product editing
      if (state.updated_content_keys.length) dispatch(SAVE_UPDATED_CONTENT)

      if (is_last_call) {
        await dispatch(`${ADMIN}/${FETCH_SHOPIFY_DATA}`, undefined, { root: true })
        commit(`${ADMIN}/${UPDATE_LOADER}`, false, { root: true })
      }
      commit(`${ADMIN}/${SHOPIFY_DATA_UPDATED}`, false, { root: true })
    } catch (error) {
      commit(`${ADMIN}/${UPDATE_LOADER}`, false, { root: true })
      console.log(error)
    }
  },
  async [UPDATE_SHOPIFY_COLLECTIONS]({ commit, dispatch }, [collections, is_last_call = true]) {
    commit(`${ADMIN}/${UPDATE_LOADER}`, true, { root: true })

    const collection_update_promises = collections.reduce((total, collection) => {
      // filter out product option values of value null
      collection.products = collection.products.map(product => ({
        ...product,
        options: product.options.map(option => ({ ...option, values: option.values.filter(val => val !== null) }))
      }))

      // Update product data
      if (collection.change_type === created) {
        // ID was there only for FE purposes
        delete collection.id

        /**
         * When creating the collection, the products get automatically assgined to both
         * collects and products, however when editing a collection, they only get assigned
         * to products, so we need to add them to collects here
         */
        collection.collects = collection.products.map(({ id }) => ({ product_id: id }))

        total.push(create_new_collection({ ...collection, products: undefined }))
      }
      else if (collection.change_type === updated) {
        /**
         * Delete collections from products to ensure non-Converting circular structure and
         * assign them as collects to the updated collection
         */
        collection.collects = collection.products.reduce((tot, { id, collection_change_type }) => {
          if (collection_change_type === added) tot.push({ product_id: id })
          return tot
        }, [])

        delete collection.products
        total.push(update_existing_collection(collection))
      }
      else if (collection.change_type === deleted) {
        total.push(delete_collection(collection))
      }

      return total
    }, [])

    try {
      await Promise.all(collection_update_promises)

      if (is_last_call) {
        await dispatch(`${ADMIN}/${FETCH_SHOPIFY_DATA}`, undefined, { root: true })
        commit(`${ADMIN}/${UPDATE_LOADER}`, false, { root: true })
      }
    } catch (error) {
      commit(`${ADMIN}/${UPDATE_LOADER}`, false, { root: true })
      console.log(error)
    }
  },
  async [FETCH_IMPORTED_PRODUCTS]({ commit, rootState }) {
    const { data: { popular_products, highlighted_products, header_items, ...products_data } } = await fetch_products_data()

    commit(`${ADMIN}/${CLEAR_IMAGES_FOR_DELETE}`, { store_type: PRODUCTS }, { root: true })
    commit(UPDATE_IMPORTED_PRODUCTS_DATA, products_data)

    // Check if Content data already fetched
    if (!rootState[ADMIN][CONTENT].footer_content) commit(
      `${CONTENT_STORE}/${UPDATE_CONTENT_DATA}`,
      { popular_products, highlighted_products, header_items },
      { root: true }
    )
  },
  async [UPDATE_INVENTORY_CHECKS]({ commit, state }) {
    commit(`${ADMIN}/${UPDATE_SMALL_LOADER}`, true, { root: true })

    try {
      await update_inventory_checks(state.inventory_checks)
    } catch (error) {
      commit(`${ADMIN}/${ADD_GLOBAL_ERROR}`, error, { root: true })
    }

    commit(`${ADMIN}/${UPDATE_SMALL_LOADER}`, false, { root: true })
  },
  async [UPDATE_POPULAR_OR_HIGHLIGHTED_PRODUCTS](
    { commit },
    [product_id, add_to_popular = true]
  ) {
    commit(
      `${
        CONTENT_STORE
      }/${
        add_to_popular ? UPDATE_POPULAR_PRODUCTS : UPDATE_HIGHLIGHTED_PRODUCTS
      }`, product_id, { root: true }
    )

    commit(
      UPDATED_CONTENT_KEYS,
      add_to_popular ? "popular_products" : "highlighted_products"
    )
    commit(`${ADMIN}/${IMPORTED_DATA_UPDATED}`, true, { root: true })
  },
  async [SAVE_UPDATED_CONTENT](
    { dispatch, state, commit, rootState },
  ) {
    await dispatch(
      `${CONTENT_STORE}/${SAVE_CONTENT}`, [
        state.updated_content_keys.reduce((tot, key) => ({
          ...tot,
          [key]: rootState[ADMIN][CONTENT][key]
        }), {}),
        false,
        state.updated_content_keys
      ], { root: true }
    )

    commit(CLEAR_UPDATED_CONTENT_KEYS)
  },
}
