/**
 * 
 * !!!!!! Make sure to always also update the default_translations_object.js in functions/constants and any other language translations
 * 
 */

export default {
  about_us: "About us",
  add_liked: "Please like some items",
  add_to_cart: "Add to cart!",
  address_first: "Street and Number",
  address_second: "Additional info: Apt. number, Floor, ...",
  apply: "Apply",
  back_button: "Back",
  buy_now: "Buy now!",
  business_id: "Business ID",
  cancel_payment_method: "Change payment method",
  cancel_selection_of_group: "Cancel selection",
  checkout: "Checkout",
  city: "City",
  clear_basket: "Clear basket",
  clear_filters: "Clear filters",
  company_purchase_checkbox: "This is a company purchase",
  company: "Company name",
  completed_orders: "Completed orders",
  contact_us: {
    email_label: "Your email",
    email_placeholder: "your@email.com",
    fullname_label: "Your name",
    fullname_placeholder: "John Doe",
    message_fail: "Unfortunately there was an error, please try again or reload page.",
    message_label: "Your message",
    message_success: "Your message has been sent",
    send_button: "Send",
    title: "Contact us",
    validation_fail: "Please fill out all fields",
    store_info_title: "Store info:",
    businessID: "Business ID",
    vatID: "Vat ID",
    bank_account: "Bank account"
  },
  cookie_consent_button: "I Agree",
  cookie_message: "This page uses cookies to enhance your visit and provide best possible user experience.",
  country: "Country",
  country_doesnt_match_method: "Selected country doesnt match chosen shipping country.",
  currency_code: "USD",
  currency_symbol: "$",
  customer_reviews: {
    average_rating: "Average rating",
    customer_reviews: "Customer Reviews",
    number_of_ratings: "Number of ratings"
  },
  default_title: "Welcome to our page!",
  discount_code: "Discount code",
  discount_code_error: "Discount code does not exist, is expired or can not be applied.",
  email: "Email",
  empty_basket: "There are no products in your basket.",
  excluding_tax: "excl. tax",
  tax_title: "Tax",
  finish_order: "Finish order",
  first_name: "First name",
  free: "Free",
  further_reading: "Product description",
  go_to_basket: "Go to basket",
  go_to_product: "Open",
  here_link: "HERE",
  highlighted_products: {
    button: "Go to product",
    title: "Selected products"
  },
  hover_to_zoom: "Hover to zoom",
  including_tax: "incl. tax",
  order_page: {
    title: "Order detail",
    order_not_found: "The order you are looking for could not be found.",
    order_number: "Order number",
    created_at: "Created at",
    order_id: "Order ID",
    shipping_address: "Shipping address",
    billing_address: "Billing address",
    products_title: "Products summary",
    price_title: "Price summary",
    taxes: "Taxes",
    subtotal: "Subtotal",
    total: "Total",
    shipping_price_title: "Shipping",
    tracking_title: "Tracking",
    tracking_no_value_message: "This order does not have tracking enabled.",
    download_invoice_for_order: "Download order invoice"
  },
  last_name: "Last name",
  location: "Location",
  log_in_message: "Log in for better user experience or checkout as a guest:",
  log_in: "Log in",
  main_page_link_button: "Explore our offers",
  main_page_link_message: "We have much more for you, check out our entire product lineup and see for yourself.",
  no_location_selected: "No location selected",
  no_products: "No products found",
  alternate_shipping_methods: "Other shipping methods:",
  order_summary: "ORDER SUMMARY:",
  out_of_stock: "Product out of stock",
  pay_for_group: "Pay for selected",
  pay_via_cash: "Pay via cash/card on pickup",
  pay_via_bank_transfer: "Pay via bank transfer",
  period_of_delivery: "days",
  phone: "Phone number",
  price: "Price",
  price_has_been_adjusted_by_server: "There are only {number} products in stock.",
  product_categories_explore: "Explore",
  product_categories_message: "Some info about the shop, products and related information.",
  product_categories_title: "Discover our products",
  products_from_cross_store_are_unavailable: "Cross-sold products from this store are currently not available.",
  products_missing: "Unfortunately, the products you have chosen are missing",
  pwa_prompt_message: "Would you like to add our web application to your home screen?",
  pwa_prompt_message_button: "Add",
  quantity: "Quantity",
  random_items: "Popular items:",
  recently_viewed: "Recently viewed:",
  remove_group_products: "Remove all",
  remove_item: "Remove",
  same_as_shipping: "Is shipping address same as billing?",
  search_title: "Search our products",
  select_group: "Please select product group to finish the checkout process.",
  select_variant_message: "Please select a variant",
  shipping_info: {
    shipping_option: "Choose a shipping option:",
    shipping_method_default: "Shipping method",
    estimated_delivery_message: "Estimated delivery in {estimated_days} days",
    days_estimate: "~{estimated_days} days",
    chosen_method: "Chosen method",
    ship_to: "Ship to",
    add_amount_for_better_shipping_discount: "Add {amount} more for {discount_title}",
    shipping_price_title: "Shipping",
    shipping_default_suffix: "off shipping"
  },
  shipping_option: "Choose a shipping option:",
  shopify_buy_button: "Securely checkout order",
  shopping_basket_title: "Your shopping cart:",
  similar_items: "You might also like:",
  state: "State",
  subtotal: "Subtotal",
  tax_percantage: 20,
  thank_you_page: {
    email: "Confirmation mail has been sent to your e-mail address",
    first_message: "Your order has been received and is being processed. You will be contacted as soon as the package is shipped.",
    homepage_link: "Go to homepage",
    homepage_redirect: "Go to homepage",
    order_number: "Your order number is: ",
    second_message: "Thank you for shopping with us!",
    go_to_order: "Go to order",
    here_link: "HERE",
    bank_transfer: "Bank transfer",
    on_pickup: "Cash/card on pickup",
    thank_you_message: "Thank you for your order!",
    bank_transfer_payment_method_message: "To complete your order, please transfer the order amount to the bank account below:",
    on_pickup_payment_method_message: "You have chosen to pay on pick up. Come collect your order at your convenience to our store located at:",
    chosen_shipping_option: "Chosen shipping option",
    chosen_payment_method: "Chosen payment method",
    amount_to_be_paid: "Amount to be paid",
    bank_account: "Bank account",
    iban: "IBAN",
    swift: "BIC (SWIFT)",
    reference_number: "Reference number"
  },
  these_products_are_cross_sold: "These products are being cross-sold from another store.",
  total: "Total",
  touch_to_zoom: "Touch to zoom",
  VAT_id: "VAT ID",
  view_your_order_message: "Show order detail for: <b>{products}</b> by clicking",
  website_name: "Our website",
  you_need_to_choose_shipping_method: "You need to choose a shipping method.",
  zip: "Zip"
}
