export default {
  bottom_banner : {},
  bottom_paragraphs : [],
  enable_about_page : false,
  texts : {},
  tile_pairs : [],
  top_banner : {},
  top_paragraph : {},
  use_laser : false
}

