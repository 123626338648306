// Store names
export const MARKETPLACE = "Marketplace"
export const MARKETPLACE_PRODUCTS = "Products"
export const MARKETPLACE_PRODUCTS_STORE = `${MARKETPLACE}/${MARKETPLACE_PRODUCTS}`

// Financial
export const DEFAULT_MARKETPLACE_CURRENCY = "USD"

// Special routes
export const POPULAR_PRODUCTS = "popular_products"

// Other
export const LOADING_TIMEOUT = 3000 // ms
export const ALL_CATEGORIES_OBJECT = {
  name: "All categories",
  link:  "/categories"
}
export const MIN_SEARCH_CHARACTERS = 2
export const search_attributes = ["id", "categories", "images", "title", "variants", "tags"]
