// The Vue build version to load with the `import` command
import Vue from "vue"
import "intersection-observer"
import axios from "axios"
import store from "./store"
import App from "./App"
import router from "./router"
import "./google_tracking"
import "./registerServiceWorker"
import "./styles/global_styles.scss"

// Polyfills
import "array-flat-polyfill";

// Filters
import insert_variables_into_string from "./applications/Client/methods/insert_variables_into_string"

// Directives
import { ObserveVisibility } from "vue-observe-visibility"
import click_outside_directive from "./applications/Shared/methods/click_outside_directive"

// Make axios accessible from outside files
window.axios = axios

// Activate Filters and Directives
Vue.filter("insert_variables_into_string", insert_variables_into_string)
Vue.directive("click-outside", click_outside_directive)
Vue.directive("observe-visibility", ObserveVisibility)

// Initialize EventHub
export const event_hub = new Vue()

Vue.config.productionTip = false

/* eslint-disable no-new */

new Vue({
  router: router(),
  store,
  render: h => h(App)
}).$mount("#app")

window.Vue = Vue // Needed for i18n
