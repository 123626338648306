import Vue from "vue"
import { UPDATE_ORDERS, UPDATE_DISCOUNT_CODE, UPDATE_PRICE_RULE, UPDATE_BASE_FINANCIALS, UPDATE_LATEST_ORDERS } from "./constants"

export default {
  [UPDATE_ORDERS](state, [orders, total] = [null, null]) {
    state.orders = orders
    if (total) state.number_of_orders = total
  },
  [UPDATE_DISCOUNT_CODE](state, [type, discount_code]) {
    Vue.set(state[`${type}_discount_codes`], discount_code.id, discount_code)
  },
  [UPDATE_PRICE_RULE](state, [type, price_rule]) {
    Vue.set(state[`${type}_price_rules`], price_rule.id, price_rule)
  },
  [UPDATE_BASE_FINANCIALS](state, financials) {
    state.base_financials = financials
  },
  [UPDATE_LATEST_ORDERS](state, latest_orders) {
    state.latest_orders = latest_orders
  },
}
