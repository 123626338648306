import Vue from "vue"
import { user_basket_items } from "../../../../data/other_constants"
import adjust_basket_data_to_not_include_duplicates from "../../../Client/methods/adjust_basket_data_to_not_include_duplicates"
import validate_basket from "../../../Client/methods/validate_basket"
import shopify_product_availability_updater from "../../../Client/methods/shopify_product_availability_updater"
import update_shopify_checkout_line_items from "../../../Client/methods/update_shopify_checkout_line_items"
import { ga_event_actions, ga_event_categories } from "../../constants/ga_constants"
import get_correct_translation_value from "../../methods/get_correct_translation_value"
import get_message_or_types_from_product_description from "../../methods/get_message_or_types_from_product_description"
import {
  FIRST_LOAD, UPDATE_MESSAGE, UPDATE_USER_CREDENTIALS, UPDATE_MOBILE_VIEW, UPDATE_DISCOUNT_CODE,
  TOGGLE_PREVIEW_BASKET, UPDATE_BASKET_ITEMS, SET_WHOLE_BASKET, ADD_BASKET_ITEM, DELETE_BASKET_ITEM, UPDATE_BASKET_LOADER,
  CHANGE_ADJUSTED_STATE_OF_PRODUCT, CHANGE_QUANTITY_OF_PRODUCT, REMOVE_PURCHASE_ITEM_FROM_BASKET, UPDATE_MOUSE_OVER_BASKET,
  UPDATE_CURRENCY_RATES, UPDATE_ORDER_LINKS, UPDATE_BASKET_GROUPS, UPDATE_SELECTED_BASKET_GROUP, CLEAR_ORDER_LINKS,
  SET_STORE_ID
} from "./constants"
import get_image_src from "../../methods/get_image_src"
import { get_ga_instance } from "../../../../google_tracking"

export default {
  [SET_STORE_ID](state, store_id) {
    state.store_id = store_id
  },
  [UPDATE_ORDER_LINKS](state, links) {
    state.order_links = [...state.order_links, links]
  },
  [CLEAR_ORDER_LINKS](state) {
    state.order_links = []
  },
  [FIRST_LOAD](state) {
    state.first_load = !state.first_load
  },
  [UPDATE_MESSAGE](state, { type, message }) {
    state[`${type}_message`] = message
  },
  [UPDATE_USER_CREDENTIALS](state, user_credentials) {
    state.user_credentials = user_credentials
  },
  [UPDATE_MOBILE_VIEW](state, is_mobile_device) {
    state.is_mobile_device = is_mobile_device
  },
  [UPDATE_DISCOUNT_CODE](state, discount_code = {}) {
    state.discount_code = discount_code
  },
  [TOGGLE_PREVIEW_BASKET](state, [new_state, send_event, is_basket_call]) {
    if (state.show_preview_basket !== new_state && (is_basket_call ? true : !state.mouse_over_basket)) {
      // GA event
      if (send_event) get_ga_instance().then((ga) => {
        ga.track(ga_event_categories.basket, ga_event_actions.preview)
      })
      state.show_preview_basket = new_state
      state.mouse_over_basket = false
    }
  },
  [CHANGE_ADJUSTED_STATE_OF_PRODUCT](state, item) {
    const id_in_basket = state.basket_items.findIndex(
      el => el.id === item.id && el.chosen_size === item.chosen_size
    )

    Vue.set(state.basket_items[id_in_basket], item.has_product_adjusted_amount, false)
  },
  [REMOVE_PURCHASE_ITEM_FROM_BASKET](state, item) {
    Vue.set(
      state,
      "basket_items",
      state.basket_items.filter(({ variant_id }) => variant_id !== item.variant_id)
    )
  },
  async [CHANGE_QUANTITY_OF_PRODUCT](state, item) {
    state.basket_loader = true
    let updated_basket_data;

    if (state.use_shopify_data) updated_basket_data = await shopify_product_availability_updater(item)
    else {
      const { data } = await validate_basket(
        state.basket_items.map(product => {
          if (product.id === item.id) product.quantity = item.quantity

          return product
        })
      )
      updated_basket_data = data
    }

    Vue.set(state, "basket_items", updated_basket_data)
    localStorage.setItem(
      user_basket_items,
      JSON.stringify(updated_basket_data)
    );
    state.basket_loader = false
  },
  [UPDATE_BASKET_ITEMS](state, basket_data) {
    Vue.set(state, "basket_items", basket_data)
  },
  [SET_WHOLE_BASKET](state, basket_items) {
    Vue.set(state, "basket_items", basket_items?.filter(({ language }) => language) || [])
  },
  async [ADD_BASKET_ITEM](state, [product, language]) {
    state.basket_loader = true

    get_ga_instance().then((ga) => {
      ga.track(ga_event_categories.basket, {
        actions: ga_event_actions.add,
        product_name: get_correct_translation_value(product, "title", [language]),
        product_id: product.id
      })
    })

    let updated_basket_data;

    if (state.use_shopify_data) updated_basket_data = await shopify_product_availability_updater(product)
    else {
      const selected_variant = product.variants.find(({ id }) => product.product_variant.id === id)
      const variant_image = product?.images?.find(({ id }) => selected_variant.image_id === id)
      const basket_without_duplicates = adjust_basket_data_to_not_include_duplicates(
        [
          {
            id: product.id,
            variant_id: product.product_variant.id,
            price: product.product_variant.price,
            compare_at_price: product.product_variant.compareAtPrice,
            prices: product.prices,
            title: product.title,
            handle: product.handle,
            resale_percentage: product.resale_percentage,
            cross_base_currency: product.cross_base_currency,
            language,
            variant_options: product.options.map((option, ind) => ({
              name: get_correct_translation_value(option, "name", [language]),
              value: get_correct_translation_value(selected_variant, `option${ind + 1}`, [language])
            })),
            ideal_quantity: selected_variant.ideal_quantity,
            height: selected_variant.height,
            weight: selected_variant.weight,
            width: selected_variant.width,
            length: selected_variant.length,
            weight_unit: selected_variant.weight_unit,
            distance_unit: selected_variant.distance_unit,
            requires_shipping: selected_variant.requires_shipping,
            quantity: product.quantity,
            message: get_message_or_types_from_product_description(product.body_html, true),
            collections: product.collections,
            categories: product.categories,
            project_id: product.project_id,
            image: get_image_src(variant_image || product.images?.[0]),
            project_domain: product.project_domain,
            project_name: product.project_name
          },
          ...state.basket_items
        ],
        state.use_shopify_data
      )

      Vue.set(state, "basket_items", basket_without_duplicates)

      const { data } = await validate_basket(basket_without_duplicates)
      updated_basket_data = data
    }

    Vue.set(state, "basket_items", updated_basket_data)
    state.show_preview_basket = true

    setTimeout(() => { state.show_preview_basket = state.mouse_over_basket }, 3000);
    localStorage.setItem(
      user_basket_items,
      JSON.stringify(updated_basket_data)
    );
    state.basket_loader = false
  },
  async [DELETE_BASKET_ITEM](state, item) {
    state.basket_loader = true

    get_ga_instance().then((ga) => {
      ga.track(ga_event_categories.basket, {
        actions: ga_event_actions.delete,
        product_name: get_correct_translation_value(item, "title"),
        product_id: item.id
      })
    })

    let filtered_basket
    if (state.use_shopify_data) filtered_basket = await update_shopify_checkout_line_items(item, "remove")
    else {
      filtered_basket = state.basket_items.filter(({ variant_id }) => variant_id !== item.variant_id)

      if (filtered_basket.length) {
        const { data } = await validate_basket(filtered_basket)
        filtered_basket = data
      }
    }

    Vue.set(state, "basket_items", filtered_basket)
    localStorage.setItem(
      user_basket_items,
      JSON.stringify(filtered_basket)
    );
    state.basket_loader = false
  },
  [UPDATE_BASKET_LOADER](state, value) {
    state.basket_loader = value
  },
  [UPDATE_MOUSE_OVER_BASKET](state, value) {
    state.mouse_over_basket = value
  },
  [UPDATE_CURRENCY_RATES](state, [currency, rates]) {
    Vue.set(state, "currency_rates", {
      ...state.currency_rates,
      [currency]: rates
    })
  },
  [UPDATE_BASKET_GROUPS](state, groups) {
    state.basket_product_groups = groups
  },
  [UPDATE_SELECTED_BASKET_GROUP](state, group) {
    state.selected_group = group
  },
}
