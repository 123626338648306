export default (options = []) => options?.map(option => ({
  ...option,
  values: option?.values?.filter(val => val !== null),
  ...(option.translations && {
    ...Object
      .entries(option.translations)
      .reduce((tot, [key, option_translation_value]) => {
        const sanitized_option_values = option_translation_value?.values?.length ?
          option_translation_value?.values :
          Object.values(option_translation_value?.values || {})

        return ({
          ...tot,
          [key]: {
            ...option_translation_value,
            values: sanitized_option_values.filter(val => val !== null)
          }
        })
      }, {})
  })
}))
