import Vue from "vue"
import {
  ADD_TO_STORE,
  TOGGLE_MENU,
  IMPORTED_DATA_UPDATED,
  SHOPIFY_DATA_UPDATED,
  UPDATE_LOADER,
  UPDATE_DATA_LOAD_STATE,
  UPDATE_ERROR_LIST,
  CLEAR_ERRORS_SECTION,
  CONTENT_DATA_UPDATED,
  CONFIGURATION_DATA_UPDATED,
  UPDATE_SMALL_LOADER,
  FINANCE_DATA_UPDATED,
  ADD_GLOBAL_ERROR,
  DELETE_GLOBAL_ERROR,
  USER_EDITED_SPECIAL_HOMEPAGES,
  UPDATE_GUIDE_INFO,
  UPDATE_GUIDE_OPEN_STATE,
  CLEAR_STORE,
  SET_GLOBAL_MOMENT_LIBRARY_VARIABLE,
  UPDATE_ERROR_LIST_TITLE,
  UPDATE_IMAGES_FOR_DELETE,
  CLEAR_IMAGES_FOR_DELETE,
  UPDATE_SUBSCRIPTION_PAYED,
  REMOVE_NOTIFICATION,
  UPDATE_NOTIFICATIONS,
  SET_LOADING_MESSAGE,
  UPDATE_ACTIVE_SIDE_MENU_ICON,
  UPDATE_ADMIN_LANGUAGE
} from "./constants"
import { base_state as configuration_state } from "./configuration/constants"
import { base_state as content_state } from "./content/constants"
import { base_state as products_state } from "./products/constants"
import { base_state as finance_state } from "./finance/constants"
import { base_state as social_state } from "./social/constants"
import { CONFIGURATION, PRODUCTS, FINANCE, SOCIAL, CONTENT } from "../../constants/others_constants"

const base_states_mapper = {
  [PRODUCTS]: products_state,
  [CONFIGURATION]: configuration_state,
  [FINANCE]: finance_state,
  [SOCIAL]: social_state,
  [CONTENT]: content_state
}

export default {
  [ADD_TO_STORE](state, [route, key, value]){
    const call_vue_set_with_dinamic_params = new Function(
      "Vue",
      "state",
      "route",
      "key",
      "value",
      `Vue.set(state${route === "" ? "" : route.split(".").map(item => `["${item}"]`).join("")}, key, value)`
    )

    call_vue_set_with_dinamic_params(Vue, state, route, key, value)

    // If depth of more than 2 children is modified, update the whole parent to propagate change
    if(route.split(".").length > 2) Vue.set(
      state,
      route.split(".")[0],
      JSON.parse(JSON.stringify(state[route.split(".")[0]]))
    )
  },
  [SET_LOADING_MESSAGE](state, loading_message) {
    Vue.set(state, "loading_message", loading_message)
  },
  [CLEAR_STORE](state, store_name){
    Vue.set(state, store_name, base_states_mapper[store_name]())
  },
  [TOGGLE_MENU](state, value) {
    state.open_menu = value
  },
  [REMOVE_NOTIFICATION](state, notification_id) {
    if (notification_id) Vue.delete(state.notifications, notification_id)
    else Vue.set(state, "notifications", {})
  },
  [UPDATE_NOTIFICATIONS](state, notifications) {
    Vue.set(state, "notifications", notifications)
  },
  [IMPORTED_DATA_UPDATED](state, value = true) {
    state.user_edited_imported_products = value
  },
  [SHOPIFY_DATA_UPDATED](state, value = true) {
    state.user_edited_shopify_products = value
  },
  [CONTENT_DATA_UPDATED](state, { types_updated, value = true}) {
    // If types not specified clear all
    if (!types_updated) state.user_edited_content = {}
    else types_updated.forEach(type => Vue.set(state.user_edited_content, type, value))
  },
  [CONFIGURATION_DATA_UPDATED](state, { types_updated, value = true}) {
    // If types not specified clear all
    if (!types_updated) state.user_edited_website_configuration = {}
    else types_updated.forEach(type => Vue.set(state.user_edited_website_configuration, type, value))
  },
  [FINANCE_DATA_UPDATED](state, value = true) {
    state.user_edited_finance_data = value
  },
  [USER_EDITED_SPECIAL_HOMEPAGES](state, value = true) {
    state.user_edited_special_type_homepages = value
  },
  [UPDATE_LOADER](state, value = true) {
    state.loading = value
  },
  [UPDATE_SMALL_LOADER](state, value = true) {
    state.loading_small = value
  },
  [UPDATE_DATA_LOAD_STATE](state, value = true) {
    state.data_loaded = value
  },
  [UPDATE_ERROR_LIST](state, value) {
    state.error_list = value
  },
  [UPDATE_ERROR_LIST_TITLE](state, value) {
    state.error_list_title = value
  },
  [CLEAR_ERRORS_SECTION](state, error_id) {
    state.error_list = state.error_list.filter(({ id }) => id !== error_id)
  },
  [ADD_GLOBAL_ERROR](state, error) {
    state.global_error = error
  },
  [DELETE_GLOBAL_ERROR](state) {
    state.global_error = null
  },
  [UPDATE_GUIDE_INFO](state, [type, step]) {
    if (step === 0) setTimeout(() => state.guide_data = {
      type: type || state.guide_data.type,
      step
    }, 1000)

    else state.guide_data = step === undefined ? null : {
      type: type || state.guide_data.type,
      step
    }
  },
  [UPDATE_GUIDE_OPEN_STATE](state, new_state) {
    state.guide_open_state = new_state
  },
  [SET_GLOBAL_MOMENT_LIBRARY_VARIABLE](state, moment) {
    state.$moment = moment
  },
  [UPDATE_IMAGES_FOR_DELETE](state, { images, store_type }) {
    const correct_state = state[store_type]
    let final_images = correct_state.images_to_delete

    if (images) images.forEach(image => {
      if (final_images.includes(image)) final_images = final_images.filter(url => url !== image)
      else final_images = [...final_images, image]
    });

    Vue.set(correct_state, "images_to_delete", final_images)
  },
  [CLEAR_IMAGES_FOR_DELETE](state, { store_type }) {
    Vue.set(state[store_type], "images_to_delete", [])
  },
  [UPDATE_SUBSCRIPTION_PAYED](state, value) {
    state.subscription_payed = value
  },
  [UPDATE_ACTIVE_SIDE_MENU_ICON](state, icon) {
    state.current_active_side_menu_icon = icon
  },
  [UPDATE_ADMIN_LANGUAGE](state, language) {
    state.admin_language = language
  }
}
