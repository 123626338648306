import { default_option_name, default_option_title } from "../others_constants";

export default (languages) => languages ?
  {
    translations: {
      ...languages.reduce((tot, lang) => ({
        ...tot,
        [lang]: {
          name: default_option_title,
          values: [default_option_name],
        }
      }), {})
    }
  } : {
    name: default_option_title,
    values: [default_option_name]
  }

