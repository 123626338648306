export const SET_PERMISSIONS = "SET_PERMISSIONS"
export const SET_USER_INFO = "SET_USER_INFO"
export const SET_MANAGED_USERS = "SET_MANAGED_USERS"
export const RESET_USER_STORE = "RESET_USER_STORE"
export const SET_USER_AND_PROJCT_CREADENTIALS = "SET_USER_AND_PROJCT_CREADENTIALS"
export const SET_USER_PROJECTS = "SET_USER_PROJECTS"
export const SET_USER_SELECTED_PROJECT = "SET_USER_SELECTED_PROJECT"
export const GET_USER_CURRENCY = "GET_USER_CURRENCY"
export const GET_USER_LANGUAGE = "GET_USER_LANGUAGE"
export const USER_STORE_TOUR_TOGGLE = "USER_STORE_TOUR_TOGGLE"
export const SET_GENERAL_ADMIN_STATE = "SET_GENERAL_ADMIN_STATE"

export const base_state = () => ({
  managed_users: [],
  user_accounts: [],
  projects: [],
  credentials: {},
  plan_type: undefined,
  selected_project: undefined,
  user_info: undefined,
  permissions: undefined,
  showing_marketplace_tour: false,
  showing_store_tour: false,
  is_any_store_admin: false,
  is_any_other_store_admin: false,
  rates_info: {},
  paying_user: false
})
