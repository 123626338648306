import { api } from "./axios_instance"

// Firebase data
export const fetch_firebase_data = page_type => api.post("firebase_data_fetch", { page_type })

// Shopify collections for Client app
export const fetch_shopify_data = () => api.get("store_and_serve_shopify_data")

// Shopify public config
export const get_shopify_public_config = () => api.get("get_shopify_public_config")

// User fingerprint settings update and retrieve
export const find_and_update_user_settings = data => api.post("find_and_update_user_settings", data)

// Get specific order
export const fetch_order = (order_id, store_id) => api.post("fetch_order", { order_id, store_id })

// Get firebase config
export const update_product_views = ({ product_route, product_project_id, product_id }) =>
  api.post("update_product_views", { product_route, product_project_id, product_id })

// Get user info
export const fetch_user_profile = ({ user_id }) => api.post("fetch_user_profile", { user_id })

// Verify discount code
export const verify_discount_code = ({ discount_code, basket_items, selected_language, group_project_id }) => api.post(
  "verify_discount_code",
  { discount_code, basket_items, selected_language, group_project_id }
)

// Get products by ids
export const get_products_by_ids = product_ids => api.post(
  "get_products_by_ids",
  product_ids
)

// Create order
export const create_order = order_data => api.post(
  "create_order",
  order_data
)

// Fetch store data necessary for order export as PDF
export const fetch_order_store_data = store_id => api.post(
  "fetch_order_store_data",
  { store_id }
)
