import {
  SAVE_FINANCE, UPDATE_SHOPIFY_ORDERS, UPDATE_IMPORTED_ORDERS, REFUND_ULSEMO_ORDER,
  RESTOCK_PRODUCT, UPDATE_BASE_FINANCIALS, GET_BASIC_FINANCIAL_OVERVIEW,
  GET_LATEST_ORDERS, UPDATE_LATEST_ORDERS, GET_ORDERS, UPDATE_ORDERS
} from "./constants"
import {
  update_website_configuration, update_imported_order, refund_ulsemo_order, restock_imported_product, update_discount_codes,
  get_basic_financial_overview, latest_orders, get_orders
} from "../../../constants/endpoints/firebase"
import { ADMIN, shopify, USER_STORE } from "../../../constants/others_constants"
import {
  UPDATE_LOADER, FINANCE_DATA_UPDATED, FETCH_SHOPIFY_FINANCIALS,
  FETCH_IMPORTED_FINANCIALS, ADD_GLOBAL_ERROR, POST_UPDATED_ORDERS,
  UPDATE_DISCOUNT_CODES, DELETE_DISCOUNT_CODE
} from "../constants"
import {
  update_shopify_order, shopify_fulfillments_API, restock_shopify_variant, create_shopify_price_rule, create_shopify_discount_code,
  update_shopify_price_rule, update_shopify_discount_code, delete_shopify_discount_code, delete_shopify_price_rule
} from "../../../constants/endpoints/shopify"
import { order_action_types, order_financial_statuses, order_fulfillment_statuses } from "../../../../Shared/constants/other"
import { GET_USER_CURRENCY } from "../user/constants"
import { translate } from "../../../../Shared/translator"

export default {
  async [RESTOCK_PRODUCT](_, { product_id, variant_id, quantity, product_origin }) {    
    (product_origin === shopify ? restock_shopify_variant : restock_imported_product)({ product_id, variant_id, quantity })    
  },
  async [SAVE_FINANCE]({ commit, dispatch }, content) {
    commit(`${ADMIN}/${UPDATE_LOADER}`, true, { root: true })
    const content_update_promises = Object.entries(content).map(([key, value]) => update_website_configuration(key, value))

    const responses = await Promise.all(content_update_promises)

    if (responses.every(state => state)) {
      await dispatch(`${ADMIN}/${FETCH_SHOPIFY_FINANCIALS}`, undefined, { root: true })
      commit(`${ADMIN}/${FINANCE_DATA_UPDATED}`, false, { root: true })
    }
    commit(`${ADMIN}/${UPDATE_LOADER}`, false, { root: true })
  },
  async [UPDATE_SHOPIFY_ORDERS]({ commit, state }, [order, action_type, payload, is_fulfillment_update]) {
    commit(`${ADMIN}/${UPDATE_LOADER}`, true, { root: true })
    let was_there_internal_error = false

    if (is_fulfillment_update) await shopify_fulfillments_API(order.id, payload)
    const { data } = await update_shopify_order(order.id, action_type, payload).catch(() => was_there_internal_error = true)

    commit(`${ADMIN}/${UPDATE_LOADER}`, false, { root: true })

    if (data.errors) commit(`${ADMIN}/${ADD_GLOBAL_ERROR}`, { message: data.errors.base[0] }, { root: true })
    else if (was_there_internal_error) commit(
      `${ADMIN}/${ADD_GLOBAL_ERROR}`,
      { message: await translate("orders.something_went_wrong") },
      { root: true }
    )
    else commit(UPDATE_ORDERS, [state.orders.map(iterated_order => order.id === iterated_order.id ? order : iterated_order)])
  },
  async [UPDATE_IMPORTED_ORDERS]({ commit, rootState, state }, [order, action_type]) {
    commit(`${ADMIN}/${UPDATE_LOADER}`, true, { root: true })
    let was_there_internal_error = false
    let order_state
    
    switch (action_type) {
      case order_action_types.close:
        order.closed_at = rootState[ADMIN].$moment().format()
        break;
      case order_action_types.cancel:
        order.cancelled_at = rootState[ADMIN].$moment().format()
        break;
      case order_action_types.open:
        order.closed_at = null
        break;
      case order_action_types.fulfill:
        order.fulfillment_status = "fulfilled"
        order_state = "fulfilled"
        break;
      case order_action_types.ship:
        order_state = "shipped"
        break;
    
      default:
        break;
    }

    await update_imported_order(order, order_state).catch(() => was_there_internal_error = true)

    commit(`${ADMIN}/${UPDATE_LOADER}`, false, { root: true })

    if (was_there_internal_error) commit(
      `${ADMIN}/${ADD_GLOBAL_ERROR}`,
      { message: await translate("orders.something_went_wrong") },
      { root: true }
    )
    else commit(UPDATE_ORDERS, [state.orders.map(iterated_order => order.id === iterated_order.id ? order : iterated_order)])
  },
  async [REFUND_ULSEMO_ORDER]({ commit, dispatch }, [{
    purchase_units,
    order_id,
    customer_locale,
    order_currency,
    cross_selling_store_id,
    cross_product_profit_share,
    order_number,
    payment_method
  }, payload = {}]) {
    let was_there_internal_error = false
    const capture_id = purchase_units?.[0]?.payments.captures?.[0]?.id || ""

    commit(`${ADMIN}/${UPDATE_LOADER}`, true, { root: true })

    const { data = {} } = await refund_ulsemo_order({
      capture_id,
      payload,
      order_id,
      customer_locale,
      order_currency,
      cross_selling_store_id,
      cross_product_profit_share,
      order_number,
      payment_method
    }).catch(() => was_there_internal_error = true)

    await dispatch(`${ADMIN}/${FETCH_IMPORTED_FINANCIALS}`, undefined, { root: true })

    commit(`${ADMIN}/${UPDATE_LOADER}`, false, { root: true })

    const paypal_error = data.details ? data.details[0].description : data.message

    if (paypal_error) commit(`${ADMIN}/${ADD_GLOBAL_ERROR}`, { message: paypal_error }, { root: true })
    if (was_there_internal_error) commit(
      `${ADMIN}/${ADD_GLOBAL_ERROR}`,
      { message: await translate("orders.something_went_wrong") },
      { root: true }
    )
  },
  async [POST_UPDATED_ORDERS]({ dispatch, state, commit, rootState }, [orders, fulfillments, type, data_source]) {
    let products_for_restock = []

    const get_location_id = (location_id = null) => {
      return !location_id && data_source === shopify ? (state.shopify_locations[0] || {}).id : location_id
    }

    const assemble_fulfillments_for_order = ({ line_items, fulfillments: [{ location_id, tracking_number } = {}] = []}) => {
      return {
        fulfillment: {
          location_id: get_location_id(location_id),
          line_items: line_items.map(({ id }) => ({ id })),
          ...(tracking_number && { tracking_number }), // TODO enable multiple tracking numbers
        }
      }
    }

    if (
      type === order_action_types.fulfill &&
      orders.find(({ financial_status }) => financial_status === order_financial_statuses.refunded)
    ) return commit(
      `${ADMIN}/${ADD_GLOBAL_ERROR}`,
      { message: await translate("orders.can_not_be_fulfilled_is_refunded") },
      { root: true }
    )

    if (Object.keys(fulfillments).length) orders = orders.map(order => ({
      ...order,
      ...(fulfillments[order.id] && { fulfillments: fulfillments[order.id] })
    }))

    if (order_action_types.cancel === type) products_for_restock = orders.flatMap(
      ({ line_items, status }) => status === order_action_types.cancel ?
        [] :
        line_items.map(
          ({ id, quantity, variant_id }) => dispatch(RESTOCK_PRODUCT, {
            product_id: id, variant_id: variant_id,quantity, product_origin: data_source
          })
        )
    )

    return Promise.all([
      ...products_for_restock,
      ...orders.map(order => {
        if (type === order_action_types.fulfill) order = {
          ...order,
          ...assemble_fulfillments_for_order(order)
        }

        if (type === order_action_types.ship) {
          const [first_fulfillment] = fulfillments[order.id] // TODO enable multiple fulfillments
          order.fulfillment_status = order_fulfillment_statuses.shipped

          if (
            first_fulfillment &&
            first_fulfillment.tracking_number
          ) return dispatch(`UPDATE_${data_source.toUpperCase()}_ORDERS`, [order, type])
          else return
        }

        if (order_action_types.cancel === type) order.fulfillment_status = order_fulfillment_statuses.restocked
        if (order_action_types.zasilkovna === type) order.fulfillment_status = order_fulfillment_statuses.zasilkovna
        if (order_action_types.paid === type) {
          order.financial_status = order_financial_statuses.paid
          order.paid_at = rootState[ADMIN].$moment().format()
        }

        return dispatch(`UPDATE_${data_source.toUpperCase()}_ORDERS`, [order, type])
      })
    ])
  },
  async [UPDATE_DISCOUNT_CODES]({ commit, dispatch }, [updating_code, discount_code, price_rule, data_source]) {
    commit(`${ADMIN}/${UPDATE_LOADER}`, true, { root: true })

    try {
      if (data_source === shopify) {
        if (updating_code) {
          await update_shopify_price_rule(price_rule.id, price_rule)
          await update_shopify_discount_code(price_rule.id, discount_code)
          await dispatch(`${ADMIN}/${FETCH_SHOPIFY_FINANCIALS}`, undefined, { root: true })
        }
        else {
          const { data: { price_rule } } = await create_shopify_price_rule(price_rule)
          await create_shopify_discount_code(price_rule.id, discount_code)
          await dispatch(`${ADMIN}/${FETCH_IMPORTED_FINANCIALS}`, undefined, { root: true })
        }
      }
      else await update_discount_codes(discount_code, price_rule)
    } catch (error) {
      commit(
        `${ADMIN}/${ADD_GLOBAL_ERROR}`,
        { message: error },
        { root: true }
      )
    }

    commit(`${ADMIN}/${UPDATE_LOADER}`, false, { root: true })
  },
  async [DELETE_DISCOUNT_CODE]({ commit, dispatch }, [price_rule_id, discount_id, data_source]) {
    commit(`${ADMIN}/${UPDATE_LOADER}`, true, { root: true })

    try {
      if (data_source === shopify) {
        await delete_shopify_discount_code(price_rule_id, discount_id)
        await delete_shopify_price_rule(price_rule_id)
        await dispatch(`${ADMIN}/${FETCH_SHOPIFY_FINANCIALS}`, undefined, { root: true })
      }
      else {
        await update_discount_codes({ id: discount_id }, { id: price_rule_id })
        await dispatch(`${ADMIN}/${FETCH_IMPORTED_FINANCIALS}`, undefined, { root: true })
      }
    } catch (error) {
      commit(
        `${ADMIN}/${ADD_GLOBAL_ERROR}`,
        { message: error },
        { root: true }
      )
    }

    commit(`${ADMIN}/${UPDATE_LOADER}`, false, { root: true })
  },
  async [GET_BASIC_FINANCIAL_OVERVIEW]({ commit, rootGetters }) {
    commit(UPDATE_BASE_FINANCIALS, null)

    const { data } = await get_basic_financial_overview(rootGetters[`${USER_STORE}/${GET_USER_CURRENCY}`])

    commit(UPDATE_BASE_FINANCIALS, data)
  },
  async [GET_LATEST_ORDERS]({ commit }, duration) {
    commit(UPDATE_LATEST_ORDERS, null)

    const { data } = await latest_orders(duration)

    commit(UPDATE_LATEST_ORDERS, data)
  },
  async [GET_ORDERS]({ commit }, [limit, page, search_keys, statuses, sort_type, sort_direction] = []) {
    commit(UPDATE_ORDERS)

    const { data: { orders, total } = {} } = await get_orders({ limit, page, search_keys, statuses, sort_type, sort_direction })

    commit(UPDATE_ORDERS, [orders, total])
  }
}
