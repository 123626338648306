import { SAVE_CONFIGURATION, GET_SHOPIFY_CONNECTION_STATE, UPDATE_CONFIGURATION_DATA } from "./constants"
import { update_website_configuration, check_shopify_API_access, fetch_website_configuration } from "../../../constants/endpoints/firebase"
import { ADMIN, CONFIGURATION } from "../../../constants/others_constants"
import { UPDATE_LOADER, CONFIGURATION_DATA_UPDATED, FETCH_CONFIGURATION_DATA, UPDATE_IMAGES_FOR_DELETE, CLEAR_IMAGES_FOR_DELETE } from "../constants"
import empty_shopify_configuration from "../../../constants/empty_objects/empty_shopify_configuration"
import remove_storage_file from "../../../methods/remove_storage_file"

export default {
  async [SAVE_CONFIGURATION]({ commit, dispatch, rootState, state }, content) {
    commit(`${ADMIN}/${UPDATE_LOADER}`, true, { root: true })

    const content_update_promises = Object
      .entries(rootState[ADMIN].user_edited_website_configuration)
      .map(([key, value]) => value && update_website_configuration(key, content[key]))

    const responses = await Promise.all(content_update_promises)

    if (responses.every(state => state)) {
      await dispatch(FETCH_CONFIGURATION_DATA)
      commit(`${ADMIN}/${CONFIGURATION_DATA_UPDATED}`, false, { root: true })
    }

    // Delete all removed images
    state.images_to_delete.forEach(async image_url => {
      await remove_storage_file(image_url)
      commit(`${ADMIN}/${UPDATE_IMAGES_FOR_DELETE}`, { images: [image_url], store_type: CONFIGURATION }, { root: true })
    });

    commit(`${ADMIN}/${UPDATE_LOADER}`, false, { root: true })
  },
  async [GET_SHOPIFY_CONNECTION_STATE]({ state }) {
    const { data } = await check_shopify_API_access(
      state.shopify_config.shopify_public ? state.shopify_config : empty_shopify_configuration
    )
    return !!data.length
  },
  async [FETCH_CONFIGURATION_DATA]({ commit }) {
    const { data } = await fetch_website_configuration()

    commit(`${ADMIN}/${CLEAR_IMAGES_FOR_DELETE}`, { store_type: CONFIGURATION }, { root: true })
    commit(UPDATE_CONFIGURATION_DATA, data)
  },
}
