export default {
  shopify_public: {
    shop_name: '',
    shopify_store_url: '',
    storefront_access_token: ''
  },
  shopify_secret: {
    API_key: '',
    API_password: '',
    shopify_email: '',
    shopify_password: ''
  },
  should_use_shopify_data: false
}
