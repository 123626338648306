/**
 * 
 * !!!!!! Make sure to always also update the default_translations_object_cz.js in functions/constants and any other language translations
 * 
 */

export default {
  about_us: "O nás",
  add_liked: "Přidejte položky do oblíbených",
  add_to_cart: "Do košíku",
  address_first: "Ulice a číslo",
  address_second: "Dodatečné info: číslo bytu, poschodí",
  apply: "Použít",
  back_button: "Zpět",
  buy_now: "Kup teď!",
  business_id: "IČO",
  cancel_payment_method: "Změnit platební metodu",
  cancel_selection_of_group: "Zrušit výběr",
  checkout: "Pokračovat",
  city: "Město",
  clear_basket: "Vyprázdnit košík",
  clear_filters: "Zrušit vybrané filtry",
  company: "Název firmy",
  company_purchase_checkbox: "Nakupuji na společnost",
  completed_orders: "Dokončené objednávky",
  contact_us: {
    email_label: "Email",
    email_placeholder: "vas@email.cz",
    fullname_label: "Jméno",
    fullname_placeholder: "Vaše Jméno",
    message_fail: "Bohužel se naskytla chyba, zkuste znovu nebo aktualizujte stránku.",
    message_label: "Vaše zpráva",
    message_success: "Vaše zpráva byla odeslána",
    send_button: "Odeslat",
    title: "Kontaktujte nás",
    validation_fail: "Prosím vyplňte všechny údaje",
    store_info_title: "Informace o nás:",
    businessID: "IČO",
    vatID: "DIČ",
    bank_account: "Bankovní účet"
  },
  cookie_consent_button: "Souhlasím",
  cookie_message: "Tato stránka používá cookies abychom vám poskytly nejlepší možný uživatelský zážitek.",
  country: "Země",
  country_doesnt_match_method: "Vybraná země neodpovídá je jiná než země k doručení.",
  currency_code: "CZK",
  currency_symbol: "Kč",
  customer_reviews: {
    average_rating: "Průměrné Hodnocení",
    customer_reviews: "Recenze",
    number_of_ratings: "Počet ohodnocení"
  },
  default_title: "Vítejte!",
  discount_code: "Slevový kupón",
  discount_code_error: "Slevový kupón buď neexistuje, již vypršel nebo nemůže být uplatněn.",
  email: "Email",
  empty_basket: "Váš košík je prázdný.",
  excluding_tax: "bez DPH",
  tax_title: "Daň",
  finish_order: "Dokončit objednávku",
  first_name: "Jméno",
  free: "Zdarma",
  further_reading: "O produktu",
  go_to_basket: "Přejít do košíku",
  go_to_product: "Otevřít",
  here_link: "ZDE",
  highlighted_products: {
    button: "Detail produktu",
    title: "Vybrané produkty"
  },
  hover_to_zoom: "Najeďte myší pro zoom",
  including_tax: "včetně DPH",
  order_page: {
    title: "Detail objednávky",
    order_not_found: "Vaše objednávka nebyla nalezena",
    order_number: "Číslo objednávky",
    created_at: "Vytvořeno v",
    order_id: "ID objednávky",
    shipping_address: "Dodací adresa",
    billing_address: "Fakturační adresa",
    products_title: "Shrnutí produktu",
    price_title: "Shrnutí ceny",
    taxes: "Daň",
    subtotal: "Mezisoučet",
    total: "Celkem",
    shipping_price_title: "Doprava",
    tracking_title: "Sledování zásilky",
    tracking_no_value_message: "Tato objednávka nemá sledování zásilky umožněno.",
    download_invoice_for_order: "Stáhnout fakturu"
  },
  last_name: "Příjmení",
  location: "Pobočka",
  log_in_message: "Přihlaste se prosím nebo zaplaťte jako host:",
  log_in: "Přihlásit se",
  main_page_link_button: "Naše nabídka",
  main_page_link_message: "Máme pro vás více podobných produktů, přesvědčte se sami.",
  no_location_selected: "Zvolte pobočku",
  no_products: "Produkty nebyly nalezeny",
  alternate_shipping_methods: "Další možnosti doručení:",
  order_summary: "Shrnutí objednávky",
  out_of_stock: "Není skladem",
  pay_for_group: "Zaplatit produkty",
  pay_via_cash: "Platba hotově/kartou na místě",
  pay_via_bank_transfer: "Platba převodem",
  period_of_delivery: "dny",
  phone: "Telefon",
  price: "Cena",
  price_has_been_adjusted_by_server: "Skladem {number} kusů",
  product_categories_explore: "Prozkoumejte",
  product_categories_message: "O obchodu a produktech",
  product_categories_title: "Objevte naše produkty",
  products_from_cross_store_are_unavailable: "Přeprodávane produkty z tohoto obchodu jsou momentálně nedostupné.",
  products_missing: "Bohužel, vámi vybraný produkt neexistuje",
  pwa_prompt_message: "Přidat stránku na domovskou obrazovku vašeho zařízení?",
  pwa_prompt_message_button: "Přidat",
  quantity: "Počet",
  random_items: "Oblíbené:",
  recently_viewed: "Nedávno prohlížené:",
  remove_group_products: "Smazat všechny",
  remove_item: "Odstranit",
  same_as_shipping: "Stejná adresa pro doručení i fakturaci?",
  search_title: "Prohledejte naše produkty",
  select_group: "Pro pokračování k platbě nejdříve zvolte skupinu produktů.",
  select_variant_message: "Prosím vyberte variantu",
  shipping_info: {
    shipping_option: "Zvolte si způsob dopravy:",
    shipping_method_default: "Způsob dopravy",
    estimated_delivery_message: "Očekávané dodání za {estimated_days} dní",
    days_estimate: "~{estimated_days} dní",
    chosen_method: "Zvolená metoda",
    ship_to: "Dopravit do",
    add_amount_for_better_shipping_discount: "Přidejte ještě {amount} pro {discount_title} slevu",
    shipping_price_title: "Doprava",
    shipping_default_suffix: "z dopravy"
  },
  shipping_option: "Zvolte způsob dopravy:",
  shopify_buy_button: "K bezpečné platbě",
  shopping_basket_title: "Váš košík:",
  similar_items: "Mohlo by se vám líbit",
  state: "Stát",
  subtotal: "Mezisoučet",
  tax_percantage: 21,
  thank_you_page: {
    email: "Potvrzení bylo odesláno na Vaši emailovou adresu.",
    first_message: "Objednávku jsme obdrželi, budete kontaktováni jakmile objednávka bude odeslána.",
    homepage_link: "Domů",
    homepage_redirect: "Domů",
    order_number: "Číslo vaší objednávky: ",
    second_message: "Děkujeme za váš nákup!",
    go_to_order: "K objednávce",
    here_link: "ZDE",
    bank_transfer: "Bankovní převod",
    on_pickup: "Hotově/kartou při předání",
    thank_you_message: "Děkujeme za Vaši objednávku!",
    bank_transfer_payment_method_message: "Pro dokončení objednávky prosím zašlete platbu na tento účet:",
    on_pickup_payment_method_message: "Zvolily jste způsob platby při převzetí na místě. Dostavte se tedy prosím na naši adresu zde:",
    chosen_shipping_option: "Zvolená doprava",
    chosen_payment_method: "Zvolená platba",
    amount_to_be_paid: "Částka k úhradě",
    bank_account: "Bankovní účet",
    iban: "IBAN",
    swift: "BIC (SWIFT)",
    reference_number: "Variabilní symbol"
  },
  these_products_are_cross_sold: "Tyto produkty jsou přeprodávány z jiného obchodu.",
  total: "Celkem",
  touch_to_zoom: "Klikněte pro zoom",
  VAT_id: "DIČ",
  view_your_order_message: "Podívejte se na detail objednávky za produkty: <b>{products}</b> kliknutím",
  website_name: "Naše stránky",
  you_need_to_choose_shipping_method: "Musíte nejdříve vybrat způsob dopravy.",
  zip: "PSČ"
}
