import { api } from "../../Shared/methods/ajax/axios_instance";
import { search_attributes } from "./other";

// Get marketplace product or collection of products
export const get_marketplace_products = product_array_route => api.post("get_marketplace_product_data", product_array_route)

export const get_popular_marketplace_products = () => api.post("get_popular_marketplace_products")

export const get_marketplace_homepage_slides = () => api.post("get_marketplace_homepage_slides")

export const get_marketplace_homepage_collections = () => api.post("get_marketplace_homepage_collections")

export const get_marketplace_homepage_favorite_products = () => api.post("get_marketplace_homepage_favorite_products")

export const get_marketplace_popular_categories = () => api.post("get_marketplace_popular_categories")

export const get_marketplace_categories = categories_array => api.post("get_marketplace_categories", { categories_array })

export const search_marketplace = ({ query, page_size, wanted_attributes = search_attributes }) => api.post("search_marketplace", { query, page_size, wanted_attributes })

export const get_marketplace_breadcrumbs = ({ routes }) => api.post("get_marketplace_breadcrumbs", { routes })
