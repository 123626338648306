import { default_menu_item_name } from "../others_constants"
import replace_spaces_for_dashes from "../../../Shared/methods/replace_spaces_for_dashes"

export default (
  is_top_level, name, category, sub_category, sub_items, is_parent_category, entity_id = null, languages
) => ({
  id: entity_id,
  ...(is_top_level && {
    open: false,
    category: category || replace_spaces_for_dashes(default_menu_item_name.toLowerCase()),
  }),
  ...(is_top_level && sub_items && { sub_items }),
  ...(!is_top_level && {
    sub_category: sub_category || replace_spaces_for_dashes(default_menu_item_name.toLowerCase())
  }),
  ...(is_parent_category && { parent_category: true }),
  translations: languages.reduce((tot, lang) => ({
    ...tot,
    [lang]: {
      name: name || default_menu_item_name,
    }
  }), {})
})
