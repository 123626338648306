// i18n setup
import Vue from "vue"
import VueI18n from "vue-i18n"
import store from "../../store"
import { ADMIN } from "../Admin/constants/others_constants"
import { USER } from "../Admin/constants/others_constants"

const default_language = "en"
Vue.prototype.$translate = () => ""
Vue.prototype.$translation_exists = () => ""

const translator = async (return_instance, language = default_language, application_type = "Admin") => {
  if (!return_instance) Vue.use(VueI18n)

  const i18n = new VueI18n()

  const translations = await import(
    /* webpackChunkName: "translations-[request]" */ `../${application_type}/constants/translations/${language}/index.js`
  )
  i18n.setLocaleMessage(language, translations.default)
  i18n.locale = language

  if (return_instance) return i18n

  Vue.prototype.$translate = (keys, params) => i18n.t(keys, params)
  Vue.prototype.$translation_exists = (keys, params) => i18n.te(keys, params)
}

export const translate = async (translation_route, params = {}, language) => {
  if (!store.state[ADMIN][USER].user_info) return ""

  const instance = await translator(
    true, language || store.state[ADMIN][USER].user_info.language_code
  )
  return instance.t(translation_route, params)
}

window.translate = translate

export default translator
