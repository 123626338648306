// Actions
export const FACEBOOK_AUTH = "FACEBOOK_AUTH"
export const FETCH_FACEBOOK_PAGES = "FETCH_FACEBOOK_PAGES"
export const UPDATE_USER_AUTH_DATA = "UPDATE_USER_AUTH_DATA"
export const CONNECT_SOCIAL_MEDIA_ENTITIES = "CONNECT_SOCIAL_MEDIA_ENTITIES"
export const FETCH_SOCIAL_MEDIA = "FETCH_SOCIAL_MEDIA"
export const FETCH_FACEBOOK_GROUPS = "FETCH_FACEBOOK_GROUPS"
export const PUBLISH_FACEBOOK_POSTS = "PUBLISH_FACEBOOK_POSTS"
export const FETCH_FACEBOOK_POSTS = "FETCH_FACEBOOK_POSTS"
export const SWITCH_FACEBOOK_ACCOUNT = "SWITCH_FACEBOOK_ACCOUNT"
export const REAUTHENTICATE_FACEBOOK_PAGE = "REAUTHENTICATE_FACEBOOK_PAGE"
export const FETCH_INSTAGRAM_ACCOUNTS = "FETCH_INSTAGRAM_ACCOUNTS"
export const FETCH_INSTAGRAM_POSTS = "FETCH_INSTAGRAM_POSTS"
export const PUBLISH_INSTAGRAM_POSTS = "PUBLISH_INSTAGRAM_POSTS"
export const FETCH_FACEBOOK_AD_ACCOUNTS = "FETCH_FACEBOOK_AD_ACCOUNTS"
export const PUBLISH_FACEBOOK_AD = "PUBLISH_FACEBOOK_AD"
export const FETCH_FACEBOOK_AD_CAMPAIGNS = "FETCH_FACEBOOK_AD_CAMPAIGNS"
export const CREATE_FACEBOOK_CAMPAIGN = "CREATE_FACEBOOK_CAMPAIGN"
export const FETCH_FACEBOOK_AUDIENCES = "FETCH_FACEBOOK_AUDIENCES"
export const FETCH_FACEBOOK_ADS = "FETCH_FACEBOOK_ADS"
export const FETCH_FACEBOOK_AD_INSIGHTS = "FETCH_FACEBOOK_AD_INSIGHTS"
export const FETCH_FACEBOOK_PIXELS = "FETCH_FACEBOOK_PIXELS"
export const FETCH_FACEBOOK_PIXELS_STATS = "FETCH_FACEBOOK_PIXELS_STATS"
export const FETCH_GA_REPORT = "FETCH_GA_REPORT"
export const GOOGLE_AUTH = "GOOGLE_AUTH"
export const SWITCH_GOOGLE_ACCOUNT = "SWITCH_GOOGLE_ACCOUNT"
export const FETCH_GA_VIEWS = "FETCH_GA_VIEWS"

// Mutations
export const UPDATE_FB_PAGES = "UPDATE_FB_PAGES"
export const UPDATE_CONNECTED_ENTITIES = "UPDATE_CONNECTED_ENTITIES"
export const UPDATE_FB_GROUPS = "UPDATE_FB_GROUPS"
export const UPDATE_FACEBOOK_PAGE_POSTS = "UPDATE_FACEBOOK_PAGE_POSTS"
export const UPDATE_FACEBOOK_GROUP_POSTS = "UPDATE_FACEBOOK_GROUP_POSTS"
export const UPDATE_CONNECTED_ENTITY = "UPDATE_CONNECTED_ENTITY"
export const UPDATE_IG_ACCOUNTS = "UPDATE_IG_ACCOUNTS"
export const UPDATE_INSTAGRAM_POSTS = "UPDATE_INSTAGRAM_POSTS"
export const UPDATE_FB_AD_ACCOUNTS = "UPDATE_FB_AD_ACCOUNTS"
export const UPDATE_AD_CAMPAINGS = "UPDATE_AD_CAMPAINGS"
export const UPDATE_AD_AUDIENCES = "UPDATE_AD_AUDIENCES"
export const UPDATE_ADS = "UPDATE_ADS"
export const UPDATE_AD_INSIGHTS = "UPDATE_AD_INSIGHTS"
export const UPDATE_FB_PIXELS = "UPDATE_FB_PIXELS"
export const UPDATE_FB_PIXEL_STATS = "UPDATE_FB_PIXEL_STATS"
export const UPDATE_GA_REPORT = "UPDATE_GA_REPORT"
export const UPDATE_GA_VIEWS = "UPDATE_GA_VIEWS"

export const base_state = () => ({
  fb_pages: [],
  connected_fb_pages: [],
  fb_groups: [],
  connected_fb_groups: [],
  ig_accounts: [],
  connected_ig_accounts: [],
  connected_fb_ad_accounts: [],
  fb_ad_accounts: [],
  fb_pages_posts: {},
  fb_pixel_stats: [],
  fb_pixels: {},
  fb_groups_posts: {},
  ig_accounts_posts: {},
  fb_ad_campaigns: {},
  fb_ad_audiences: {},
  ga_reports: {},
  connected_google_analytics_views: [],
  google_analytics_views: [],
  ads: {},
  fb_user_auth_data: null,
  google_user_auth_data: null,
})
