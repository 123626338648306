import store from "../../../store";
import { ADD_GLOBAL_ERROR, UPDATE_LOADER } from "../stores/Admin/constants";
import { ADMIN, maximum_API_request_size } from "../constants/others_constants";
import { store_and_update_image } from "../constants/endpoints/firebase";
import { translate } from "../../Shared/translator";
import { message_durations } from "../../Shared/constants/other";
import remove_file_type_from_route from "./remove_file_type_from_route"

export default async (
  { file },
  route,
  update_loader = true,
  generate_mobile_image = true,
  generate_desktop_image = true,
  generate_tablet_image = true,
  transform_image = true, // Should image be transformed into webp
  dont_transform_mobile = false // Keep image type if webp only for mobile image
) => {
  let final_value = null
  if (file.size > maximum_API_request_size) {
    store.commit(
      `${ADMIN}/${ADD_GLOBAL_ERROR}`,
      { message: await translate("errors.uploads.file_size_limit_exceeded"), duration: message_durations.long },
      { root: true }
    );
    return final_value
  }

  if (update_loader) store.commit(`${ADMIN}/${UPDATE_LOADER}`, true, { root: true });

  try {
    const form_data = new FormData()
    form_data.append("image", file)
    form_data.append("route", remove_file_type_from_route(route))
    form_data.append("generate_mobile_image", generate_mobile_image)
    form_data.append("generate_desktop_image", generate_desktop_image)
    form_data.append("generate_tablet_image", generate_tablet_image)

    // Only disallow image transformation if PNG image (due to some limitations with transparent WEBP images)
    if (!transform_image && file.type.includes("png")) form_data.append("transform_image", transform_image)
    if (dont_transform_mobile && file.type.includes("png")) form_data.append("dont_transform_mobile", dont_transform_mobile)

    const { data } = await store_and_update_image(form_data);

    final_value = data;
  } catch (_) {
    store.commit(
      `${ADMIN}/${ADD_GLOBAL_ERROR}`,
      { message: await await translate("errors.uploads.general"), duration: message_durations.long },
      { root: true }
    );
  }

  if (update_loader) store.commit(`${ADMIN}/${UPDATE_LOADER}`, false, { root: true });

  return final_value;
};
