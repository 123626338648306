import { ADMIN, TEST } from "../../../data/page_types";

// Checks if URL is on subdomain admin.ulsemo.com
export default () => window.location.port === "8081" ||
  window.location.host.split(".").length === 3 && (
    window.location.host.split(".")[0] === ADMIN ||
    window.location.host.split(".")[0] === `${TEST}-${ADMIN}` ||
    window.location.port === "8081" || /// For when testing anything related to Google auth as set here:
    window.location.host.split(".")[0] === TEST
  )
  // https://console.cloud.google.com/apis/credentials/oauthclient/31032534272
