// mutations
export const UPDATE_TRANSLATIONS = "UPDATE_TRANSLATIONS"
export const REMOVE_TRANSLATION = "REMOVE_TRANSLATION"
export const UPDATE_PAGE_TYPE_TRANSLATION = "UPDATE_PAGE_TYPE_TRANSLATION"
export const ADD_PAGE_TYPE_TRANSLATION = "ADD_PAGE_TYPE_TRANSLATION"
export const UPDATE_PROJECT_STYLE = "UPDATE_PROJECT_STYLE"
export const UPDATE_PROJECT_REST_CONFIG = "UPDATE_PROJECT_REST_CONFIG"
export const UPDATE_SHOPIFY_CONFIG = "UPDATE_SHOPIFY_CONFIG"
export const UPDATE_CUSTOM_SHIPPING_METHODS = "UPDATE_CUSTOM_SHIPPING_METHODS"
export const UPDATE_PROJECT_CONFIG = "UPDATE_PROJECT_CONFIG"
export const UPDATE_ANALYTICS_CONFIG = "UPDATE_ANALYTICS_CONFIG"
export const UPDATE_ERRORS = "UPDATE_ERRORS"
export const UPDATE_PAYPAL_CONFIG = "UPDATE_PAYPAL_CONFIG"
export const DELETE_PAGE_TYPE_TRANSLATION = "DELETE_PAGE_TYPE_TRANSLATION"
export const UPDATE_LEGAL_PAGE_TRANSLATIONS = "UPDATE_LEGAL_PAGE_TRANSLATIONS"
export const REMOVE_LEGAL_PAGE_TRANSLATION = "REMOVE_LEGAL_PAGE_TRANSLATION"
export const UPDATE_CONFIGURATION_DATA = "UPDATE_CONFIGURATION_DATA"
export const UPDATE_SHIPPING_DISCOUNTS = "UPDATE_SHIPPING_DISCOUNTS"
export const UPDATE_SHIPPING_ALLOWED_COUNTRIES = "UPDATE_SHIPPING_ALLOWED_COUNTRIES"
export const UPDATE_SHIPPING_MAX_DISCOUNT = "UPDATE_SHIPPING_MAX_DISCOUNT"
export const UPDATE_PROJECT_ADDRESS = "UPDATE_PROJECT_ADDRESS"
export const UPDATE_PROJECT_NAME = "UPDATE_PROJECT_NAME"
export const UPDATE_PRODUCTS_UPDATED_STATE = "UPDATE_PRODUCTS_UPDATED_STATE"
export const UPDATE_OTHER_SHIPPING_OPTIONS = "UPDATE_OTHER_SHIPPING_OPTIONS"

// actions
export const SAVE_CONFIGURATION = "SAVE_CONFIGURATION"
export const GET_SHOPIFY_CONNECTION_STATE = "GET_SHOPIFY_CONNECTION_STATE"

export const base_state = () => ({
  translations: {},
  products_updated: false,
  shopify_config: {},
  analytics_config: {},
  project_config: {},
  shipping_options: {},
  rest_config: {},
  paypal_config: {},
  error_list: [],
  images_to_delete: [],
})
