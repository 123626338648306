import axios from "axios"
import { fb_api_url, fb_media_file_types } from "../../../constants/social_media_constants"
import facebook_targeting_merge from "../../../methods/facebook_targeting_merge"

export const upload_facebook_ad_media = async (ad_data, access_token, ad_account_id) => {
  const form_data_attached_media = ad_data.ad_slides.map(({ media, ...rest }) => {
    let form_data

    if (media.file) {
      form_data = new FormData()
      form_data.append("source", media.file)
      form_data.append("access_token", access_token)  
    }

    return { form_data, ...rest, media }
  })

  // Upload images and videos
  const media_objects = await Promise.all(
    form_data_attached_media.map(async ({ form_data, media: { type, src, thumbnail }, ...rest }) => {
      const is_video = type === fb_media_file_types.videos
      let image_hash
      const { data } = await axios.post(
        `${fb_api_url}/${ad_account_id}/${type === fb_media_file_types.videos ? "advideos" : "adimages"}`,
        is_video ? form_data : {
          bytes: src.split("base64,")[1],
          access_token
        }
      )

      if (is_video) {
        // Wait for video the get uploaded and verified
        await new Promise(async (resolve) => {
          const check_video_upload_state = setInterval(async () => {
            const { data: { status } } = await axios.get(`${fb_api_url}/${data.id}?fields=id,status&access_token=${access_token}`)

            if (status.video_status === "ready") {
              clearInterval(check_video_upload_state)
              resolve()
            }
          }, 500);
        })

        // Upload video thumbnail
        const { data: thumbnail_data } = await axios.post(
          `${fb_api_url}/${ad_account_id}/adimages`, {
            bytes: thumbnail.split("base64,")[1],
            access_token
          }
        )
        image_hash = thumbnail_data.images.bytes.hash
      }

      const correct_id = is_video ? data.id : data.images.bytes.hash

      return { id: correct_id, ...rest, is_video, image_hash }
    })
  )

  return media_objects
}

export const create_facebook_ad_creative = async (ad_data, access_token, ad_account_id, media_objects) => {
  const { data: { id: creative_id } = {}} = await axios.post(
    `${fb_api_url}/${ad_account_id}/adcreatives`,
    {
      name: ad_data.name,
      // Add body key only to ads that arent solely video ads
      ...((media_objects.length === 1 && !media_objects[0].is_video || media_objects.length !== 1) && {
        body: ad_data.message
      }),
      object_story_spec: {
        // Add video_data if ad is solely video ad
        ...((media_objects.length === 1 && media_objects[0].is_video) && {
          video_data: {
            video_id: media_objects[0].id,
            image_hash: media_objects[0].image_hash,
            ...(!!ad_data.fb_button && {
              call_to_action: { type: ad_data.fb_button, value: { link: ad_data.ad_link }}, 
            }),
          }
        }),
        // Add photo_data if ad is solely image ad
        ...((media_objects.length === 1 && !media_objects[0].is_video) && {
          photo_data: {
            ...(!!ad_data.fb_button && {
              call_to_action: { type: ad_data.fb_button, value: { link: ad_data.ad_link }}, 
            }),
            image_hash: media_objects[0].id
          }
        }),
        // If carousel ad
        ...(media_objects.length > 1 && {
          link_data: {
            ...(!!ad_data.fb_button && {
              call_to_action: { type: ad_data.fb_button, value: { link: ad_data.ad_link }}, 
            }),
            child_attachments: media_objects.map(({ description, id, is_video, image_hash, headline, display_link }) => (
              { 
                description,
                image_hash: is_video ? image_hash : id,
                name: headline,
                link: display_link,
                ...(is_video && { video_id: id })
              })
            ),
            link: ad_data.ad_link,
          }
        }),
        page_id: ad_data.selected_ad_page.id
      },
      access_token
    }
  )

  return creative_id
}

export const assemble_facebook_ad_audiences = async (ad_account_audiences, access_token) => {
  const audiences_obj = await Promise.all(
    ad_account_audiences.map(async ({ is_custom, id }) => {
      let targeting

      if (!is_custom) {
        const { data: { targeting: audience_targeting } = {}} = await axios.get(
          `${fb_api_url}/${id}?fields=targeting&access_token=${access_token}`,
        )

        targeting = audience_targeting
      }

      return { targeting, id }
    })
  )

  // Merging targeting objects of each "saved audience"
  const final_audiences = audiences_obj.reduce((tot, audience) => {
    // If not a targeting audience, just return
    if (!audience.targeting) return {
      targeting: tot.targeting, custom_audiences: [...tot.custom_audiences, audience]
    }

    // Otherwise merge targeting objects together
    return {
      targeting: tot.targeting ? facebook_targeting_merge(tot.targeting, audience.targeting) : audience.targeting,
      custom_audiences: tot.custom_audiences
    }

  }, { targeting: undefined, custom_audiences: [] })

  return final_audiences
}