export default {
  single_product_homepages: {
    buy_now: 'Buy now!',
    message_bottom: 'Default text',
    message_middle: 'Default text',
    message_top: 'Default text',
    title: 'Default Title',
    to_product_button: 'Go to product',
    to_product_or_variants: 'Checkout out product or variants'
  },
  couple_products_homepages: {
    message_bottom: 'Default text',
    message_middle: 'Default text',
    message_top: 'Default text',
    title: 'Default Title',
  }
}