import { tablet_width_small } from "../../constants/others_constants"
import {default_language} from "../../../Shared/constants/other";

export const ADD_TO_STORE = "ADD_TO_STORE"
export const TOGGLE_MENU = "TOGGLE_MENU"
export const IMPORTED_DATA_UPDATED = "IMPORTED_DATA_UPDATED"
export const SHOPIFY_DATA_UPDATED = "SHOPIFY_DATA_UPDATED"
export const UPDATE_LOADER = "UPDATE_LOADER"
export const UPDATE_DATA_LOAD_STATE = "UPDATE_DATA_LOAD_STATE"
export const ASSIGN_DATA_TO_STORE = "ASSIGN_DATA_TO_STORE"
export const FETCH_FIREBASE_DATA = "FETCH_FIREBASE_DATA"
export const FETCH_SHOPIFY_DATA = "FETCH_SHOPIFY_DATA"
export const CLEAR_ERRORS_SECTION = "CLEAR_ERRORS_SECTION"
export const UPDATE_ERROR_LIST = "UPDATE_ERROR_LIST"
export const CONTENT_DATA_UPDATED = "CONTENT_DATA_UPDATED"
export const CONFIGURATION_DATA_UPDATED = "CONFIGURATION_DATA_UPDATED"
export const UPDATE_SMALL_LOADER = "UPDATE_SMALL_LOADER"
export const FINANCE_DATA_UPDATED = "FINANCE_DATA_UPDATED"
export const FETCH_SHOPIFY_FINANCIALS = "FETCH_SHOPIFY_FINANCIALS"
export const FETCH_IMPORTED_FINANCIALS = "FETCH_IMPORTED_FINANCIALS"
export const ADD_GLOBAL_ERROR = "ADD_GLOBAL_ERROR"
export const DELETE_GLOBAL_ERROR = "DELETE_GLOBAL_ERROR"
export const SET_PERMISSIONS = "SET_PERMISSIONS"
export const USER_EDITED_SPECIAL_HOMEPAGES = "USER_EDITED_SPECIAL_HOMEPAGES"
export const FETCH_CONFIGURATION_DATA = "FETCH_CONFIGURATION_DATA"
export const UPDATE_GUIDE_INFO = "UPDATE_GUIDE_INFO"
export const UPDATE_PROJECT_STATE = "UPDATE_PROJECT_STATE"
export const POST_UPDATED_ORDERS = "POST_UPDATED_ORDERS"
export const UPDATE_DISCOUNT_CODES = "UPDATE_DISCOUNT_CODES"
export const DELETE_DISCOUNT_CODE = "DELETE_DISCOUNT_CODE"
export const LOGIN_VIA_TOKEN = "LOGIN_VIA_TOKEN"
export const UPDATE_GUIDE_OPEN_STATE = "UPDATE_GUIDE_OPEN_STATE"
export const FETCH_USER_CURRENCY_RATES = "FETCH_USER_CURRENCY_RATES"
export const CLEAR_ADMIN_STORES = "CLEAR_ADMIN_STORES"
export const CLEAR_STORE = "CLEAR_STORE"
export const SET_GLOBAL_MOMENT_LIBRARY_VARIABLE = "SET_GLOBAL_MOMENT_LIBRARY_VARIABLE"
export const FETCH_AND_ASSING_MOMENT_LIBRARY = "FETCH_AND_ASSING_MOMENT_LIBRARY"
export const UPDATE_ERROR_LIST_TITLE = "UPDATE_ERROR_LIST_TITLE"
export const UPDATE_IMAGES_FOR_DELETE = "UPDATE_IMAGES_FOR_DELETE"
export const CLEAR_IMAGES_FOR_DELETE = "CLEAR_IMAGES_FOR_DELETE"
export const UPDATE_SUBSCRIPTION_PAYED = "UPDATE_SUBSCRIPTION_PAYED"
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION"
export const UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS"
export const SET_LOADING_MESSAGE = "SET_LOADING_MESSAGE"
export const UPDATE_ACTIVE_SIDE_MENU_ICON = "UPDATE_ACTIVE_SIDE_MENU_ICON"
export const BUILD_NEW_STORE = "BUILD_NEW_STORE"
export const UPDATE_ADMIN_LANGUAGE = "UPDATE_ADMIN_LANGUAGE"

export const base_state = {
  user_edited_header_items: false,
  user_edited_shipping_options: false,
  user_edited_content: {},
  user_edited_website_configuration: {},
  user_edited_footer: false,
  user_edited_couple_product_homepages: false,
  user_edited_single_product_homepages: false,
  user_edited_shopify_products: false,
  user_edited_imported_products: false,
  user_edited_finance_data: false,
  user_edited_special_type_homepages: false,
  user_edited_social_data: false,
  open_menu: false,
  loading: false,
  loading_small: false,
  loading_message: "",
  guide_open_state: false,
  subscription_payed: true,
  data_loaded: false,
  is_small_viewport: window.innerWidth < tablet_width_small,
  guide_data: null,
  global_error: null,
  $moment: null,
  error_list: [],
  error_list_title: "",
  feature_toggles: {},
  notifications: {},
  current_active_side_menu_icon: "",
  admin_language: default_language
}
