
const Products = () => import("../../applications/Client/components/base/Products.vue")

export const client_products_routes = {
  Products: "Client_products"
}

export default [
  {
    path: "products",
    children: [
      {
        path: ":category?/:subcategory?/:product?",
        name: client_products_routes.Products,
        component: Products
      }
    ]
  }
]
