// Products
export const CAN_SEE_MODULE_PRODUCTS = "CAN_SEE_MODULE_PRODUCTS"
export const CAN_MANAGE_PRODUCTS = "CAN_MANAGE_PRODUCTS"

// Layout and Content
export const CAN_SEE_MODULE_LAYOUT_AND_CONTENT = "CAN_SEE_MODULE_LAYOUT_AND_CONTENT"
export const CAN_MANAGE_LAYOUT_AND_CONTENT = "CAN_MANAGE_LAYOUT_AND_CONTENT"

// Configuration
export const CAN_SEE_MODULE_CONFIGURATION = "CAN_SEE_MODULE_CONFIGURATION"
export const CAN_SEE_MODULE_CONFIGURATION_TRANSLATIONS = "CAN_SEE_MODULE_CONFIGURATION_TRANSLATIONS"
export const CAN_MANAGE_MODULE_CONFIGURATION_TRANSLATIONS = "CAN_MANAGE_MODULE_CONFIGURATION_TRANSLATIONS"
export const CAN_SEE_MODULE_CONFIGURATION_STYLING = "CAN_SEE_MODULE_CONFIGURATION_STYLING"
export const CAN_MANAGE_MODULE_CONFIGURATION_STYLING = "CAN_MANAGE_MODULE_CONFIGURATION_STYLING"
export const CAN_SEE_MODULE_CONFIGURATION_SHOPIFY = "CAN_SEE_MODULE_CONFIGURATION_SHOPIFY"
export const CAN_MANAGE_MODULE_CONFIGURATION_SHOPIFY = "CAN_MANAGE_MODULE_CONFIGURATION_SHOPIFY"
export const CAN_SEE_MODULE_CONFIGURATION_SHIPPING = "CAN_SEE_MODULE_CONFIGURATION_SHIPPING"
export const CAN_MANAGE_MODULE_CONFIGURATION_SHIPPING = "CAN_MANAGE_MODULE_CONFIGURATION_SHIPPING"
export const CAN_SEE_MODULE_CONFIGURATION_PAYMENT_GATEWAY = "CAN_SEE_MODULE_CONFIGURATION_PAYMENT_GATEWAY"
export const CAN_MANAGE_MODULE_CONFIGURATION_PAYMENT_GATEWAY = "CAN_MANAGE_MODULE_CONFIGURATION_PAYMENT_GATEWAY"
export const CAN_SEE_MODULE_CONFIGURATION_PROJECT_CONFIGURATION = "CAN_SEE_MODULE_CONFIGURATION_PROJECT_CONFIGURATION"
export const CAN_MANAGE_MODULE_CONFIGURATION_PROJECT_CONFIGURATION = "CAN_MANAGE_MODULE_CONFIGURATION_PROJECT_CONFIGURATION"
export const CAN_SEE_MODULE_CONFIGURATION_OTHER_CONFIGURATION = "CAN_SEE_MODULE_CONFIGURATION_OTHER_CONFIGURATION"
export const CAN_MANAGE_MODULE_CONFIGURATION_OTHER_CONFIGURATION = "CAN_MANAGE_MODULE_CONFIGURATION_OTHER_CONFIGURATION"

// Finance
export const CAN_SEE_MODULE_FINANCE = "CAN_SEE_MODULE_FINANCE"
export const CAN_SEE_MODULE_FINANCE_ORDERS = "CAN_SEE_MODULE_FINANCE_ORDERS"
export const CAN_MANAGE_MODULE_FINANCE_ORDERS = "CAN_MANAGE_MODULE_FINANCE_ORDERS"
export const CAN_SEE_MODULE_FINANCE_DISCOUNTS = "CAN_SEE_MODULE_FINANCE_DISCOUNTS"
export const CAN_MANAGE_MODULE_FINANCE_DISCOUNTS = "CAN_MANAGE_MODULE_FINANCE_DISCOUNTS"
export const CAN_SEE_MODULE_FINANCE_ANALYTICS = "CAN_SEE_MODULE_FINANCE_ANALYTICS"
export const CAN_MANAGE_MODULE_FINANCE_ANALYTICS = "CAN_MANAGE_MODULE_FINANCE_ANALYTICS"

// User
export const CAN_SEE_MODULE_USER_PERMISSIONS = "CAN_SEE_MODULE_USER_PERMISSIONS"
export const CAN_MANAGE_MODULE_USER_PERMISSIONS = "CAN_MANAGE_MODULE_USER_PERMISSIONS"
export const CAN_SEE_MODULE_USER_SETTINGS = "CAN_SEE_MODULE_USER_SETTINGS"
export const CAN_MANAGE_MODULE_USER_SETTINGS_EMAIL = "CAN_MANAGE_MODULE_USER_SETTINGS_EMAIL"
export const CAN_MANAGE_MODULE_USER_SETTINGS_PASSWORD = "CAN_MANAGE_MODULE_USER_SETTINGS_PASSWORD"
export const CAN_SEE_MODULE_USER_BILLING = "CAN_SEE_MODULE_USER_BILLING"
export const CAN_MANAGE_MODULE_USER_BILLING = "CAN_MANAGE_MODULE_USER_BILLING"

// Social media
export const CAN_SEE_MODULE_SOCIAL_MEDIA = "CAN_SEE_MODULE_SOCIAL_MEDIA"
export const CAN_SEE_MODULE_SOCIAL_MEDIA_ACCOUNTS = "CAN_SEE_MODULE_SOCIAL_MEDIA_ACCOUNTS"
export const CAN_MANAGE_MODULE_SOCIAL_MEDIA_ACCOUNTS = "CAN_MANAGE_MODULE_SOCIAL_MEDIA_ACCOUNTS"
export const CAN_SEE_MODULE_SOCIAL_MEDIA_POSTS = "CAN_SEE_MODULE_SOCIAL_MEDIA_POSTS"
export const CAN_MANAGE_MODULE_SOCIAL_MEDIA_POSTS = "CAN_MANAGE_MODULE_SOCIAL_MEDIA_POSTS"
export const CAN_SEE_MODULE_SOCIAL_MEDIA_ADS = "CAN_SEE_MODULE_SOCIAL_MEDIA_ADS"
export const CAN_MANAGE_MODULE_SOCIAL_MEDIA_ADS = "CAN_MANAGE_MODULE_SOCIAL_MEDIA_ADS"
export const CAN_SEE_MODULE_MARKETING_ANALYTICS_ADS = "CAN_SEE_MODULE_MARKETING_ANALYTICS_ADS"
export const CAN_MANAGE_MODULE_MARKETING_ANALYTICS_ADS = "CAN_MANAGE_MODULE_MARKETING_ANALYTICS_ADS"

// Admin
export const IS_ADMIN = "IS_ADMIN"
