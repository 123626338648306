
import Analytics from "analytics"
import store from "./store"
import googleAnalyticsPlugin from "@analytics/google-analytics"
import googleAnalyticsV3Plugin from "@analytics/google-analytics-v3"
import { CLIENT_STORE } from "./applications/Client/constants/other";

let ga_instance = null

const GA_ID_promise = new Promise((resolve) => {
  const ga_interval = setInterval(() => {
    const found_id = store.state[CLIENT_STORE]?.analytics_config?.ga_project_id

    if (found_id !== undefined) {
      resolve(found_id)
      clearInterval(ga_interval)
    }
  }, 500)

  setTimeout(() => {
    clearInterval(null)
    resolve({})
  }, 10000);
})

const integrate_google_analytics_tracking = async () => {
  const id = await GA_ID_promise

  if (!id || typeof id !== "string") return

  /* Initialize analytics instance */
  ga_instance = Analytics({
    plugins: [
      /* Load Google Analytics v4 */
      ...(id?.includes("G-") ? [googleAnalyticsPlugin({
        measurementIds: [id],
      })] : []),
      /* Load Google Analytics v3 */
      ...(id?.includes("UA-") ? [googleAnalyticsV3Plugin({
        trackingId: id,
      })] : []),
    ],
  })

  ga_instance.page()
}

integrate_google_analytics_tracking()

export const get_ga_instance = async () => new Promise((resolve) => {
  if (ga_instance) return resolve(ga_instance)

  const ga_interval = setInterval(() => {
    if (ga_instance) {
      resolve(ga_instance)
      clearInterval(ga_interval)
    }
  }, 500)

  setTimeout(() => {
    clearInterval(ga_interval)
    resolve({})
  }, 10000);
})
