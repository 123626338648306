export const social_media_provider_names = {
  fb: "fb",
  ig: "ig",
  google: "google"
}
export const social_media_providers = Object.values(social_media_provider_names)
export const social_media_ad_providers = [
  social_media_provider_names.fb
]
export const social_platform_entities_mapper = {
  [social_media_provider_names.fb]: ["pages", "groups", "ad_accounts"],
  [social_media_provider_names.ig]: ["accounts"],
  [social_media_provider_names.google]: ["analytics_views"]
}

export const social_platform_entities_with_content_mapper = {
  [social_media_provider_names.fb]: ["pages", "groups"],
  [social_media_provider_names.ig]: ["accounts"],
  [social_media_provider_names.google]: ["analytics_views"]
}

export const fb_api_version = "v14.0"
export const fb_api_url = `https://graph.facebook.com/${fb_api_version}`
export const IG_media_upload_not_finished_error_subcode = 2207027
export const IG_video_upload_iteration_wait = 1500
export const GA_visitor_types = {
  returning: "Returning Visitor",
  new_visitors: "New Visitor"
}

export const twitter_character_limit = 280
export const max_fb_video_size = 1e+9
export const max_fb_image_size = 4e+6
export const max_fb_video_duration = 1200 // in sec
export const supported_fb_image_formats = ["jpeg", "bmp", "png", "gif", "tiff", "jpg"]
export const supported_fb_video_formats = ["3g2", "3gp", "3gpp", "asf", "avi", "dat", "divx", "dv", "f4v", "flv", "gif", "m2ts", "m4v", "mkv",
  "mod", "mov", "mp4", "mpe", "mpeg", "mpeg4", "mpg", "mts", "nsv", "ogm", "ogv", "qt", "tod", "ts", "vob", "wmv"]
export const max_ig_video_size = 1e+8
export const max_ig_image_size = 8e+6
export const ig_image_dimensions = {
  w: {
    min: 320,
    max: 1440
  },
  ratio: {
    min: 0.8,
    max: 1.91
  }
}
export const max_ig_video_duration = 60 // in sec
export const supported_ig_image_formats = ["jpeg", "jpg"]
export const supported_ig_video_formats = ["mov", "mp4"]
export const fb_ad_buttons = [
  "APPLY_NOW",
  "DOWNLOAD",
  "LEARN_MORE",
  "SIGN_UP",
  "SHOP_NOW",
  "BUY_NOW",
  "CONTACT_US",
  "SEE_MORE"
]
export const fb_ad_objectives = [
  "BRAND_AWARENESS",
  "CONVERSIONS",
  "LINK_CLICKS",
  "LOCAL_AWARENESS",
  "PAGE_LIKES",
  "POST_ENGAGEMENT",
  "REACH",
  "IMPRESSIONS"
]

export const fb_ad_statuse_names = {
  ACTIVE: "ACTIVE", PAUSED: "PAUSED"
}
export const fb_ad_statuses = Object.keys(fb_ad_statuse_names)
export const fb_ad_billing_event_names = {
  IMPRESSIONS: "IMPRESSIONS",
  LINK_CLICKS: "LINK_CLICKS",
  APP_INSTALLS: "APP_INSTALLS",
  PAGE_LIKES: "PAGE_LIKES",
  POST_ENGAGEMENT: "POST_ENGAGEMENT",
  VIDEO_VIEWS: "VIDEO_VIEWS"
}
export const fb_ad_billing_events = Object.keys(fb_ad_billing_event_names)
export const fb_bid_strategies = {
  LOWEST_COST_WITHOUT_CAP: "LOWEST_COST_WITHOUT_CAP",
  LOWEST_COST_WITH_BID_CAP: "LOWEST_COST_WITH_BID_CAP",
  COST_CAP: "COST_CAP",
  LOWEST_COST_WITH_MIN_ROAS: "LOWEST_COST_WITH_MIN_ROAS"
}
export const fb_bid_strategies_requiring_cap = [fb_bid_strategies.LOWEST_COST_WITH_BID_CAP, fb_bid_strategies.COST_CAP]
export const DEFAULT_FB_BID_STRATEGY = fb_bid_strategies.LOWEST_COST_WITHOUT_CAP
export const fb_media_file_types = {
  videos: "videos",
  photos: "photos"
}
export const fb_ad_event_types = [
  "ADD_TO_CART", "ADD_TO_WISHLIST", "INITIATED_CHECKOUT", "ADD_PAYMENT_INFO", "PURCHASE", "SEARCH", "OTHER"
]
export const fb_objective_rules = {
  pixel_id: ["CONVERSIONS"],
  page_id: ["PAGE_LIKES", "OFFER_CLAIMS", "LEAD_GENERATION"]
}
export const fb_promoted_object_type = "pixel_id"

export const default_fb_campaign = {
  special_ad_categories: [],
  status: null,
  objective: null,
  name: "",
  lifetime_budget: null,
  daily_budget: null
}
export const default_new_ad_state = {
  name: "",
  fb_button: "",
  fb_ad_status: fb_ad_statuse_names.PAUSED,
  start_date: new Date().toISOString(),
  end_date: "",
  ad_message: "",
  ad_link: "",
  ad_slides: [{}],
  selected_audiences: {},
  lifetime_budget: "",
  daily_budget: "",
  billing_event: "",
  selected_ad_page: {},
  promoted_object_id: "",
  promoted_object_type: fb_promoted_object_type,
  promoted_object_event_type: "",
  bid_cap: "",
}

export const google_auth_scopes = [
  "https://www.googleapis.com/auth/analytics.readonly",
  "https://www.googleapis.com/auth/analytics.manage.users.readonly",
]

export const google_client_id = "31032534272-popk90j094n97bbr5a2usu2nu61c1b3c.apps.googleusercontent.com"
export const google_api_key = "AIzaSyBxd97cgzupkNmOLJuPcaNNE8gL3bjaXEo"
export const google_analytics_data_types = ["gender", "age", "country", "new_and_returning", "source"]
