import { default_product_name, created, added, ulsemo, default_currency } from "../others_constants"
import {v4 as uuid} from "uuid"
import empty_variant_object from "./empty_variant_object"
import empty_option_object from "./empty_option_object"
import { lang_to_currency_mapper } from "../../../Shared/constants/other"

export default (
  collection, id, is_imported_product, languages, translations, price, images = [], inventory_quantity = 0,
  further_data = {}
) => {
  const new_id = id || uuid()

  return {
    ...(!is_imported_product && { title: default_product_name }),
    ...(!is_imported_product && { body_html: "" }),
    ...(is_imported_product && {
      translations: {
        ...languages.reduce((tot, lang) => ({
          ...tot,
          [lang]: {
            title: translations?.[lang]?.title || default_product_name,
            body_html: translations?.[lang]?.body_html || "",
            currency_code:
              (translations[lang] || {}).currency_code ||
              lang_to_currency_mapper[lang] ||
              default_currency
          }
        }), {})
      }
    }),
    images,
    handle: new_id,
    change_type: created,
    published_at: null,
    published: false,
    variants: [
      empty_variant_object(
        is_imported_product ? uuid() : false,
        undefined,
        is_imported_product ? languages : undefined,
        false,
        { inventory_quantity },
        price
      )
    ],
    vendor: ulsemo,
    id: new_id,
    options: [empty_option_object(is_imported_product ? languages : undefined)],
    collection_change_type: added,
    allow_on_marketplace: false,
    ...(collection && { collections: [collection] }),
    ...further_data
  }
}
