export const distance_units = [
  "cm",
  "in",
  "ft",
  "mm",
  "m",
  "yd"
]

export const weight_units = [
  "g",
  "oz",
  "lb",
  "kg"
]
