import Vue from "vue"
import { user_liked_products, user_selected_language } from "../../../../data/other_constants"
import project_styles from "../../../../data/project_styles"
import { get_ga_instance } from "../../../../google_tracking"
import { ga_event_actions, ga_event_categories } from "../../../Shared/constants/ga_constants"
import get_correct_translation_value from "../../../Shared/methods/get_correct_translation_value"
import {
  UPDATE_PRODUCTS_BEING_FETCHED, UPDATE_PRODUCTS_DATA, SET_VIEWED_PRODUCTS,
  UPDATE_VIEWED_PRODUCT, TOGGLE_LIKED_PRODUCT_STATE, SET_LIKED_PRODUCTS,
  UPDATE_PRODUCT_BEING_VIEWED, ADD_PAGE_TYPE_TRANSLATIONS, UPDATE_ABOUT_PAGE,
  HAS_STORE_ABOUT_PAGE, UPDATE_SHOPIFY_CHECKOUT, UPDATE_PAGE_TYPE,
  TOGGLE_USE_BUY_NOW_BUTTON, UPDATE_COLLECTIONS, UPDATE_TRANSLATIONS,
  UPDATE_SHIPPING_OPTIONS, UPDATE_BANNERS, UPDATE_POPULAR_PRODUCTS,
  UPDATE_HIGHLIGHTED_PRODUCTS, UPDATE_IMPORTANT_MESSAGE_BAR, UPDATE_FOOTER,
  UPDATE_STYLES, UPDATE_ANALYTICS_CONFIG, UPDATE_PROJECT_CONFIG, UPDATE_REST_CONFIG,
  TOGGLE_SHOPIFY_USE, UPDATE_OTHER_PAGE_TRANSLATIONS, TOGGLE_DEMO_STATE, UPDATE_SHOPIFY_INSTANCE,
  UPDATE_SELECTED_LANGUAGE, UPDATE_USER_SETTINGS, UPDATE_SELECTED_ENTITY, UPDATE_HEADER_ITEMS,
  UPDATE_ADMIN_IMPORTED, UPDATE_VISITOR_INTERATION_CHECKS, UPDATE_TEMP_ORDER_DATA
} from "./constants"

export default {
  [UPDATE_TEMP_ORDER_DATA](state, data) {
    state.temp_order_data = data
  },
  [UPDATE_VISITOR_INTERATION_CHECKS](state, type) {
    Vue.set(state.visitor_interaction_checks, type, true)
  },
  [UPDATE_ADMIN_IMPORTED](state, value) {
    state.is_admin_imported = value
  },
  [HAS_STORE_ABOUT_PAGE](state, value) {
    state.has_about_page = value
  },
  update_free_shipping_state(state, is_free_shipping) {
    state.is_free_shipping = is_free_shipping
  },
  /*
  * 
  */
  [TOGGLE_DEMO_STATE](state, is_demo_account) {
    state.is_demo_account = is_demo_account
  },
  /*
  * 
  */
  update_shipping_option(state, shipping_option = {}) {
    state.shipping_option = shipping_option
  },
  /*
  * 
  */
  [UPDATE_ABOUT_PAGE](state, about_page_content) {
    Vue.set(state, "about_page_content", about_page_content)
  },
  /*
  * 
  */
  [UPDATE_OTHER_PAGE_TRANSLATIONS](state, { type, language, translations }) {
    if (!state.other_page_translations[language]) Vue.set(state.other_page_translations, language, {})

    Vue.set(state.other_page_translations[language], type, translations)
  },
  /*
  * 
  */
  [UPDATE_ANALYTICS_CONFIG](state, analytics_config) {
    state.analytics_config = analytics_config
  },
  /*
  * 
  */
  [UPDATE_PROJECT_CONFIG](state, project_config) {
    state.project_config = project_config
  },
  /*
  * 
  */
  [UPDATE_SHIPPING_OPTIONS](state, shipping_options) {
    state.shipping_options = shipping_options
  },
  /*
  * 
  */
  [UPDATE_USER_SETTINGS](state, user_settings) {
    state.user_settings = user_settings
  },
  /*
  * 
  */
  [TOGGLE_SHOPIFY_USE](state, use_shopify_data) {
    state.use_shopify_data = use_shopify_data
  },
  /*
  * 
  */
  [UPDATE_STYLES](state, styles) {
    state.project_styles = styles || project_styles
  },
  /*
  * 
  */
  [UPDATE_REST_CONFIG](state, config) {
    state.rest_config = config
  },
  /*
  * 
  */
  [UPDATE_SELECTED_LANGUAGE](state, language) {
    state.selected_language = language
    if (state.translations_object) state.translations = state.translations_object[language]
    if (language) window.localStorage.setItem(user_selected_language, language)
  },
  /*
  * 
  */
  [TOGGLE_USE_BUY_NOW_BUTTON](state, decision) {
    state.use_buy_it_now = decision
  },
  /*
  * 
  */
  [UPDATE_SELECTED_ENTITY](state, id) {
    state.selected_entity = id
  },
  /*
  * 
  */
  add_couple_products_homepage_translations(state, translations) {
    Vue.set(state, "couple_products_homepage_translations", translations)
  },
  /*
  * 
  */
  [ADD_PAGE_TYPE_TRANSLATIONS](state, [translations, type]) {
    Vue.set(state, `${type}_page_translations`, translations)
  },
  /*
  * 
  */
  [UPDATE_PAGE_TYPE](state, data) {
    state.category_of_product_variety = data
  },
  /*
  * 
  */
  update_shopify_highlighted_items(state, data) {
    state.shopify_highlighted_items = data
  },
  /*
  * 
  */
  [UPDATE_SHOPIFY_INSTANCE](state, data) {
    state.shopify_instance = data
  },
  /*
  * 
  */
  [UPDATE_SHOPIFY_CHECKOUT](state, data) {
    state.shopify_checkout = data
  },
  /*
  * 
  */
  [UPDATE_POPULAR_PRODUCTS](state, popular_products) {
    state.popular_products = popular_products
  },
  /*
  * 
  */
  [UPDATE_HIGHLIGHTED_PRODUCTS](state, highlighted_products) {
    state.highlighted_products = highlighted_products
  },
  /*
  * 
  */
  [UPDATE_IMPORTANT_MESSAGE_BAR](state, important_message_bar) {
    state.important_message_bar = important_message_bar
  },
  /*
  * 
  */
  [TOGGLE_LIKED_PRODUCT_STATE](state, [product, decision]) {
    // GA event
    get_ga_instance().then((ga) => {
      ga.track(ga_event_categories.liked_products, {
        product: product.id,
        product_name: get_correct_translation_value(product, "title"),
        actions: decision ? ga_event_actions.add : ga_event_actions.remove,
      })
    })

    if (decision) state.liked_products = [
      ...state.liked_products,
      product.id
    ]
    else state.liked_products = state.liked_products.filter(liken_item_id => liken_item_id !== product.id)

    window.localStorage.setItem(user_liked_products, JSON.stringify(state.liked_products))
  },
  /*
  * 
  */
  [SET_LIKED_PRODUCTS](state, items) {
    state.liked_products = items
  },
  /*
  * 
  */
  [UPDATE_FOOTER](state, content) {
    state.footer_content = content
  },
  /*
  * 
  */
  open_header_item(state, [index, decision]) {
    state.header_items = state.header_items.map((item, ind) => {
      if (ind === index) item.open = decision
      return item
    })
  },
  /*
  * 
  */
  close_all_header_items(state) {
    state.header_items = state.header_items.map(item => ({
      ...item,
      open: false
    }))
  },
  [UPDATE_BANNERS](state, object) {
    state.banners = object
  },
  /*
  * 
  */
  [UPDATE_HEADER_ITEMS](state, items) {
    state.header_items = items
  },
  /*
  * 
  */
  [UPDATE_COLLECTIONS](state, data) {
    state.collections = data
  },
  /*
  * 
  */
  [UPDATE_PRODUCTS_DATA](state, data) {
    Vue.set(state, "products", data)
  },
  [UPDATE_PRODUCTS_BEING_FETCHED](state, product_ids = []) {
    state.products_being_fetched = product_ids
  },
  /*
  * 
  */
  [UPDATE_TRANSLATIONS](state, data) {
    state.translations_object = data
  },
  /*
  /*
  * 
  */
  [UPDATE_VIEWED_PRODUCT](state, product) {
    // GA event
    get_ga_instance().then((ga) => {
      ga.track(ga_event_categories.liked_products, {
        product: product.id,
        product_name: get_correct_translation_value(product, "title"),
        actions: ga_event_actions.view,
      })
    })

    state.viewed_product_id = product.id
    state.viewed_item_category = product.category
    state.viewed_item_types = product.type
  },
  /*
  * 
  */
  [UPDATE_PRODUCT_BEING_VIEWED](state, decision) {
    state.vieving_item = decision
  },
  /*
  * 
  */
  [SET_VIEWED_PRODUCTS](state, items) {
    state.viewed_products = items
  }
}
