/**
* Colors 
* 
* Need to also be adjusted in src/styles/global_styles.scss !!!
*/ 

export default {
  sizes: {
    maximum_width: "1080",
    tablet: "800",
    mobile: "450",
    menu_height: "50",
    mobile_menu_width: "300"
  },
  colors: {
    font_light: "#999",
    font_heavy: "#505050",
    background_light: "#f7f5f5",
    background_heavy: "#1b1b1b",
    shadow_color: "rgba(0, 0, 0, 0.6)",
    background_heavy_RGB: "27, 27, 27",
    shadow_color_light: "rgba(0, 0, 0, 0.15)",
    alert_color: "#ca311d",
    success_color: "#1dca65",
    pure_white: "#fff",
    pure_black: "#000",
    grey: "#989899",
    light_grey: "#dadcde",
    light_green: "#c4e8c4",
    green_color: "#178846",
    dark_brown: "#30231d",
    item_name_color: "#fff",
    body_color: "#fff",
    body_color_RGB: "255, 255, 255",
  }
}
