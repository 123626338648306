import { GET_PRODUCT_PRICE, GET_BASKET_PRICE, GET_TOTAL_PRICE, DOES_BASKET_REQUIRE_SHIPPING, GET_TOTAL_PRODUCT_DISCOUNTS } from "./constants"
import round_to_two_decimals from "../../../Client/methods/round_to_two_decimals"
import calculate_price from "../../../Client/methods/calculate_price"
import final_basket_price from "../../../Client/methods/final_basket_price"
import { CLIENT_STORE } from "../../../Client/constants/other"

export default {
  [GET_TOTAL_PRODUCT_DISCOUNTS]: (_, getters) => (items, language) => 
    getters[GET_PRODUCT_PRICE](items, language, false) - getters[GET_PRODUCT_PRICE](items, language, true),
  [GET_PRODUCT_PRICE]: (state) => (items, language, use_discount = true) => round_to_two_decimals(calculate_price(
    items || state.basket_items,
    language,
    use_discount ? state.discount_code : {}
  )),
  [GET_BASKET_PRICE]: (_, getters, rootState) => (items, tax, language) => {
    let temp_price = getters[GET_PRODUCT_PRICE](items, language)

    if (rootState[CLIENT_STORE].translations.tax_percantage || tax) temp_price = temp_price * (
      1 + (Number(tax || rootState[CLIENT_STORE].translations.tax_percantage) / 100)
    )

    return round_to_two_decimals(temp_price)
  },
  [GET_TOTAL_PRICE]: (_, getters, rootState) =>
    (items, tax, language) => final_basket_price(getters[GET_BASKET_PRICE](items, tax, language), rootState[CLIENT_STORE]?.shipping_option?.price),
  [DOES_BASKET_REQUIRE_SHIPPING]: state =>
    items => (items || state.basket_items).some(({ requires_shipping }) => requires_shipping),
}
