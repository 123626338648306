// mutations
export const UPDATE_ORDERS = "UPDATE_ORDERS"
export const UPDATE_PRICE_RULE = "UPDATE_PRICE_RULE"
export const UPDATE_DISCOUNT_CODE = "UPDATE_DISCOUNT_CODE"
export const UPDATE_ERROR_LIST = "UPDATE_ERROR_LIST"
export const CLEAR_ERRORS = "CLEAR_ERRORS"
export const UPDATE_BASE_FINANCIALS = "UPDATE_BASE_FINANCIALS"
export const UPDATE_LATEST_ORDERS = "UPDATE_LATEST_ORDERS"

// actions
export const SAVE_FINANCE = "SAVE_FINANCE"
export const UPDATE_SHOPIFY_ORDERS = "UPDATE_SHOPIFY_ORDERS"
export const UPDATE_IMPORTED_ORDERS = "UPDATE_IMPORTED_ORDERS"
export const REFUND_ULSEMO_ORDER = "REFUND_ULSEMO_ORDER"
export const RESTOCK_PRODUCT = "RESTOCK_PRODUCT"
export const GET_BASIC_FINANCIAL_OVERVIEW = "GET_BASIC_FINANCIAL_OVERVIEW"
export const GET_LATEST_ORDERS = "GET_LATEST_ORDERS"
export const GET_ORDERS = "GET_ORDERS"

export const base_state = () => ({
  error_list: [],
  orders: null,
  number_of_orders: 0,
  number_of_order_pages: 0,
  base_financials: null,
  latest_orders: null
})
