import urlencode from "urlencode";
import store from "../../../store";
import { ADMIN, CONFIGURATION } from "../constants/others_constants";

const get_storage_tour_from_url = (url, decode) => {
  const final_route = url
    .split(store.state[ADMIN][CONFIGURATION].project_config.project_id)[1]
    .split("?")[0]

  return decode ? urlencode.decode(final_route) : final_route
}

const remove_file = async (url, decode_route = true) => {
  if (url) {
    try {
      await window.firebase_storage
        .ref()
        .child(
          `${
            store.state[ADMIN][CONFIGURATION].project_config.project_id
          }/${get_storage_tour_from_url(url, decode_route)}`
        )
        .delete()
    } catch (error) {
      if (decode_route) return remove_file(url, false)
    }
  }
}

export default remove_file
