import remove_storage_file from "./remove_storage_file"

export default async ({ media_file, media_id, project_id, remove_media }) => {
  if (remove_media) {
    await remove_storage_file()

    return true
  }

  const storage_ref = await window.firebase_storage.ref().child(`${project_id}/temp_media/${media_id}`)
  const snapshot = await storage_ref.put(media_file)
  const image_download_url = await snapshot.ref.getDownloadURL()

  return image_download_url.split("&token")[0]
}
