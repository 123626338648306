<template>
  <section>
    <div v-if="global_loader" class="webpage__loading">
      <Loader class="webpage__loading-content"/>
    </div>
    <template v-if="!admin_imported && (is_admin || is_marketplace)">
      <router-view />
    </template>
    <template  v-else>
      <section v-if="!data_loaded" class="main-app-wrapper webpage">
        <Stand_in_header />
        <div v-if="!data_loaded" class="webpage__loading">
          <Loader class="webpage__loading-content"/>
        </div>
        <Stand_in_footer />
      </section>
      <section v-else class="main-app-wrapper webpage">
        <!-- Shared components page types -->
        <Menu/>
        <important_message_bar
          v-if="
            $route.params &&
            !$route.params.product &&
            (is_mobile_device ? !$route.params.category : true)"
        />

        <div class="wrapper">
          <!-- Website specific contents -->
            <Multi_product_page v-if="category_of_product_variety === MULTI"/>
            <Couple_product_page v-else-if="category_of_product_variety === COUPLE"/>
            <Single_product_page v-else-if="category_of_product_variety === SINGLE"/>
          <!-- End of website specific contents -->
        </div>

        <!-- Shared components page types -->
          <pwa_prompt />
          <cookie_bar v-if="show_cookie_bar"/>
          <Footer />
        <!-- End of shared components page types -->
      </section>
    </template>
  </section>
</template>

<script>
// Direct imports
import Vue from "vue"
import { mapState, mapMutations, mapActions, mapGetters } from "vuex"
import Loader from "@/applications/Shared/components/Loader/index.vue"
import is_user_in_EU from "@segment/in-eu"
import LoadScript from "vue-plugin-load-script";
import html_enity_encoder from "urlencode"

// Components
const Menu = () => import("./applications/Client/components/base/Menu")
const Stand_in_header = () => import("./applications/Client/components/stand_in_components/header")
const Stand_in_footer = () => import("./applications/Client/components/stand_in_components/footer")
const Footer = () => import("./applications/Client/components/base/Footer")
const pwa_prompt = () => import("./applications/Client/components/utils/pwa_prompt")
const important_message_bar = () => import("./applications/Client/components/utils/important_message_bar")
const cookie_bar = () => import("./applications/Client/components/utils/cookie_bar")

// Page content types
const Multi_product_page = () => import("./applications/Client/components/page_content_types/Multi_product_page")
const Single_product_page = () => import("./applications/Client/components/page_content_types/Single_product_page")
const Couple_product_page = () => import("./applications/Client/components/page_content_types/Couple_product_page")

// Other
const check_if_valid_visitor = () => import("./applications/Client/methods/check_if_valid_visitor")

// Config and constants
import { SINGLE, COUPLE, MULTI } from "./data/product_variety_types"
import { CLIENT_STORE, local_storage_ulsemo_user_fingerprint } from "./applications/Client/constants/other"

// Methods
import capitalize_word  from "./applications/Client/methods/capitalize_word"
import get_total_items_array from "./applications/Client/methods/get_total_items_array"
import { home, user_liked_products, user_basket_items, user_selected_language } from "./data/other_constants"
import { find_and_update_user_settings } from "./applications/Shared/methods/ajax/endpoints"
import { product, collection, default_image_src, SHARED_STORE } from "./applications/Shared/constants/other"
import new_fingerprint from "./applications/Shared/methods/browser_fingerprint"
import check_if_user_in_admin from "./applications/Shared/methods/check_if_user_in_admin"
import check_if_user_in_marketplace from "./applications/Shared/methods/check_if_user_in_marketplace"
import { FIRST_LOAD, SET_STORE_ID, SET_WHOLE_BASKET, UPDATE_MOBILE_VIEW } from "./applications/Shared/stores/Shared/constants"
import { ADD_LOADSCRIPT_GLOBAL_VAR, UPDATE_GLOBAL_LOADER } from "./store"
import {
  BASE_DATA_FETCH, FETCH_PRODUCTS_BY_IDS, HAS_STORE_ABOUT_PAGE, TOGGLE_USE_BUY_NOW_BUTTON,
  UPDATE_HEADER_ITEMS, UPDATE_PAGE_TYPE, UPDATE_PRODUCTS_DATA,
  UPDATE_SELECTED_ENTITY, UPDATE_USER_SETTINGS, UPDATE_SELECTED_LANGUAGE, UPDATE_ADMIN_IMPORTED, SET_LIKED_PRODUCTS, UPDATE_TEMP_ORDER_DATA, ARE_ANY_PRODUCTS_BEING_FETCHED
} from "./applications/Client/stores/Client/constants"
import filter_out_unavailable_products from "./applications/Client/methods/filter_out_unavailable_products"
import { event_hub } from "./main";
import check_if_user_in_store_preview from "./applications/Shared/methods/check_if_user_in_store_preview"
import get_store_preview_store_id from "./applications/Shared/methods/get_store_preview_store_id"
import get_correct_translation_value from "./applications/Shared/methods/get_correct_translation_value"

Vue.use(LoadScript);

export default {
  name: "App",
  props: {
    admin_imported: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Menu,
    Stand_in_header,
    Footer,
    Stand_in_footer,
    pwa_prompt,
    important_message_bar,
    Loader,
    cookie_bar,
    Multi_product_page,
    Couple_product_page,
    Single_product_page,
  },
  data() {
    return {
      SINGLE,
      COUPLE,
      MULTI,
      home,
      is_admin: this.admin_imported ? false : check_if_user_in_admin(),
      is_marketplace: check_if_user_in_marketplace(),
      content_items: {},
      open_liked_products: false,
      data_loaded: false,
      open_default: false,
      user_agreed_to_GDPR: localStorage.getItem("user_consents_to_GDPR") === "true",
    }
  },
  computed: {
    ...mapState(SHARED_STORE, [
      "is_mobile_device",
    ]),
    ...mapState(CLIENT_STORE, [
      "products",
      "translations",
      "header_items",
      "category_of_product_variety",
      "use_shopify_data",
      "rest_config",
      "project_styles",
      "project_config",
      "translations_object",
      "analytics_config",
      "selected_entity",
      "selected_language"
    ]),
    ...mapState([
      "global_loader",
    ]),
    ...mapGetters(CLIENT_STORE, {
      still_fetching_products: ARE_ANY_PRODUCTS_BEING_FETCHED
    }),
    /**
     * 
     */
    show_cookie_bar() {
      return is_user_in_EU() && !this.user_agreed_to_GDPR
    }
  },
  watch: {
    still_fetching_products(val) {
      if (!val) this.create_title(this.$route)
    },
    liked_products() {
      localStorage.setItem(user_liked_products, JSON.stringify(this.liked_products))
    },
    async data_loaded() {
      if (!this.admin_imported) {
        this.set_meta_fields()
        this.update_favicon()
        this.create_title(this.$route)
        await check_if_valid_visitor()
      }

      this.run_event_hubs()

      /**
       * Gets rid of all menu items except for Home and the product or collection in
       * @var {string} this.$route.query.product
       * @var {string} this.$route.query.collection
       * 
       * Works only on SINGLE and COUPLE product page
       */
      if (this.category_of_product_variety !== MULTI) {
        this.upload_header_items(
          this.header_items.filter(({ category, direct_route }) =>
            category === home ||
            (
              direct_route &&
              [
                this.$route.query[product],
                this.$route.query[collection]
              ].some(name => direct_route.includes(name))
            )
          )
        )
      }

      this.update_global_loader(false)
    },
    $route() {
      if ((this.data_loaded && !this.admin_imported) || this.is_marketplace) this.create_title(this.$route)
      if(this.is_mobile_device) event_hub.$emit("close_mobile_menu")

      window.scrollTo(0, 0)
    },
  },
  async created() {
    window.addEventListener("load", this.first_load)

    if (check_if_user_in_store_preview()) {
      this.set_store_id(get_store_preview_store_id())
    }
    if (this.admin_imported) {
      this.update_mobile_view(false)
      this.update_admin_imported(true)
    }
    if ((!this.is_admin && !this.is_marketplace) || this.admin_imported) await this.client_data_fetch()
    else if (this.is_marketplace) {
      this.update_favicon()
      this.create_title(this.$route)
      this.set_localstorage_basket()
    }

    this.add_loadscript_global_variable(this.$loadScript)
  },
  methods: {
    ...mapMutations(SHARED_STORE, {
      first_load: FIRST_LOAD,
      update_mobile_view: UPDATE_MOBILE_VIEW,
      set_store_id: SET_STORE_ID
    }),
    ...mapMutations(CLIENT_STORE, {
      update_products: UPDATE_PRODUCTS_DATA,
      has_store_about_page: HAS_STORE_ABOUT_PAGE,
      upload_header_items: UPDATE_HEADER_ITEMS,
      update_page_type: UPDATE_PAGE_TYPE,
      use_buy_now_button: TOGGLE_USE_BUY_NOW_BUTTON,
      update_temp_order_data: UPDATE_TEMP_ORDER_DATA,
      update_selected_entity: UPDATE_SELECTED_ENTITY,
      update_user_settings: UPDATE_USER_SETTINGS,
      update_selected_language: UPDATE_SELECTED_LANGUAGE,
      update_admin_imported: UPDATE_ADMIN_IMPORTED,
      set_liked_products: SET_LIKED_PRODUCTS
    }),
    ...mapActions(CLIENT_STORE, {
      fetch_products: FETCH_PRODUCTS_BY_IDS,
      base_data_fetch: BASE_DATA_FETCH
    }),
    ...mapMutations({
      update_global_loader: UPDATE_GLOBAL_LOADER,
      add_loadscript_global_variable: ADD_LOADSCRIPT_GLOBAL_VAR
    }),
    ...mapActions(SHARED_STORE, {
      set_whole_basket: SET_WHOLE_BASKET
    }),
    check_if_user_in_store_preview,
    get_total_items_array,
    async set_localstorage_basket() {
      const local_basket_data = window.localStorage.getItem(user_basket_items)
      const basket_items_from_localstorage = JSON.parse(local_basket_data === "undefined" ? null : local_basket_data) || []

      await this.fetch_products({ ids: basket_items_from_localstorage.map(({ id }) => id) })

      this.set_whole_basket(this.is_marketplace ?
        basket_items_from_localstorage : filter_out_unavailable_products(
          basket_items_from_localstorage === "undefined" ? [] : basket_items_from_localstorage
        )
      )
    },
    update_favicon() {
      const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = this.is_marketplace ? "/static/icons/ulsemo-icon.svg" : this.rest_config.favicon || this.rest_config.icon || default_image_src;

      document.head.appendChild(link);
    },
    /**
     * Sets user personalized settings according to browser fingerprint
     */
    set_user_settings() {
      const existing_fingerprint = window.localStorage.getItem(local_storage_ulsemo_user_fingerprint)

      find_and_update_user_settings({ existing_fingerprint, new_fingerprint: new_fingerprint() })
        .then(({ data : { settings, fingerprint } }) => {
          this.update_user_settings({ ...settings, fingerprint })
          window.localStorage.setItem(local_storage_ulsemo_user_fingerprint, fingerprint)
        })
    },
    /**
     * If product name is included in the URL parameter 
     * ?product=, only include the category containing the product within
     */
    determine_page_type() {
      if (window.location.search.includes("product=")) return SINGLE
      else if (window.location.search.includes("collection=")) return COUPLE

      return MULTI
    },
    /*
     * 
     */
    get_all_categories_from_object(obj){
      return Object.keys(obj).filter(val => val !== "last_id")
    },
    /*
     *
     */
    set_meta_fields() {
      document.querySelector('meta[name="description"]').setAttribute("content", (this.project_config || {}).seo_description || "")
      document.querySelector('meta[name="theme-color"]').setAttribute("content", (this.project_config || {}).theme_color || "")
      document.querySelector('meta[name="keywords"]').setAttribute("content", (this.project_config || {}).seo_keywords || "")
    },
    /*
     *
     */
    async initialize_analytics_scripts() {
      const promises = []
      const fb_init_func = async () => {
        const mainFunction = window.fbq = function() {
          mainFunction.callMethod ?
            mainFunction.callMethod.apply(mainFunction, arguments) : mainFunction.queue.push(arguments)
        }
        window._fbq = mainFunction
        mainFunction.push = mainFunction
        mainFunction.loaded = !0
        mainFunction.version = '2.0'
        mainFunction.queue = []

        try {
          await this.$loadScript("https://connect.facebook.net/en_US/fbevents.js")

          fbq("init", this.analytics_config.fb_pixel_id)
          fbq("track", "PageView")
        // eslint-disable-next-line no-empty
        } catch {}
      }

      const hotjar_init_func = async () => {
        const hotjar_stable_version = 6
        window.hj = window.hj || function() {
          (window.hj.q = window.hj.q || []).push(arguments)
        }
        window._hjSettings = {
          hjid: this.analytics_config.hotjar_id,
          hjsv: hotjar_stable_version
        }

        try {
          await this.$loadScript(`https://static.hotjar.com/c/hotjar-${this.analytics_config.hotjar_id}.js?sv=${hotjar_stable_version}`)
        } catch (error) {
          console.log(error)
        }
      }

      const google_verification_func = () => {
        document
          .querySelector(`meta[name="google-site-verification"]`)
          .setAttribute("content", this.analytics_config.google_site_verification)
      }

      const fb_verification_func = () => {
        document
          .querySelector(`meta[name="facebook-domain-verification"]`)
          .setAttribute("content", this.analytics_config.fb_site_verification)
      }
      
      if (this.analytics_config.google_site_verification) google_verification_func()
      if (this.analytics_config.fb_site_verification) fb_verification_func()
      if (this.analytics_config.fb_pixel_id) promises.push(fb_init_func())
      if (this.analytics_config.hotjar_id) promises.push(hotjar_init_func())

      await Promise.all(promises)
    },
    /*
     *
     */
    create_title(route) {
      let suffix_value

      if (route.params.subcategory) {
        if (route.params.product) suffix_value = get_correct_translation_value(
          this.products.find(({ handle }) => handle === route.params.product) || {},
          "title",
          [this.selected_language]
        ) || route.params.product
        else suffix_value = this.use_shopify_data ? route.params.category : route.params.subcategory
      }
      else suffix_value = route.params.category ?
        route.params.category :
        (route.params.type ? route.params.type : route.name)

      const prefix = `${this.translations ? this.translations.website_name : "Ulsemo"} | `
      const suffix = capitalize_word(suffix_value)

      document.title = this.is_marketplace ?
        "Ulsemo marketplace" :
        (prefix + (this.is_admin ? "Admin" : suffix))
    },
    /**
     *
     */
    convert_item_name_to_readable_format(name) {
      return name.split("-").slice(1).join(" ")
    },
    /**
     * 
     */
    async client_data_fetch() {
      this.set_localstorage_basket()
      const liked_products = JSON.parse(
        window.localStorage.getItem(user_liked_products)
      ) || []

      this.set_liked_products(liked_products)

      await this.base_data_fetch()
      const correct_language = this.admin_imported ?
        Object.keys(this.translations_object)[0] :
        (
          window.localStorage.getItem(user_selected_language) ||
          (this.$route.query || {}).lang ||
          Object.keys(this.translations_object)[0]
        )

      this.update_selected_language(
        this.translations_object[correct_language] ? correct_language : Object.keys(this.translations_object)[0]
      )

      this.update_page_type(this.determine_page_type())
      this.use_buy_now_button(this.category_of_product_variety !== MULTI)
      if (!this.admin_imported) this.initialize_analytics_scripts()

      const admin_iframe = document.getElementById("store-preview-iframe");

      // Setting global colors
      if (this.project_styles) Object.entries(this.project_styles.colors).forEach(([key, val]) =>
        (admin_iframe ? admin_iframe.contentWindow.document : document).documentElement.style.setProperty(`--${key}`, val)
      )

      if (this.category_of_product_variety !== MULTI) {
        const params = new Proxy(new URLSearchParams(window.location.search), {
          get: (searchParams, prop) => searchParams.get(prop),
        });

        this.update_selected_entity(
          html_enity_encoder(
            params[product] ||
            params[collection]
          )
        )
      }

      window.default_main_page = this.get_all_categories_from_object(this.products)[0]

      this.$nextTick(() => this.data_loaded = true)
    },
    /*
     *
     */
    run_event_hubs() {
      event_hub.$on("delete_basket_item", this.delete_basket_item)
      event_hub.$on("user_has_payed", order_data => {
        this.set_whole_basket()
        this.update_temp_order_data(order_data)
        this.$router.push("/thank-you")
      })
      event_hub.$on("user_agreed_to_GDPR", () => this.user_agreed_to_GDPR = true)
    }
  }
};
</script>

<style lang="scss">
@use "./styles/_global-constants" as *;

* {
  -webkit-tap-highlight-color: transparent;
}

body {
  font-family: $main-font;
  color: var(--font_heavy);
  background-color: var(--body_color);
  margin: 0;
  padding-top: $menu-height;
}

h1, h2, h3, h4, h5, h6 {
  box-sizing: border-box;
}


a {
  color: $pure-white;
  text-decoration: none !important;
}



.item-slider-buttons {
  margin: 0 0 5px;
  text-align: center;

  h3.item-slider-buttons__not-selected {
    cursor: pointer;
    color: rgba(var(--background_heavy_RGB), 0.6);
    border-bottom: 1px solid transparent;
  }
}

.webpage {
  position: relative;
  min-height: 100vh;

  &__store-preview-error {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    padding: $page-padding;
    width: 100%;
    max-width: 500px;
    background-color: $pure-white;
    font-weight: bold;
  }

  &__loading {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 102;
    background-color: rgba(var(--body_color_RGB), 0.5);

    &-content {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1000;
      text-align: center;

      .spinner {
        width: 73px;
        margin: auto;
        height: 73px;

        &-inner {
          &.loading {
            transform: scale(1.2) !important;
          }
        }
      }
    }
  }

  h3 {
    font-size: 24px !important;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 29px;
    padding: 15px 15px 5px;
    margin-bottom: 0;
    color: var(--background_heavy) !important;
    transition: $default-transition;

    @media (max-width: $mobile) {
      font-size: 18px;
      padding: 15px 5px 5px;
    }
  }

  h5 {
    font-size: 17px;
    color: $pure-black;
    text-align: center;
  }
}

.wrapper {
  position: relative;
  max-width: $maximum-width;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 40px;
  min-height: calc(100vh - #{$menu-height});
  box-sizing: border-box;
  overflow: hidden;

  @media (max-width: $maximum-width) {
    padding: 0 $half-page-padding $page-padding;
  }
}

.main-app-wrapper {
  min-height: calc(100vh - #{$menu-height} - 170px); // total height - top menu bar - footer
}

.alert {
  color: $alert-color;
}

.button {
  position: relative;
  margin: 5px 0;
  text-align: center;
  line-height: 36px;
  background-color: var(--background_heavy);
  cursor: pointer;
  color: var(--font_light);
  border: 1px solid var(--background_heavy);
  border-radius: $border-radius;
  transition: color 250ms ease-in-out, background-color 250ms ease-in-out;

  svg {
    display: inline-block;
    vertical-align: text-top;
    transition: $default-transition;
    fill: var(--font_light);
  }

  &:hover {
    color: var(--background_heavy);
    background-color: var(--font_light);

    svg {
      fill: var(--background_heavy);
    }
  }

  &:disabled {
    cursor: auto;
    filter: grayscale(80%);

    &:hover {
      color: var(--font_light);
      background-color: var(--background_heavy);
    }
  }

  @media (max-width: $tablet) {
    height: 46px;
    line-height: 46px;
  }

  &--green {
    background-color: $green-color;
    border-color: $green-color;
    color: $pure-white;

    &:hover {
      color: $green-color;
      background-color: $pure-white;
      border: 1px solid $green-color;
    }
  }

  &--red {
    background-color: $red-color;
    border-color: $red-color;

    &:hover {
      color: $pure-white;
      background-color: $red-color;
    }
  }

  &--half-size {
    display: inline-block;
    width: 50%;
    box-sizing: border-box;
  }

  &--reverse {
    color: var(--background_heavy);
    background-color: var(--body_color);
    border-color: var(--background_heavy);

    &:hover {
      color: var(--background_heavy);
      background-color: var(--body_color);
      border-color: var(--background_heavy);
      filter: grayscale(40%);
    }
  }

  &--naked {
    padding: 0 10px;
    border: none;
    height: 30px;
    line-height: 30px !important;

    &:before {
      position: absolute;
      display: block;
      bottom: 0;
      left: 0;
      right: 0;
      height: 0;
      max-height: 1px;
      opacity: 0;
      background-color: var(--background_heavy);
      content: "";
      transition: 200ms linear;
    }

    &:hover {
      &:before {
        opacity: 1;
        height: 1px;
        max-height: 1px;
      }
    }
  }

  &--wide {
    padding: 0 20px;
  }

  &--full-width {
    display: block;
    width: 100%;
  }
}

.link {
  color: unset;
  text-decoration: unset;
}

::placeholder {
  color: var(--font_heavy);
  opacity: 0.5;
  text-align: center;
  font-family:  $main-font, "Courier New", Courier, monospace;
}

input {
  border-radius: 0;
  user-select: text;
}

.multiselect input {
  border: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance: textfield;
}

.small-title {
  font-size: 15px;
}
</style>
