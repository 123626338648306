import { default_language } from "../../Shared/constants/other";
import { DEFAULT_MARKETPLACE_CURRENCY } from "../constants/other";

export const UPDATE_CATEGORIES = "UPDATE_CATEGORIES"

export const base_state = {
  marketplace_currency: DEFAULT_MARKETPLACE_CURRENCY,
  marketplace_language: default_language,
  categories: {}
}
