import store from "../../../store"
import { CLIENT_STORE } from "../constants/other"
import get_message_or_types_from_product_description from "../../Shared/methods/get_message_or_types_from_product_description"
import { UPDATE_SHOPIFY_CHECKOUT } from "../stores/Client/constants"

export default async (data, type_of_action) => {
  const adding_new_lineitem = type_of_action === "add"

  if(type_of_action === "remove") data = [data.chosen_shopify_product_lineitem_id]
  else {
    data = data.length ?
      data.map(({ variant_id: variantId, chosen_shopify_product_lineitem_id, quantity }) => ({
        [ 
          adding_new_lineitem ? "variantId" : "id"
        ]: adding_new_lineitem ? variantId : chosen_shopify_product_lineitem_id,
        quantity: parseInt(quantity, 10)
      })) :
      [{
        [ 
          adding_new_lineitem ? "variantId" : "id"
        ]: adding_new_lineitem ? data.variant_id : data.chosen_shopify_product_lineitem_id,
        quantity: parseInt(data.quantity, 10)
      }]
  }

  // TODO Shopify will only add to cart products, which are made available on this sales channel in Shopify admin
  if(store.state[CLIENT_STORE].shopify_checkout.id) {
    const response = await store.state[CLIENT_STORE]
      .shopify_instance
      .checkout[`${type_of_action}LineItems`](store.state[CLIENT_STORE].shopify_checkout.id, data)
      .catch(err => console.log(err))

    const basket_products_with_lineitem_ids = response.lineItems.map(({ variant, id, title, quantity }) => {
      const product = store.state[CLIENT_STORE].products.find(({ variants }) => variants.some(({ id }) => variant.id === id))

      return {
        id: product.id,
        variant_id: variant.id,
        chosen_shopify_product_lineitem_id: id,
        price: variant.price,
        compare_at_price: variant.compareAtPrice,
        title,
        handle: product.handle,
        variant_options: product.options.map(({ name }, ind) => ({
          name,
          value: product.variants.find(({ id }) => variant.id === id)[`option${ind + 1}`]
        })),
        quantity,
        message: get_message_or_types_from_product_description(product.body_html, true),
        collections: product.collections,
        image: (variant.image || product.images[0]).src
      }
    })

    store.commit(`${CLIENT_STORE}/${UPDATE_SHOPIFY_CHECKOUT}`, response)

    return basket_products_with_lineitem_ids
  }

  return []
}
