/*
*  Creates an array of all items in an object
*/

export default (obj, viewed_item_category) => [
    ...Object.values(
      obj[viewed_item_category]
    ).reduce(
        (tot, el) =>{
          return [...tot, ...el]
        }
        ,[]
      )
  ]