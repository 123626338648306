import {v4 as uuid} from "uuid";

export default {
  domain: "",
  email_service: "",
  info_email: "",
  order_email: "",
  order_email_password: "",
  project_id: uuid(),
  seo_description: "",
  seo_keywords: [],
  theme_color: "",
  use_paypal_payment_gateway: true
}
