import Vue from "vue"
import {
  ADD_NEW_MENU_ITEM,
  ADD_NEW_MENU_SUB_ITEM,
  UPDATE_MENU_ITEM_NAME,
  DELETE_OR_RESTORE_MENU_ITEM,
  UPDATE_MENU_ITEMS_CASE,
  UPDATE_FOOTER_CONTENT,
  UPDATE_COLLECTION_BANNER,
  DELETE_OR_RESTORE_COLLECTION_BANNER,
  ADD_COLLECTION_BANNER,
  CREATE_SPECIAL_MESSAGE_BANNER,
  DELETE_SPECIAL_MESSAGE_BANNER,
  UPDATE_POPULAR_PRODUCTS,
  UPDATE_HIGHLIGHTED_PRODUCTS,
  UPDATE_STORY_BANNER_IMAGES,
  UPDATE_STORY_BANNER_MESSAGE,
  UPDATE_STORY_BANNER_TITLE,
  DELETE_STORY_BANNER,
  UPDATE_STORY_BANNER_BUTTONS,
  UPDATE_IMPORTANT_MESSAGE_BAR,
  EDITED_PAGE_TRANSLATIONS, UPDATE_CONTENT_DATA, UPDATE_CONTENT_DATA_BY_KEY,
  UPDATE_SPECIAL_MESSAGE_BANNER_LASER,
  UPDATE_SPECIAL_MESSAGE_BANNER_USE_ON_PAGES,
  UPDATE_ABOUT_PAGE_ABILITY, DELETE_ABOUT_PAGE_TILES, UPDATE_ABOUT_PAGE_TILE,
  ADD_ABOUT_PAGE_TILES, UPDATE_ABOUT_PAGE_TEXTS, UPDATE_ABOUT_PAGE_TOP_PARAGRAPH,
  ADD_ABOUT_PAGE_BOTTOM_PARAGRAPH, UPDATE_ABOUT_PAGE_BOTTOM_PARAGRAPH,
  DELETE_ABOUT_PAGE_BOTTOM_PARAGRAPH, UPDATE_ABOUT_PAGE_BANNER, DELETE_ABOUT_PAGE_BANNER,
  UPDATE_ABOUT_PAGE_LASER_USAGE, CONVERT_ABOUT_PAGE_TILE_TO_IMAGE,
  CONVERT_ABOUT_PAGE_TILE_TO_TEXT, SWITCH_ABOUT_PAGE_LANGUAGE, REMOVE_ABOUT_PAGE_LANGUAGE,
  TOGGLE_ABOUT_PAGE_ABILITY, SET_EMPTY_ABOUT_PAGE,
  UPDATE_POPULAR_PRODUCTS_POSITIONING,
  UPDATE_HIGHLIGHTED_PRODUCTS_POSITIONING,
  UPDATE_MENU_ITEMS, REARRANGE_SPECIAL_MESSAGE_BANNER,
  UPDATE_SPECIAL_MESSAGE_BANNER
} from "./constants"
import empty_menu_item from "../../../constants/empty_objects/empty_menu_item"
import { ADMIN, deleted, PRODUCTS } from "../../../constants/others_constants"
import { UPDATE_ERROR_LIST, CLEAR_ERRORS_SECTION } from "../constants"
import empty_collection_banner from "../../../constants/empty_objects/empty_collection_banner"
import { translate } from "../../../../Shared/translator"
import empty_about_page from "../../../constants/empty_objects/empty_about_page"
import un_bind from "../../../../Shared/methods/un_bind"
import store from "../../../../../store"

export default {
  [UPDATE_SPECIAL_MESSAGE_BANNER](state, [banner_index, updated_banner]) {
    if (state.banners?.special_message_banner?.constructor === Array)
      Vue.set(state.banners.special_message_banner, banner_index, updated_banner)
    else Vue.set(state.banners, "special_message_banner", [updated_banner])
  },
  [REARRANGE_SPECIAL_MESSAGE_BANNER](state, updated_arrangement) {
    Vue.set(state.banners, "special_message_banner", updated_arrangement)
  },
  [SET_EMPTY_ABOUT_PAGE](state) {
    Vue.set(state, "about_page_content", un_bind(empty_about_page))
  },
  [TOGGLE_ABOUT_PAGE_ABILITY](state, value) {
    Vue.set(state.about_page_content, "enable_about_page", value)
  },
  [REMOVE_ABOUT_PAGE_LANGUAGE](state, language) {
    if (state.about_page_content.bottom_paragraphs.length) {
      state.about_page_content.bottom_paragraphs.forEach((_, index) => 
        Vue.delete(state.about_page_content.bottom_paragraphs[index], language)
      )
    }
    if (state.about_page_content.tile_pairs.length) {
      state.about_page_content.tile_pairs.forEach((tile_pair, index) =>
        tile_pair.forEach(({ src }, tile_index) => src
          ? null : Vue.delete(state.about_page_content.tile_pairs[index][tile_index], language))
      )
    }
    if (state.about_page_content.top_paragraph) Vue.delete(state.about_page_content.top_paragraph, language)

    Vue.delete(state.about_page_content.texts, language)
  },
  [SWITCH_ABOUT_PAGE_LANGUAGE](state, language) {
    // If language already exists, do nothing
    if (state.about_page_content.texts[language]) return

    if (state.about_page_content.bottom_paragraphs.length) {
      state.about_page_content.bottom_paragraphs.forEach((_, index) => 
        Vue.set(state.about_page_content.bottom_paragraphs[index], language, {
          title: "",
          message: ""
        })
      )
    }
    if (state.about_page_content.tile_pairs.length) {
      state.about_page_content.tile_pairs.forEach((tile_pair, index) =>
        tile_pair.forEach(({ src }, tile_index) => src ? null : Vue.set(
          state.about_page_content.tile_pairs[index][tile_index], language, "")
        )
      )
    }
    if (state.about_page_content.top_paragraph) Vue.set(
      state.about_page_content.top_paragraph, language, ""
    )
    Vue.set(state.about_page_content.texts, language, { title: "", quote: "" })
  },
  [UPDATE_ABOUT_PAGE_LASER_USAGE](state, value) {
    Vue.delete(state.about_page_content, "use_laser", value)
  },
  [UPDATE_ABOUT_PAGE_ABILITY](state, value) {
    Vue.delete(state.about_page_content, "enable_about_page", value)
  },
  [DELETE_ABOUT_PAGE_TILES](state, pair_index) {
    Vue.delete(state.about_page_content.tile_pairs, pair_index)
  },
  [UPDATE_ABOUT_PAGE_TILE](state, [pair_index, tile_index, key, value]) {
    Vue.set(state.about_page_content.tile_pairs[pair_index][tile_index], key, value)
  },
  [CONVERT_ABOUT_PAGE_TILE_TO_TEXT](state, [pair_index, tile_index, language]) {
    Vue.set(state.about_page_content.tile_pairs[pair_index], tile_index, { [language]: "" })
  },
  [CONVERT_ABOUT_PAGE_TILE_TO_IMAGE](state, [pair_index, tile_index]) {
    Vue.set(state.about_page_content.tile_pairs[pair_index], tile_index, { src: {} })
  },
  [ADD_ABOUT_PAGE_TILES](state, index) {
    if (!state.about_page_content.tile_pairs) Vue.set(state.about_page_content, "tile_pairs", [])

    Vue.set(state.about_page_content.tile_pairs, index, [{}, {}])
  },
  [UPDATE_ABOUT_PAGE_TEXTS](state, [language, key, value]) {
    if (!state.about_page_content.texts) Vue.set(state.about_page_content, "texts", {})
    if (!state.about_page_content.texts[language]) Vue.set(state.about_page_content.texts, language, {})

    Vue.set(state.about_page_content.texts[language], key, value)
  },
  [UPDATE_ABOUT_PAGE_TOP_PARAGRAPH](state, [language, value]) {
    if (!state.about_page_content.top_paragraph) Vue.set(state.about_page_content, "top_paragraph", {})

    Vue.set(state.about_page_content.top_paragraph, language, value)
  },
  [ADD_ABOUT_PAGE_BOTTOM_PARAGRAPH](state, [index, language]) {
    if (!state.about_page_content.bottom_paragraphs) Vue.set(state.about_page_content, "bottom_paragraphs", [])

    Vue.set(state.about_page_content.bottom_paragraphs, index, {
      [language]: {
        title: "",
        message: ""
      }
    })
  },
  [UPDATE_ABOUT_PAGE_BOTTOM_PARAGRAPH](state, [index, language, key, value]) {
    Vue.set(state.about_page_content.bottom_paragraphs[index][language], key, value)
  },
  [DELETE_ABOUT_PAGE_BOTTOM_PARAGRAPH](state, index) {
    Vue.delete(state.about_page_content.bottom_paragraphs, index)
  },
  [UPDATE_ABOUT_PAGE_BANNER](state, [banner_type, src, thumb_src]) {
    Vue.set(state.about_page_content, banner_type, { src, thumb_src })
  },
  [DELETE_ABOUT_PAGE_BANNER](state, banner_type) {
    Vue.set(state.about_page_content, banner_type, {})
  },
  [ADD_NEW_MENU_ITEM](state, [name, category, sub_items, is_parent_category, entity_id, languages]) {
    Vue.set(
      state,
      "header_items",
      [
        ...state.header_items,
        empty_menu_item(true, name, category, undefined, sub_items, is_parent_category, entity_id, languages)
      ]
    )
  },
  [ADD_NEW_MENU_SUB_ITEM](state, [index_of_header_item, name, sub_category, languages] = []) {
    const header_item = state.header_items[index_of_header_item]

    Vue.set(
      header_item,
      "sub_items",
      [
        ...(header_item.sub_items || []),
        empty_menu_item(false, name, undefined, sub_category, undefined, undefined, null, languages)
      ]
    )
  },
  [UPDATE_MENU_ITEM_NAME](state, [index_of_header_item, value, index_of_header_sub_item, language]) {
    const header_item = state.header_items[index_of_header_item]

    if (index_of_header_sub_item >= 0) {
      if (
        !header_item.sub_items[index_of_header_sub_item].translations
      ) Vue.set(header_item.sub_items[index_of_header_sub_item], "translations", { [language]: {} })
      if (
        !header_item.sub_items[index_of_header_sub_item].translations[language]
      ) Vue.set(header_item.sub_items[index_of_header_sub_item].translations, language, { name: "" })

      Vue.set(
        header_item.sub_items,
        index_of_header_sub_item,
        {
          ...header_item.sub_items[index_of_header_sub_item],
          translations: {
            ...(header_item.sub_items[index_of_header_sub_item].translations || {}),
            [language]: {
              name: value
            }
          }
        }
      )
    }
    else {
      if (!header_item.translations) Vue.set(header_item, "translations", { [language]: {} })
      if (!header_item.translations[language]) Vue.set(header_item.translations, language, { name: "" })

      Vue.set(header_item.translations[language], "name", value)
    }
  },
  [UPDATE_MENU_ITEMS](state, items) {
    Vue.set(state, "header_items", items)
  },
  [DELETE_OR_RESTORE_MENU_ITEM](state, [index_of_header_item, index_of_header_sub_item]) {
    const header_item = state.header_items[index_of_header_item]

    if (index_of_header_sub_item >= 0) Vue.set(
      header_item.sub_items[index_of_header_sub_item],
      "change_type",
      header_item.sub_items[index_of_header_sub_item].change_type ? undefined : deleted
    )
    else Vue.set(header_item, "change_type", header_item.change_type ? undefined : deleted)
  },
  [UPDATE_MENU_ITEMS_CASE](state, use_upper_case) {
    state.header_items = state.header_items.map(item => ({ ...item, use_upper_case }))
  },
  [UPDATE_FOOTER_CONTENT](state, [footer_content, language]) {
    if (typeof state.footer_content === "string") state.footer_content = {}

    state.footer_content[language] = footer_content
  },
  [UPDATE_COLLECTION_BANNER](state, [key, index_of_banner, value]) {
    Vue.set(state.banners.collection_banners[index_of_banner], key, value)
  },
  [DELETE_OR_RESTORE_COLLECTION_BANNER](state, [index, value]) {
    Vue.set(state.banners.collection_banners[index], "change_type", value)
  },
  [ADD_COLLECTION_BANNER](state, [category, sub_category, img, mobile_image, direct_route, button_text]) {
    if (!state.banners) Vue.set(state, "banners", {})
    if (!state.banners.collection_banners) Vue.set(state.banners, "collection_banners", [])

    Vue.set(state.banners, "collection_banners", [
      ...state.banners.collection_banners,
      empty_collection_banner(category, sub_category, img, mobile_image, direct_route, button_text)
    ])
  },
  [UPDATE_SPECIAL_MESSAGE_BANNER_LASER](state, value) {
    Vue.set(state.banners.special_message_banner, "use_laser", value)
  },
  [UPDATE_SPECIAL_MESSAGE_BANNER_USE_ON_PAGES](state, value) {
    Vue.set(state.banners.special_message_banner, "show_on_single_and_couple", value)
  },
  async [CREATE_SPECIAL_MESSAGE_BANNER](state, banner_data) {
    if (!state.banners) Vue.set(state, "banners", {})
    if (!state.banners.special_message_banner) Vue.set(state.banners, "special_message_banner", [banner_data])
    else {
      if (state.banners.special_message_banner?.constructor === Array)
        Vue.set(state.banners, "special_message_banner", [...state.banners.special_message_banner, banner_data])
      else
        Vue.set(state.banners, "special_message_banner", [state.banners.special_message_banner, banner_data])
    }
  },
  [DELETE_SPECIAL_MESSAGE_BANNER](state, banner_index) {
    if (state.banners.special_message_banner?.constructor === Array)
      Vue.delete(state.banners.special_message_banner, banner_index)
    else
      Vue.delete(state.banners, "special_message_banner")
  },
  async [UPDATE_STORY_BANNER_IMAGES](state, [src, mobile_image]) {
    Vue.set(state.banners, "story_banner", {
      ...(state.banners.story_banner && state.banners.story_banner),
      src,
      mobile_image,
      alt: await translate("story_banner.name")
    })
  },
  [UPDATE_STORY_BANNER_MESSAGE](state, message) {
    Vue.set(state.banners, "story_banner", { ...state.banners.story_banner, message })
  },
  [UPDATE_STORY_BANNER_TITLE](state, title) {
    Vue.set(state.banners, "story_banner", { ...state.banners.story_banner, title })
  },
  [UPDATE_STORY_BANNER_BUTTONS](state, buttons) {
    Vue.set(state.banners, "story_banner", { ...state.banners.story_banner, buttons })
  },
  [DELETE_STORY_BANNER](state) {
    Vue.set(state.banners, "story_banner", "")
  },
  [UPDATE_IMPORTANT_MESSAGE_BAR](state, message) {
    Vue.set(state, "important_message_bar", message)
  },
  [UPDATE_POPULAR_PRODUCTS](state, id) {
    Vue.set(
      state,
      "popular_products",
      (
        state.popular_products.includes(id) ?
          state.popular_products.filter(prod_id => prod_id !== id) : [...state.popular_products, id]
      ).filter(prod_id => [
        ...(store.state[ADMIN][PRODUCTS].imported_products || []),
        ...(store.state[ADMIN][PRODUCTS].shopify_products || [])
      ].some(({ id }) => prod_id === id)) // Filter out products which no longer exist
    )
  },
  [UPDATE_POPULAR_PRODUCTS_POSITIONING](state, newly_positioned_products) {
    Vue.set(
      state,
      "popular_products",
      newly_positioned_products
    )
  },
  [UPDATE_HIGHLIGHTED_PRODUCTS](state, id) {
    Vue.set(
      state,
      "highlighted_products",
      (
        state.highlighted_products.includes(id) ?
        state.highlighted_products.filter(prod_id => prod_id !== id):
        [...state.highlighted_products, id]
      ).filter(prod_id => [
        ...(store.state[ADMIN][PRODUCTS].imported_products || []),
        ...(store.state[ADMIN][PRODUCTS].shopify_products || [])
      ].some(({ id }) => prod_id === id)) // Filter out products which no longer exist
    )
  },
  [UPDATE_HIGHLIGHTED_PRODUCTS_POSITIONING](state, newly_positioned_products) {
    Vue.set(
      state,
      "highlighted_products",
      newly_positioned_products
    )
  },
  [EDITED_PAGE_TRANSLATIONS](state, val) {
    state.page_translations_edited = val
  },
  [CLEAR_ERRORS_SECTION](state, error_id) {
    state.error_list = state.error_list.filter(({ id }) => id !== error_id)
  },
  [UPDATE_ERROR_LIST](state, value) {
    state.error_list = value
  },
  [UPDATE_CONTENT_DATA](state, data) {
    Object.entries(data).forEach(([key, value]) => Vue.set(state, key, value))
  },
  [UPDATE_CONTENT_DATA_BY_KEY](state, [key, data]) {
    Vue.set(state, key, data)
  }
}
