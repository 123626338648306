import mutations from "./mutations"
import actions from "./actions"
import { base_state } from "./constants"

import Products from "./products"
import Content from "./content"
import Configuration from "./configuration"
import Finance from "./finance"
import User from "./user"
import Social from "./social"

export default {
  modules: {
    Products,
    Content,
    Configuration,
    Finance,
    User,
    Social
  },
  namespaced: true,
  state: { ...base_state },
  mutations,
  actions,
}
