import mutations from "./mutations"
import actions from "./actions"
import { base_state } from "./constants"

import Products from "./products"

export default {
  modules: {
    Products
  },
  namespaced: true,
  state: { ...base_state },
  mutations,
  actions,
}
