import { google_api_key, google_auth_scopes, google_client_id } from "../constants/social_media_constants"

export default ($load_script, re_signin) => new Promise(async resolve => {
  if (!window.gapi) await $load_script("https://apis.google.com/js/api.js")

  if (window.gapi) await window.gapi.load("client:auth2", async () => {
    await window.gapi.client.init({
      apiKey: google_api_key,
      clientId: google_client_id,
      scope: google_auth_scopes.join(" "),
      discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"]
    })

    if (
      !re_signin &&
      window.window.gapi.auth2.getAuthInstance().isSignedIn.get() &&
      google_auth_scopes.every(scope =>
        window.gapi.auth2.getAuthInstance().currentUser.get().Zb?.scope?.split(" ").includes(scope)
      )
    ) {
      await window.gapi.auth2.getAuthInstance().currentUser.get().reloadAuthResponse()
    } else await window.gapi.auth2.getAuthInstance().signIn();

    const user_profile = window.gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile();

    resolve({
      ...window.gapi.auth2.getAuthInstance().currentUser.get().Zb,
      name: user_profile.getEmail(),
      id: user_profile.getId(),
    })
  })
})
