import { api } from "../../Shared/methods/ajax/axios_instance";

  // Get shipping rates
  export const get_shipping_rates = ({ country_code, products, state, currency }) => api.post(
    "calculate_shipping_price",
    { country_code, products, state, currency }
  )

  // Get page type translations
  export const fetch_page_type_data = ({ page_id, page_type }) => api.post(
    "fetch_page_type_data",
    { page_id, page_type }
  )

  // Get page type translations
  export const fetch_about_page = () => api.get("fetch_about_page")

  // Get legal page translations
  export const legal_page_translations = ({ language, pageType }) => api.post("legal_page_translations", { language, pageType })

  // Count valuable visitor
  export const count_store_visitor = () => api.post("visitor")
