// Imports
import check_if_mobile_device from "../../methods/check_if_mobile_device"
import connection_types from "../../../../data/connection_types"
import { MULTI } from "../../../../data/product_variety_types"
import { languages } from "../../../Shared/constants/other"
import project_styles from "../../../../data/project_styles"

// Actions
export const FETCH_PRODUCTS_BY_IDS = "FETCH_PRODUCTS_BY_IDS"
export const BASE_DATA_FETCH = "BASE_DATA_FETCH"

// Mutations
export const UPDATE_PRODUCTS_DATA = "UPDATE_PRODUCTS_DATA"
export const UPDATE_PRODUCTS_BEING_FETCHED = "UPDATE_PRODUCTS_BEING_FETCHED"
export const SET_VIEWED_PRODUCTS = "SET_VIEWED_PRODUCTS"
export const UPDATE_VIEWED_PRODUCT = "UPDATE_VIEWED_PRODUCT"
export const TOGGLE_LIKED_PRODUCT_STATE = "TOGGLE_LIKED_PRODUCT_STATE"
export const SET_LIKED_PRODUCTS = "SET_LIKED_PRODUCTS"
export const UPDATE_PRODUCT_BEING_VIEWED = "UPDATE_PRODUCT_BEING_VIEWED"
export const ADD_PAGE_TYPE_TRANSLATIONS = "ADD_PAGE_TYPE_TRANSLATIONS"
export const UPDATE_ABOUT_PAGE = "UPDATE_ABOUT_PAGE"
export const HAS_STORE_ABOUT_PAGE = "HAS_STORE_ABOUT_PAGE"
export const UPDATE_SHOPIFY_CHECKOUT = "UPDATE_SHOPIFY_CHECKOUT"
export const UPDATE_HEADER_ITEMS = "UPDATE_HEADER_ITEMS"
export const UPDATE_PAGE_TYPE = "UPDATE_PAGE_TYPE"
export const TOGGLE_USE_BUY_NOW_BUTTON = "TOGGLE_USE_BUY_NOW_BUTTON"
export const UPDATE_TRANSLATIONS = "UPDATE_TRANSLATIONS"
export const UPDATE_SHIPPING_OPTIONS = "UPDATE_SHIPPING_OPTIONS"
export const UPDATE_BANNERS = "UPDATE_BANNERS"
export const UPDATE_POPULAR_PRODUCTS = "UPDATE_POPULAR_PRODUCTS"
export const UPDATE_HIGHLIGHTED_PRODUCTS = "UPDATE_HIGHLIGHTED_PRODUCTS"
export const UPDATE_IMPORTANT_MESSAGE_BAR = "UPDATE_IMPORTANT_MESSAGE_BAR"
export const UPDATE_FOOTER = "UPDATE_FOOTER"
export const UPDATE_STYLES = "UPDATE_STYLES"
export const UPDATE_ANALYTICS_CONFIG = "UPDATE_ANALYTICS_CONFIG"
export const UPDATE_PROJECT_CONFIG = "UPDATE_PROJECT_CONFIG"
export const UPDATE_REST_CONFIG = "UPDATE_REST_CONFIG"
export const TOGGLE_SHOPIFY_USE = "TOGGLE_SHOPIFY_USE"
export const UPDATE_OTHER_PAGE_TRANSLATIONS = "UPDATE_OTHER_PAGE_TRANSLATIONS"
export const TOGGLE_DEMO_STATE = "TOGGLE_DEMO_STATE"
export const UPDATE_SHOPIFY_INSTANCE = "UPDATE_SHOPIFY_INSTANCE"
export const UPDATE_SELECTED_ENTITY = "UPDATE_SELECTED_ENTITY"
export const UPDATE_USER_SETTINGS = "UPDATE_USER_SETTINGS"
export const UPDATE_SELECTED_LANGUAGE = "UPDATE_SELECTED_LANGUAGE"
export const UPDATE_COLLECTIONS = "UPDATE_COLLECTIONS"
export const UPDATE_DATA = "UPDATE_DATA"
export const UPDATE_ADMIN_IMPORTED = "UPDATE_ADMIN_IMPORTED"
export const COUNT_VISITOR_INTERACTION = "COUNT_VISITOR_INTERACTION"
export const UPDATE_VISITOR_INTERATION_CHECKS = "UPDATE_VISITOR_INTERATION_CHECKS"
export const UPDATE_TEMP_ORDER_DATA = "UPDATE_TEMP_ORDER_DATA"

// Getters
export const ARE_ANY_PRODUCTS_BEING_FETCHED = "ARE_ANY_PRODUCTS_BEING_FETCHED"

export const initial_state = {
  viewed_products: [],
  header_items: [],
  basket_items: [],
  liked_products: [],
  collections: [],
  products_being_fetched: [],
  products: [],
  order_data: {},
  shipping_options: {},
  translations: {},
  project_config: {},
  analytics_config: {},
  shipping_option: {},
  other_page_translations: {},
  visitor_interaction_checks: {
    has_clicked: false,
    has_stayed_over_min_period: false,
    is_not_common_bot: false
  },
  banners: { collection_banners: [] },
  single_page_translations: {},
  couple_products_homepage_translations: {},
  footer_content: "",
  important_message_bar: "",
  selected_language: languages[0],
  vieving_item: false,
  show_preview_basket: false,
  is_free_shipping: false,
  is_admin_imported: false,
  loader: true,
  viewed_product_id: null,
  viewed_item_category: null,
  viewed_item_types: null,
  temp_order_data: null, // Used for temporarily storing data of order payed via bank transfer or pickup
  discount_code: {},
  is_production: process.env.NODE_ENV === "production" ? true : false,
  /**
   * Check if user is either on desktop (presumes fast connection)
   * or is using mobile and has fast connection
   */
  user_is_using_fast_connection: navigator?.connection ?
    connection_types.fast.includes(navigator.connection?.effectiveType) :
    !check_if_mobile_device(project_styles),
  user_settings: {},
  // set to MULTI by default
  category_of_product_variety: MULTI,
  use_buy_it_now: false,
  // used for signle and couple product pages (product, collection)
  selected_entity: "",
  /**
   * 
   * Shopify specific data instances
   * Should be adjusted per project
   * 
   */
  shopify_instance: {},
  shopify_checkout: { lineItems: [] },
  shopify_highlighted_items: [],
  project_styles,
}
