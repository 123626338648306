import { created } from "../constants/others_constants"
import upload_image from "./upload_image"
import {default_image_src} from "../../Shared/constants/other";

export default async products => await Promise.all(products.map(async product => {
  const images_for_upload = (product.images || []).filter(image => image?.change_type === created)

  if (!images_for_upload.length) return product

  const uploaded_images = await Promise.all(
    images_for_upload.map(async image => {
      let file

      try {
        if (!image.file) file = await fetch(image.src)
          .then(async response => {
            const contentType = response.headers.get("content-type")
            const blob = await response.blob()
            return new File([blob], image.route, { contentType })
          })

        const uploaded_image = await upload_image(
          image.file ? image : { file },
          image.route,
          false,
        )

        return {
          src: uploaded_image,
          id: image.id,
          alt_text: image.alt_text,
        }
      } catch {
        return {
          src: default_image_src,
          id: image.id,
        }
      }
    })
  )

  return {
    ...product,
    images: product.images.map(image => uploaded_images.find(({ id }) => image.id === id) || image)
  }
}))
