import { api } from "../../../Shared/methods/ajax/axios_instance"
import { order_action_types } from "../../../Shared/constants/other"
import { CAN_MANAGE_MODULE_CONFIGURATION_SHOPIFY, CAN_MANAGE_MODULE_FINANCE_DISCOUNTS } from "../permissions"


  // Admin shopify data fetch
  export const fetch_admin_shopify_data = () => api.get(
    "get_shopify_admin_data", { headers: { permission_key: CAN_MANAGE_MODULE_CONFIGURATION_SHOPIFY }}
  )

/**
 *
 *  PRODUCTS
 *
 */

    // Create new product
    export const create_new_product = payload => api.post("update_shopify_admin_data", {
      method: "post",
      endpoint: "/products.json",
      payload: JSON.stringify({ product: payload })
    }, { headers: { permission_key: CAN_MANAGE_MODULE_CONFIGURATION_SHOPIFY }})

    // Update existing product
    export const update_existing_product = payload => api.post("update_shopify_admin_data", {
      method: "put",
      endpoint: `/products/${payload.id}.json`,
      payload: JSON.stringify({ product: payload })
    }, { headers: { permission_key: CAN_MANAGE_MODULE_CONFIGURATION_SHOPIFY }})

    // Delete existing product
    export const delete_product = ({ id }) => api.post("update_shopify_admin_data", {
      method: "delete",
      endpoint: `/products/${id}.json`,
      payload: null
    }, { headers: { permission_key: CAN_MANAGE_MODULE_CONFIGURATION_SHOPIFY }})

  /**
   * Variants
   */

    // Create variant
    export const create_variant = (product_id, payload) => api.post("update_shopify_admin_data", {
      method: "post",
      endpoint: `/products/${product_id}/variants.json`,
      payload: JSON.stringify({ variant: payload })
    }, { headers: { permission_key: CAN_MANAGE_MODULE_CONFIGURATION_SHOPIFY }})

    // Delete variant
    export const delete_variant = (product_id, variant_id) => api.post("update_shopify_admin_data", {
      method: "delete",
      endpoint: `/products/${product_id}/variants/${variant_id}.json`,
      payload: null
    }, { headers: { permission_key: CAN_MANAGE_MODULE_CONFIGURATION_SHOPIFY }})

    // Update existing variant
    export const update_variant = payload => api.post("update_shopify_admin_data", {
      method: "put",
      endpoint: `/variants/${payload.id}.json`,
      payload: JSON.stringify({ variant: payload })
    }, { headers: { permission_key: CAN_MANAGE_MODULE_CONFIGURATION_SHOPIFY }})

    // Restock variant
    export const restock_shopify_variant = ({ product_id, variant_id, quantity }) => api.post(
      "restock_shopify_product",
      { product_id, variant_id, quantity },
      { headers: { permission_key: CAN_MANAGE_MODULE_CONFIGURATION_SHOPIFY }}
    )

    /**
     * Update variant quantity endpoints
     * @param {Number} inventory_item_id
     * @param {Number} location_id
     * @param {Number} inventory_item_id
     * @param {Number} available
     */
      export const get_variant_inventory_info = inventory_item_id => api.post(
        "get_shopify_variant_inventory_info",
        { inventory_item_id },
        { headers: { permission_key: CAN_MANAGE_MODULE_CONFIGURATION_SHOPIFY }}
      )

      export const update_variant_quantity = ({ location_id, inventory_item_id }, available) => api.post("update_shopify_admin_data", {
        method: "post",
        endpoint: `/inventory_levels/set.json`,
        payload: JSON.stringify({
          location_id,
          inventory_item_id,
          available
        })
      }, { headers: { permission_key: CAN_MANAGE_MODULE_CONFIGURATION_SHOPIFY }})

  /**
   * Collections
   */

    // Create new collection
    export const create_new_collection = payload => api.post("update_shopify_admin_data", {
      method: "post",
      endpoint: "/custom_collections.json",
      payload: JSON.stringify({ custom_collection: payload })
    }, { headers: { permission_key: CAN_MANAGE_MODULE_CONFIGURATION_SHOPIFY }})

    // Update existing collection
    export const update_existing_collection = payload => api.post("update_shopify_admin_data", {
      method: "put",
      endpoint: `/custom_collections/${payload.id}.json`,
      payload: JSON.stringify({ custom_collection: payload })
    }, { headers: { permission_key: CAN_MANAGE_MODULE_CONFIGURATION_SHOPIFY }})

    // Delete collection
    export const delete_collection = ({ id }) => api.post("update_shopify_admin_data", {
      method: "delete",
      endpoint: `/custom_collections/${id}.json`,
      payload: null
    }, { headers: { permission_key: CAN_MANAGE_MODULE_CONFIGURATION_SHOPIFY }})

  /**
   * Collects
   */

    // Delete collect (removes product from collection)
    export const delete_collect = ({ id }) => api.post("update_shopify_admin_data", {
      method: "delete",
      endpoint: `/collects/${id}.json`,
      payload: null
    }, { headers: { permission_key: CAN_MANAGE_MODULE_CONFIGURATION_SHOPIFY }})

  /**
   * Images
   */

    // Create image
    export const create_image = (product_id, payload) => api.post("update_shopify_admin_data", {
      method: "post",
      endpoint: `/products/${product_id}/images.json`,
      payload: JSON.stringify({ image: payload })
    }, { headers: { permission_key: CAN_MANAGE_MODULE_CONFIGURATION_SHOPIFY }})

    // Delte image
    export const delete_image = (product_id, image_id) => api.post("update_shopify_admin_data", {
      method: "delete",
      endpoint: `/products/${product_id}/images/${image_id}.json`,
      payload: null
    }, { headers: { permission_key: CAN_MANAGE_MODULE_CONFIGURATION_SHOPIFY }})

/**
 *
 * FINANCIALS
 *
 */

  // Get shopify financials
  export const get_shopify_financial_data = () => api.get("fetch_shopify_financials", { headers: { permission_key: CAN_MANAGE_MODULE_CONFIGURATION_SHOPIFY }})

  /**
   * Orders
   */

    // Update order
    export const update_shopify_order = (order_id, type, payload = null) => api.post("update_shopify_admin_data", {
      method: type === order_action_types.edit ? "put" : "post",
      endpoint: type === order_action_types.edit ? `/orders/${order_id}.json` : `/orders/${order_id}/${type}.json`,
      payload
    }, { headers: { permission_key: CAN_MANAGE_MODULE_CONFIGURATION_SHOPIFY }})

    // Creat/Update fulfillment
    export const shopify_fulfillments_API = (order_id, payload) => api.post("update_shopify_admin_data", {
      method: "post",
      endpoint: `/orders/${order_id}/fulfillments.json`,
      payload
    }, { headers: { permission_key: CAN_MANAGE_MODULE_CONFIGURATION_SHOPIFY }})

  /**
   * Discounts
   */

    // Create price rule
    export const create_shopify_price_rule = price_rule => api.post("update_shopify_admin_data", {
        method: "post",
        endpoint: `/price_rules.json`,
        payload: { price_rule }
      }, { headers: { permission_key: CAN_MANAGE_MODULE_FINANCE_DISCOUNTS } }
    )

    // Create discount
    export const create_shopify_discount_code = (price_rule_id, discount_code) => api.post("update_shopify_admin_data", {
        method: "post",
        endpoint: `/price_rules/${price_rule_id}/discount_codes.json`,
        payload: { discount_code }
      }, { headers: { permission_key: CAN_MANAGE_MODULE_FINANCE_DISCOUNTS } }
    )

    // Update price rule
    export const update_shopify_price_rule = (price_rule_id, price_rule) => api.post("update_shopify_admin_data", {
        method: "put",
        endpoint: `/price_rules/${price_rule_id}.json`,
        payload: { price_rule }
      }, { headers: { permission_key: CAN_MANAGE_MODULE_FINANCE_DISCOUNTS } }
    )

    // Update discount
    export const update_shopify_discount_code = (price_rule_id, discount_code) => api.post("update_shopify_admin_data", {
        method: "put",
        endpoint: `/price_rules/${price_rule_id}/discount_codes/${discount_code.id}.json`,
        payload: { discount_code }
      }, { headers: { permission_key: CAN_MANAGE_MODULE_FINANCE_DISCOUNTS } }
    )

    // Delete price rule
    export const delete_shopify_price_rule = (price_rule_id) => api.post("update_shopify_admin_data", {
        method: "delete",
        endpoint: `/price_rules/${price_rule_id}.json`,
        payload: null
      }, { headers: { permission_key: CAN_MANAGE_MODULE_FINANCE_DISCOUNTS } }
    )

    // Delete discount
    export const delete_shopify_discount_code = (price_rule_id, discount_id) => api.post("update_shopify_admin_data", {
        method: "delete",
        endpoint: `/price_rules/${price_rule_id}/discount_codes/${discount_id}.json`,
        payload: null
      }, { headers: { permission_key: CAN_MANAGE_MODULE_FINANCE_DISCOUNTS } }
    )
