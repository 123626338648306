// mutations
export const UPDATE_PRODUCTS = "UPDATE_PRODUCTS"
export const UPDATE_SELECTED_PRODUCT = "UPDATE_SELECTED_PRODUCT"
export const UPDATE_POPULAR_PRODUCTS = "UPDATE_POPULAR_PRODUCTS"
export const UPDATE_CATEGORIES = "UPDATE_CATEGORIES"

// actions
export const LOAD_PRODUCTS = "LOAD_PRODUCTS"

export const base_state = {
  products: {},
  categories: {},
  popular_products: [],
  selected_product: null
}
