// mutations
export const UPDATE_TITLE = "UPDATE_TITLE"
export const UPDATE_IMAGES = "UPDATE_IMAGES"
export const UPDATE_PRODUCT_AVAILABILITY = "UPDATE_PRODUCT_AVAILABILITY"
export const UPDATE_DESCRIPTION = "UPDATE_DESCRIPTION"
export const UPDATE_VARIANTS = "UPDATE_VARIANTS"
export const UPDATE_PARENT_COLLECTION = "UPDATE_PARENT_COLLECTION"
export const UPDATE_COLLECTION = "UPDATE_COLLECTION"
export const CREATE_COLLECTION = "CREATE_COLLECTION"
export const UPDATE_PRODUCT_COLLECTIONS = "UPDATE_PRODUCT_COLLECTIONS"
export const UPDATE_ERROR_LIST = "UPDATE_ERROR_LIST"
export const UPDATE_PRODUCT_IN_COLLECTION = "UPDATE_PRODUCT_IN_COLLECTION"
export const CREATE_PRODUCT = "CREATE_PRODUCT"
export const UPDATE_PRODUCT_ID_IN_COLLECTION = "UPDATE_PRODUCT_ID_IN_COLLECTION"
export const DELETE_OR_RESTORE_PRODUCT = "DELETE_OR_RESTORE_PRODUCT"
export const UPDATE_OPTIONS = "UPDATE_OPTIONS"
export const UPDATE_OPTION_VALUES = "UPDATE_OPTION_VALUES"
export const CLEAR_ERRORS_SECTION = "CLEAR_ERRORS_SECTION"
export const UPDATE_IMPORTED_PRODUCTS_DATA = "UPDATE_IMPORTED_PRODUCTS_DATA"
export const UPDATE_MARKETPLACE_AVAILABILITY = "UPDATE_MARKETPLACE_AVAILABILITY"
export const UPDATE_CROSS_SELLING_ABILITY = "UPDATE_CROSS_SELLING_ABILITY"
export const UPDATE_PRODUCT_CATEGORIZATION = "UPDATE_PRODUCT_CATEGORIZATION"
export const UPDATE_TAGS = "UPDATE_TAGS"
export const UPDATE_PRODUCT_CURRENCIES = "UPDATE_PRODUCT_CURRENCIES"
export const UPDATE_CONDUCTED_INVENTORY_CHECKS = "UPDATE_CONDUCTED_INVENTORY_CHECKS"
export const UPDATE_PURCHASE_CURRENCY = "UPDATE_PURCHASE_CURRENCY"
export const UPDATE_ERROR_LIST_TITLE = "UPDATE_ERROR_LIST_TITLE"
export const UPDATE_COLLECTION_IN_PRODUCT = "UPDATE_COLLECTION_IN_PRODUCT"
export const UPDATED_CONTENT_KEYS = "UPDATED_CONTENT_KEYS"
export const CLEAR_UPDATED_CONTENT_KEYS = "CLEAR_UPDATED_CONTENT_KEYS"
export const SAVE_UPDATED_CONTENT = "SAVE_UPDATED_CONTENT"
export const UPDATE_RESALE_PERCENTAGE = "UPDATE_RESALE_PERCENTAGE"
export const UPDATE_CROSS_CURRENCY = "UPDATE_CROSS_CURRENCY"
export const ADD_PRODUCTS = "ADD_PRODUCTS"
export const ADD_COLLECTIONS = "ADD_COLLECTIONS"

// actions
export const UPDATE_SHOPIFY_PRODUCTS = "UPDATE_SHOPIFY_PRODUCTS"
export const UPDATE_SHOPIFY_COLLECTIONS = "UPDATE_SHOPIFY_COLLECTIONS"
export const UPDATE_IMPORTED_PRODUCTS = "UPDATE_IMPORTED_PRODUCTS"
export const UPDATE_IMPORTED_COLLECTIONS = "UPDATE_IMPORTED_COLLECTIONS"
export const ASSIGN_DATA_TO_STORE = "ASSIGN_DATA_TO_STORE"
export const FETCH_IMPORTED_PRODUCTS = "FETCH_IMPORTED_PRODUCTS"
export const UPDATE_INVENTORY_CHECKS = "UPDATE_INVENTORY_CHECKS"
export const UPDATE_POPULAR_OR_HIGHLIGHTED_PRODUCTS = "UPDATE_POPULAR_OR_HIGHLIGHTED_PRODUCTS"

export const base_state = () => ({
  shopify_collections: [],
  imported_collections: [],
  error_list: [],
  images_to_delete: [],
  inventory_checks: [],
  updated_content_keys: [],
  error_list_title: "",
})
