import Vue from "vue"
import html_enity_encoder from "urlencode"
import {
  UPDATE_TITLE,
  UPDATE_RESALE_PERCENTAGE,
  UPDATE_IMAGES,
  UPDATE_PRODUCT_AVAILABILITY,
  UPDATE_DESCRIPTION,
  UPDATE_VARIANTS,
  UPDATE_PARENT_COLLECTION,
  UPDATE_COLLECTION,
  CREATE_COLLECTION,
  UPDATE_PRODUCT_COLLECTIONS,
  CREATE_PRODUCT,
  UPDATE_PRODUCT_ID_IN_COLLECTION,
  DELETE_OR_RESTORE_PRODUCT,
  UPDATE_OPTIONS,
  CLEAR_ERRORS_SECTION,
  UPDATE_ERROR_LIST,
  UPDATE_OPTION_VALUES,
  UPDATE_PRODUCT_IN_COLLECTION,
  UPDATE_PURCHASE_CURRENCY,
  UPDATE_IMPORTED_PRODUCTS_DATA,
  UPDATE_MARKETPLACE_AVAILABILITY,
  UPDATE_PRODUCT_CATEGORIZATION,
  UPDATE_TAGS,
  UPDATE_PRODUCT_CURRENCIES,
  UPDATE_CONDUCTED_INVENTORY_CHECKS,
  UPDATE_ERROR_LIST_TITLE,
  UPDATE_COLLECTION_IN_PRODUCT,
  UPDATED_CONTENT_KEYS,
  CLEAR_UPDATED_CONTENT_KEYS,
  UPDATE_CROSS_SELLING_ABILITY,
  UPDATE_CROSS_CURRENCY,
  ADD_PRODUCTS,
  ADD_COLLECTIONS
} from "./constants"
import { created, updated, deleted, removed, imported, PRODUCTS, ADMIN } from "../../../constants/others_constants"
import empty_product_object from "../../../constants/empty_objects/empty_product_object"
import get_mutation_product from "../../../methods/get_mutation_product"
import sanitize_string_for_object_key from "../../../methods/sanitize_string_for_object_key"
import un_bind from "../../../../Shared/methods/un_bind"
import { IMPORTED_DATA_UPDATED } from "../constants"
import get_correct_translation_value from "../../../../Shared/methods/get_correct_translation_value"

export default {
  [UPDATED_CONTENT_KEYS](state, key) {
    state.updated_content_keys = Array.from(new Set([
      ...state.updated_content_keys,
      key
    ]))
  },
  [CLEAR_UPDATED_CONTENT_KEYS](state) {
    state.updated_content_keys = []
  },
  [UPDATE_PRODUCT_CURRENCIES](state,  [language_code, currency_code]) {
    Vue.set(state, `${imported}_products`, (state[`${imported}_products`] || []).map(product => ({
      ...product,
      change_type: product.change_type || updated,
      translations: {
        ...(product.translations || {}),
        [language_code]: {
          ...((product.translations || {})[language_code] || {}),
          currency_code
        }
      }
    })))
    Vue.set(state, `${imported}_collections`, (state[`${imported}_collections`] || []).map(collection => ({
      ...collection,
      change_type: collection.change_type || updated,
      products: (collection.products || []).map(product => ({
        ...product,
        translations: {
          ...(product.translations || {}),
          [language_code]: {
            ...((product.translations || {})[language_code] || {}),
            currency_code
          }
        }
      }))
    })))

    this.commit(`${ADMIN}/${IMPORTED_DATA_UPDATED}`, true)
  },
  [ADD_COLLECTIONS](state, new_collections) {
    Vue.set(state, "imported_collections", new_collections)
  },
  [ADD_PRODUCTS](state, new_products) {
    Vue.set(state, "imported_products", new_products)
  },
  async [CREATE_PRODUCT](state, [
    products_type, collections_index, is_imported_product, all_available_languages, translations, cross_product
  ]) {
    let temp_collection
    const new_product = cross_product || empty_product_object(
      undefined,
      undefined,
      is_imported_product,
      all_available_languages,
      translations
    )

    if (collections_index > -1) { // If collection exists
      const collection = state[`${products_type}_collections`][collections_index]
      temp_collection = un_bind(collection)
      const change_type = collection.change_type === created ? created : updated

      Vue.set(collection, "products", [...(collection.products || []), new_product])
      Vue.set(collection, "change_type", change_type)

      delete temp_collection.products // To get rid of JSON stringify issues
    }

    Vue.set(state, [`${products_type}_products`], [
      ...state[`${products_type}_products`],
      ...(cross_product ?
        [{
          ...new_product,
          collections: [temp_collection],
          change_type: created
        }] :
        [empty_product_object(temp_collection, new_product.id, is_imported_product, all_available_languages, translations)]
      )
    ])
  },
  [DELETE_OR_RESTORE_PRODUCT](state, [products_type, product_id, restore_product]) {
    const product = get_mutation_product(state, products_type, product_id)
    const change_type = restore_product ? updated : deleted
    
    Vue.set(product, "change_type", change_type)

    this.commit(
      `${ADMIN}/${PRODUCTS}/${UPDATE_PRODUCT_IN_COLLECTION}`,
      [product, products_type, "change_type", change_type],
    )
  },
  [UPDATE_TAGS](state, [products_type, product_id, tags]) {
    const product = get_mutation_product(state, products_type, product_id)
    const change_type = product.change_type === created ? created : updated

    Vue.set(product, "tags", tags)
    Vue.set(product, "change_type", change_type)

    this.commit(
      `${ADMIN}/${PRODUCTS}/${UPDATE_PRODUCT_IN_COLLECTION}`,
      [product, products_type, "tags", tags],
    )
  },
  [UPDATE_RESALE_PERCENTAGE](state, [products_type, product_id, percentage]) {
    const product = get_mutation_product(state, products_type, product_id)
    const change_type = product.change_type === created ? created : updated

    Vue.set(product, "resale_percentage", percentage)
    Vue.set(product, "change_type", change_type)
  },
  [UPDATE_TITLE](state, [products_type, product_id, value, language]) {
    const product = get_mutation_product(state, products_type, product_id)
    const change_type = product.change_type === created ? created : updated
    const handle = sanitize_string_for_object_key(html_enity_encoder(value.toLowerCase()))

    Vue.set(product, "change_type", change_type)
    Vue.set(product, "handle", product.id);

    if (language) {
      if (!product.translations) Vue.set(product, "translations", {})
      if (!product.translations[language]) Vue.set(product.translations, language, {})
      Vue.set(product.translations[language], "title", value);
    } else Vue.set(product, "title", value);

    [
      ["title", value], ["handle", handle]
    ].forEach(([key, val]) => this.commit(
      `${ADMIN}/${PRODUCTS}/${UPDATE_PRODUCT_IN_COLLECTION}`,
      [product, products_type, key, val, language],
    ))
  },
  [UPDATE_IMAGES](state, [products_type, product_id, images]) {
    const product = get_mutation_product(state, products_type, product_id)
    const change_type = product.change_type === created ? created : updated
    
    Vue.set(product, "images", images)
    this.commit(
      `${ADMIN}/${PRODUCTS}/${UPDATE_PRODUCT_IN_COLLECTION}`,
      [product, products_type, "images", images],
    )
    Vue.set(product, "change_type", change_type)
  },
  [UPDATE_PRODUCT_COLLECTIONS](state, [product_type, product_id, collection, adding = true]) {
    const correct_products = state[`${product_type}_products`]
    const product_index = correct_products.findIndex(({ id }) => id === product_id)
    const product = correct_products[product_index]

    if (product) {
      Vue.set(product, "change_type", updated)
      Vue.set(
        product,
        "collections",
        adding ?
          // Filter out collection if it is already in collections and remove its collection_change_type since its newly added
          [
            ...(product?.collections?.filter(({ id }) => id !== collection.id) || []),
            { ...collection, collection_change_type: undefined, products: undefined, collects: undefined }
          ] :
          (product?.collections || []).map(coll => {
            if (coll.id === collection.id) coll.collection_change_type = removed
            return coll
          })
      )
    }
  },
  [UPDATE_PRODUCT_AVAILABILITY](state, [
    products_type,
    product_id,
    published_at,
    published,
    published_scope
  ]) {
    const product = get_mutation_product(state, products_type, product_id)
    const change_type = product.change_type === created ? created : updated

    Vue.set(product, "change_type", change_type)
    Vue.set(product, "published_at", published_at)
    Vue.set(product, "published", published)
    Vue.set(product, "published_scope", published_scope);

    [
      ["published_at", published_at], ["published", published], ["published_scope", published_scope]
    ].forEach(([key, val]) => this.commit(
      `${ADMIN}/${PRODUCTS}/${UPDATE_PRODUCT_IN_COLLECTION}`,
      [product, products_type, key, val],
    ))
  },
  [UPDATE_DESCRIPTION](state, [products_type, product_id, value, language]) {
    const product = get_mutation_product(state, products_type, product_id)
    const change_type = product.change_type === created ? created : updated
    
    Vue.set(product, "change_type", change_type)
    if (language) {
      if (!product.translations) Vue.set(product, "translations", {})
      if (!product.translations[language]) Vue.set(product.translations, language, {})
      Vue.set(product.translations[language], "body_html", value)
    } else Vue.set(product, "body_html", value)

    this.commit(
      `${ADMIN}/${PRODUCTS}/${UPDATE_PRODUCT_IN_COLLECTION}`,
      [product, products_type, "body_html", value, language],
    )
  },
  [UPDATE_MARKETPLACE_AVAILABILITY](state, [products_type, product_id, value]) {
    const product = get_mutation_product(state, products_type, product_id)
    const change_type = product.change_type === created ? created : updated
    
    Vue.set(product, "change_type", change_type)
    Vue.set(product, "allow_on_marketplace", value)

    this.commit(
      `${ADMIN}/${PRODUCTS}/${UPDATE_PRODUCT_IN_COLLECTION}`,
      [product, products_type, "allow_on_marketplace", value],
    )
  },
  [UPDATE_CROSS_SELLING_ABILITY](state, [products_type, product_id, value]) {
    const product = get_mutation_product(state, products_type, product_id)
    const change_type = product.change_type === created ? created : updated
    
    Vue.set(product, "change_type", change_type)
    Vue.set(product, "enable_cross_selling", value)

    this.commit(
      `${ADMIN}/${PRODUCTS}/${UPDATE_PRODUCT_IN_COLLECTION}`,
      [product, products_type, "enable_cross_selling", value],
    )
  },
  [UPDATE_PRODUCT_CATEGORIZATION](state, [products_type, product_id, value]) {
    const product = get_mutation_product(state, products_type, product_id)
    const change_type = product.change_type === created ? created : updated
    
    Vue.set(product, "change_type", change_type)
    Vue.set(product, "categories", value)

    this.commit(
      `${ADMIN}/${PRODUCTS}/${UPDATE_PRODUCT_IN_COLLECTION}`,
      [product, products_type, "categories", value],
    )
  },
  [UPDATE_VARIANTS](state, [products_type, product_id, value, language]) {
    const product = get_mutation_product(state, products_type, product_id)
    const change_type = product.change_type === created ? created : updated

    // Add title for imported, because Shopify adds it automatically
    if (products_type === imported) value = value.map(variant => ({
      ...variant,
      translations: Object.entries(variant.translations).reduce((tot, [lang, value = {}]) => {
        return {
          ...tot,
          [lang]: {
            ...value,
            title: [2,3].reduce((tit, num) => {
              const option_name = get_correct_translation_value(variant, `option${num}`, [lang])

              return (option_name || option_name === "") ? `${tit} / ${option_name}` : tit
            }, get_correct_translation_value(variant, "option1", [lang]))
          }
        }
      }, {})
    }))

    Vue.set(product, "change_type", change_type)
    Vue.set(product, "variants", value)

    this.commit(
      `${ADMIN}/${PRODUCTS}/${UPDATE_PRODUCT_IN_COLLECTION}`,
      [product, products_type, "variants", value, language],
    )
  },
  [UPDATE_OPTIONS](state, [products_type, product_id, options, language]) {
    const product = get_mutation_product(state, products_type, product_id)
    const change_type = product.change_type === created ? created : updated
    
    Vue.set(product, "change_type", change_type)
    Vue.set(product, "options", options)

    this.commit(
      `${ADMIN}/${PRODUCTS}/${UPDATE_PRODUCT_IN_COLLECTION}`,
      [product, products_type, "options", options, language],
    )
  },
  [UPDATE_OPTION_VALUES](state, [
    products_type,
    product_id,
    option_ind,
    value,
    index_of_value,
    language,
    to_be_deleted
  ]) {
    const product = get_mutation_product(state, products_type, product_id)
    const change_type = product.change_type === created ? created : updated
    const updated_options = un_bind(product.options)
    const option = updated_options[option_ind]

    if (language) {
      if (!option.translations) Vue.set(option, "translations", {})
      if (!option.translations[language]) Vue.set(option.translations, language, { values: [] })
      if (!option.translations[language].values) Vue.set(option.translations[language], "values", [])

      Vue.set(option.translations[language].values, index_of_value, value)
    } else option.values[index_of_value] = to_be_deleted ? null : value

    Vue.set(product, "change_type", change_type)
    Vue.set(product, "options", updated_options)

    this.commit(
      `${ADMIN}/${PRODUCTS}/${UPDATE_PRODUCT_IN_COLLECTION}`,
      [product, products_type, "options", updated_options, language],
    )
  },
  [CREATE_COLLECTION](state, [products_type, value]) {
    const collections = state[`${products_type}_collections`]

    Vue.set(state, `${products_type}_collections`, [...collections, value])
  },
  [UPDATE_COLLECTION](state, [products_type, collection_index, key, value]) {
    const collection = state[`${products_type}_collections`][collection_index]

    Vue.set(collection, key, value)

    this.commit(
      `${ADMIN}/${PRODUCTS}/${UPDATE_COLLECTION_IN_PRODUCT}`,
      [collection, products_type],
    )
  },
  [UPDATE_PARENT_COLLECTION](state, [products_type, collection_index, value]) {
    const collection = state[`${products_type}_collections`][collection_index]
    const change_type = collection.change_type || updated

    Vue.set(collection, "change_type", change_type)
    Vue.set(collection, "body_html", value)

    this.commit(
      `${ADMIN}/${PRODUCTS}/${UPDATE_COLLECTION_IN_PRODUCT}`,
      [collection, products_type],
    )
  },
  [UPDATE_COLLECTION_IN_PRODUCT](state, [{ products: _, change_type: __, ...collection }, products_type]) {
    const products = state[`${products_type}_products`]
  
    products.forEach(({ collections = [] }, index) => {
      const collection_index = collections.findIndex(({ id: collection_id }) => collection_id === collection.id)

      // Check that product has collection and that the collection isnt empty
      if (collection_index > -1 && collection.id) {
        Vue.set(
          products[index].collections,
          collection_index,
          { ...products[index].collections[collection_index], ...collection }
        )
      }
    })
  },
  [UPDATE_PURCHASE_CURRENCY](state, [products_type, product_id, currency]) {
    const product = get_mutation_product(state, products_type, product_id)
    const change_type = product.change_type === created ? created : updated
    
    Vue.set(product, "change_type", change_type)
    Vue.set(product, "purchase_currency", currency)

    this.commit(
      `${ADMIN}/${PRODUCTS}/${UPDATE_PRODUCT_IN_COLLECTION}`,
      [product, products_type, "purchase_currency", currency],
    )
  },
  [UPDATE_CROSS_CURRENCY](state, [products_type, product_id, currency]) {
    const product = get_mutation_product(state, products_type, product_id)
    const change_type = product.change_type === created ? created : updated
    
    Vue.set(product, "change_type", change_type)
    Vue.set(product, "cross_base_currency", currency)

    this.commit(
      `${ADMIN}/${PRODUCTS}/${UPDATE_PRODUCT_IN_COLLECTION}`,
      [product, products_type, "cross_base_currency", currency],
    )
  },
  [UPDATE_PRODUCT_ID_IN_COLLECTION] (state, [products_type, product, collection_id]){
    const collection_index = state[`${products_type}_collections`].findIndex(({ id }) => id === collection_id)
    const product_index = state[`${products_type}_collections`][collection_index].products.findIndex(
      ({ id: product_id }) => product.old_id === product_id
    )

    Vue.set(state[`${products_type}_collections`][collection_index].products, product_index, product)
  },
  [CLEAR_ERRORS_SECTION](state, product_id) {
    state.error_list = state.error_list.filter(({ id }) => id !== product_id)
  },
  [UPDATE_ERROR_LIST](state, value) {
    state.error_list = value
  },
  [UPDATE_ERROR_LIST_TITLE](state, value) {
    state.error_list_title = value
  },
  [UPDATE_PRODUCT_IN_COLLECTION](state, [product, products_type, key, value, language]) {
    const collections = state[`${products_type}_collections`]
  
    product.collections = product.collections || []
    product.collections.forEach(({ id: collection_id, collection_change_type }) => {
      const corret_collection_index = collections.findIndex(({ id }) => id === collection_id)

      // Check if collection exists and product is not being removed from it
      if (corret_collection_index > -1 && collection_change_type !== removed) {
        const product_index = collections[corret_collection_index].products.findIndex(({ id }) => id === product.id)

        if (language) {
          Vue.set(collections[corret_collection_index].products[product_index], "translations", product.translations)
          Vue.set(collections[corret_collection_index].products[product_index], "variants", product.variants.map(
            // eslint-disable-next-line no-unused-vars
            ({ price_history, ...rest_values }) => rest_values) // Dont save price_history
          )
          Vue.set(collections[corret_collection_index].products[product_index], "options", product.options)
        } else Vue.set(collections[corret_collection_index].products[product_index], key, value)

        Vue.set(collections[corret_collection_index], "change_type", updated)
      }
    })
  },
  [UPDATE_IMPORTED_PRODUCTS_DATA](state, data) {
    Object.entries(data).forEach(([key, value]) => Vue.set(state, key, value))
  },
  [UPDATE_CONDUCTED_INVENTORY_CHECKS](state, checks) {
    state.inventory_checks = checks
  },
}
