export default async (current_fb_user, change_account) => {
  if (
    !change_account && 
    firebase.auth().currentUser &&
    current_fb_user &&
    firebase.auth().currentUser.providerData[0].uid === current_fb_user.id
  ) return current_fb_user

  const fb_permissions_scope = `ads_management,email,public_profile,groups_access_member_info,instagram_basic,instagram_content_publish,
  instagram_manage_insights,pages_manage_ads,pages_manage_posts,pages_show_list,pages_read_engagement,publish_to_groups,read_insights,pages_read_user_content,
  publish_video`
  const provider = new firebase.auth.FacebookAuthProvider();

  if (change_account) provider.setCustomParameters({ auth_type: "reauthenticate" });

  fb_permissions_scope.split(",").forEach(permission => provider.addScope(permission))

  const { credential, additionalUserInfo } = await firebase.auth().signInWithPopup(provider)

  return {
    name: additionalUserInfo.profile.name,
    id: additionalUserInfo.profile.id,
    accessToken: credential.accessToken,
    permissions: additionalUserInfo.profile.granted_scopes
  }
}
