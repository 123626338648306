import { get_marketplace_products, get_popular_marketplace_products } from "../../constants/endpoints"
import { LOAD_PRODUCTS, UPDATE_PRODUCTS, UPDATE_SELECTED_PRODUCT, UPDATE_CATEGORIES } from "./constants"

export default {
  async[LOAD_PRODUCTS]({ commit, state }, [category_ids_or_product_route, load_popular]) {
    try {
      if (typeof category_ids_or_product_route === "string" && state.products[category_ids_or_product_route]) commit(
        UPDATE_SELECTED_PRODUCT,
        state.products[category_ids_or_product_route]
      )
      else if (load_popular) {
        const { data } = await get_popular_marketplace_products()

        commit(UPDATE_PRODUCTS, data.reduce((total, product) => ({
          ...total,
          [`${product.categories.join("_")}_${product.id}`]: product
        }), {}))
      }
      else {
        const { data: { products, categories } } = await get_marketplace_products(category_ids_or_product_route)

        if (typeof category_ids_or_product_route === "string") commit(UPDATE_SELECTED_PRODUCT, products)
        else {
          commit(UPDATE_PRODUCTS, products.reduce((total, product) => ({
            ...total,
            [`${product.categories.join("_")}_${product.id}`]: product
          }), {}))
        }

        commit(UPDATE_CATEGORIES, { ...state.categories, ...categories })
      }
    } catch (error) {
      console.log(error)
    }
  }
}
