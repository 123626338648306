import { default_image_src } from "../../../Shared/constants/other"

export default (
  category = null, sub_category = null, img, mobile_image, direct_route, button_text = "",
  description = "Description", title = "Title", image_object
) => ({
  category,
  sub_category,
  button_text,
  img: img || default_image_src,
  description,
  title,
  mobile_image: mobile_image || default_image_src,
  ...(direct_route && { direct_route }),
  image_object
})
