import { api } from "../../../Shared/methods/ajax/axios_instance"
import { CAN_MANAGE_MODULE_FINANCE_ORDERS, CAN_MANAGE_MODULE_USER_BILLING } from "../permissions"

/**
 * Domain email notification.
 * Used for notifying Ulsemo team about new domain needing to be added
 * and instruction on DNS connection of domain sent to user
 */

  export const user_domain_email_notification = ({
    domain, user_id, email, name, project_name
  }) => api.post("user_domain_email", {
    domain, user_id, email, name, project_name
  })

/**
 * Endpoints for handling Shippo shipping
 */
  export const purchase_shipping_label = ({ rate_id, shipment_id }) => api.post(
    "purchase_shipping_label",
    { rate_id, shipment_id },
    { headers: { permission_key: CAN_MANAGE_MODULE_FINANCE_ORDERS } }
  )

/**
 * Endpoints for handling Zasilkovna packets
 */
  export const create_zasilkovna_packets = ({ api_password, packets }) => api.post(
    "new_zasilkovna_packet",
    { api_password, packets },
    { headers: { permission_key: CAN_MANAGE_MODULE_FINANCE_ORDERS } }
  )

/**
 * Endpoints for handling payments within Ulsemo admin
 */
  export const handle_admin_payment = ({ source, amount, payment_type, other_info }) => api.post(
    "handle_admin_payment",
    { source, amount, payment_type, other_info },
    { headers: { permission_key: CAN_MANAGE_MODULE_FINANCE_ORDERS } }
  )

  export const calculate_admin_payment = ({ amount }) => api.post(
    "calculate_admin_payment",
    { amount },
    { headers: { permission_key: CAN_MANAGE_MODULE_FINANCE_ORDERS } }
  )

  // Ulsemo services subscription creation 
  export const create_subscription_session = ({ redirect_url, trial_end }) => api.post(
    "create_subscription_session",
    { redirect_url, trial_end },
    { headers: { permission_key: CAN_MANAGE_MODULE_USER_BILLING } }
  )

  // Ulsemo services billing management
  export const create_billing_session = ({ redirect_url }) => api.post(
    "create_billing_session",
    { redirect_url },
    { headers: { permission_key: CAN_MANAGE_MODULE_USER_BILLING } }
  )

/**
 * Endpoint for retrieving product categories
 */
  export const get_product_categories = ({ category_ids, get_parents }) => api.post(
    "get_product_categories",
    { category_ids, get_parents },
  )

/**
 * Currency exchange rates
 */
  export const get_rates_for_currency = ({ currency }) => api.post(
    "get_rates_for_currency",
    { currency },
  )

/**
 * Email connection
 */
  export const test_email_connection = ({ email, project_id, language }) => api.post(
    "test_email_connection",
    { email, project_id, language },
  )

/**
 * Search through categories by name
 */
  export const search_categories = ({ query = "" }) => api.post(
    "search_categories",
    { query },
  )

/**
 * Remove a store notification
 */
 export const remove_store_notification = (notification_id) => api.post(
  "remove_store_notification",
  { notification_id },
)


//
// Cross products endpoints
//

/**
 * Get products by their categories
 */
  export const get_cross_products_by_categories = ({ category = "", page = 0, number_of_results = 20 }) => api.post(
    "get_cross_products",
    { category, page, number_of_results },
  )

/**
 * Search cross products by name and attributes
 */
  export const search_cross_products = ({ query = "", page = 0, number_of_results = 20  }) => api.post(
    "search_cross_products",
    { query, page, number_of_results },
  )

/**
 * Toggle assignment to a store
 */
  export const toggle_product_to_store_assignment = ({ product_id = "", assigning = true  }) => api.post(
    "toggle_product_to_store_assignment",
    { product_id, assigning },
  )

/**
 * Check that user ass Paypal Payouts enabled
 */
  export const check_paypal_payout_connection = ({ client_id, client_secret  }) => api.post(
    "check_paypal_payout_connection",
    { client_id, client_secret },
  )
