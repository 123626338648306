import update_shopify_checkout_line_items from "./update_shopify_checkout_line_items"
import adjust_basket_data_to_not_include_duplicates from "./adjust_basket_data_to_not_include_duplicates"
import fetch_all_shopify_products_in_basket_and_get_current_availability from "./fetch_all_shopify_products_in_basket_and_get_current_availability"
import store from "../../../store"
import { CLIENT_STORE } from "../constants/other"

const updated_basket_data = (basket_data, updated_products) => basket_data.filter(({ id, variant_id }) => {
  const found_product = updated_products.find(product => product.id === id)

  return found_product && found_product.variants.some(variant => variant.id === variant_id)
})

export default async (data, check_if_basket_items_available) => {
  let shopify_basket_data;

  // is only a basket items refresh
  if(check_if_basket_items_available) {
    const updated_products_from_shopify = await fetch_all_shopify_products_in_basket_and_get_current_availability(
      data,
      store.state[CLIENT_STORE].shopify_instance
    )

    shopify_basket_data = updated_basket_data(data, updated_products_from_shopify)
  }
  // is data array of items or single item
  else if(data.length) {
    const adjusted_basket_data_without_duplicates = adjust_basket_data_to_not_include_duplicates(
      data,
      true
    )
    const updated_products_from_shopify = await fetch_all_shopify_products_in_basket_and_get_current_availability(
      adjusted_basket_data_without_duplicates,
      store.state[CLIENT_STORE].shopify_instance
    )

    shopify_basket_data = await update_shopify_checkout_line_items(
      updated_basket_data(data, updated_products_from_shopify),
      "add"
    )
  }
  // is data a single item
  else {
    const determine_action = store.state[CLIENT_STORE].basket_items.find(item_in_basket =>
      item_in_basket.variant_id === data.variant_id &&
      store.state[CLIENT_STORE].shopify_checkout.lineItems.length > 0
    ) ? "update": "add"

    shopify_basket_data = await update_shopify_checkout_line_items(data, determine_action)

    return shopify_basket_data
  }

  return updated_basket_data(shopify_basket_data, store.state[CLIENT_STORE].products)
}