import { SAVE_CONTENT, ADD_NEW_MENU_ITEM, EDITED_PAGE_TRANSLATIONS, FETCH_CONTENT_DATA, UPDATE_CONTENT_DATA, UPDATE_SPECIAL_MESSAGE_BANNER, UPDATE_CONTENT_DATA_BY_KEY } from "./constants"
import { update_content, fetch_website_content, update_website_configuration } from "../../../constants/endpoints/firebase"
import { ADMIN, CONFIGURATION, CONFIGURATION_STORE, CONTENT } from "../../../constants/others_constants"
import { UPDATE_LOADER, CONTENT_DATA_UPDATED, CONFIGURATION_DATA_UPDATED, UPDATE_IMAGES_FOR_DELETE, CLEAR_IMAGES_FOR_DELETE, SET_LOADING_MESSAGE} from "../constants"
import { ADD_PAGE_TYPE_TRANSLATION } from "../configuration/constants"
import { languages, product, collection } from "../../../../Shared/constants/other"
import default_page_type_translations from "../../../constants/empty_objects/default_page_type_translations"
import remove_storage_file from "../../../methods/remove_storage_file"
import { page_types } from "../../../../../data/other_constants"
import upload_image from "../../../methods/upload_image"
import { translate } from "../../../../Shared/translator"

export default {
  async [SAVE_CONTENT]({ commit, state , rootState }, [content, should_stop_loading = true, edited_keys]) {
    /**
     * When adding a direct link, a new entry in single/couple product pages
     * gets added and therefor you need to save that data as well
     */
    if (state.page_translations_edited) {
      await Promise.all(
        Object
          .keys(page_types)
          .map(type => update_website_configuration(type, rootState[ADMIN][CONFIGURATION][type] || {}))
      )
    }

    commit(`${ADMIN}/${UPDATE_LOADER}`, true, { root: true })
    commit(`${ADMIN}/${SET_LOADING_MESSAGE}`, await translate("loading_messages.uploading_content_images"), { root: true })

    await Promise.all(
      Object
        .entries(rootState[ADMIN].user_edited_content)
        .reduce((total, [key, val]) => {
          if (!val) return

          if (key === "banners") {
            const special_message_banner_images = state?.banners?.special_message_banner?.map(
              async (special_banner, banner_index) => {
                if (!special_banner.image) return

                const {
                  desktop,
                  mobile
                } = await upload_image(
                  special_banner.image,
                  `special_message_banner_${banner_index}_${new Date().getTime()}`,
                  false,
                  true,
                  true,
                  false
                )

                commit(
                  UPDATE_SPECIAL_MESSAGE_BANNER,
                  [banner_index, {
                    ...special_banner,
                    image: undefined,
                    src: desktop,
                    mobile_image: mobile
                  }]
                )
              }
            )

            return [...total, ...(special_message_banner_images || [])]
          }

          return total
        }, [])
    )

    commit(`${ADMIN}/${SET_LOADING_MESSAGE}`, await translate("loading_messages.uploading_content"), { root: true })
    const update_content_promise = async (key) => {
      try {
        const { data } = await update_content(key, content[key])
  
        commit(UPDATE_CONTENT_DATA_BY_KEY, [key, data])
      } catch (error) {
        return false
      }

      return true
    }

    const content_update_promises = edited_keys ?
      edited_keys.map(key => update_content_promise(key, content[key])) :
      Object
        .entries(rootState[ADMIN].user_edited_content)
        .map(([key, value]) => value && update_content_promise(key, content[key]))

    const responses = await Promise.all(content_update_promises)

    // Delete all removed images
    state.images_to_delete.forEach(async image_url => {
      await remove_storage_file(image_url)
      commit(
        `${ADMIN}/${UPDATE_IMAGES_FOR_DELETE}`,
        { images: [image_url], store_type: CONTENT },
        { root: true }
      )
    });

    if (responses.every(state => state)) commit(`${ADMIN}/${CONTENT_DATA_UPDATED}`, false, { root: true })
    if (should_stop_loading) commit(`${ADMIN}/${UPDATE_LOADER}`, false, { root: true })

    commit(EDITED_PAGE_TRANSLATIONS, false)
    commit(`${ADMIN}/${SET_LOADING_MESSAGE}`, "", { root: true })
  },
  [ADD_NEW_MENU_ITEM]({ commit, rootState }, [name, category, sub_items, is_parent_category, entity_id]) {
    // Add translation for couple or single product page
    if ([product, collection].some(type => category.includes(`?${type}=`))) {
      const is_product = category.includes(product)
      const page_type = `${is_product ? "single" : "couple"}_product${is_product ? "" : "s"}_homepages`
      const entity_handle = category.replace(`?${is_product ? product : collection}=`, "")

      if (!rootState[ADMIN][CONFIGURATION][page_type][entity_handle]) {
        commit(
          `${CONFIGURATION_STORE}/${ADD_PAGE_TYPE_TRANSLATION}`,
          [page_type, entity_handle, default_page_type_translations[page_type], languages[0]],
          { root: true }
        )
        commit(`${ADMIN}/${CONFIGURATION_DATA_UPDATED}`, true, { root: true })
        commit(EDITED_PAGE_TRANSLATIONS, true)
      }
    }

    commit(ADD_NEW_MENU_ITEM, [
      name, category, sub_items, is_parent_category, entity_id,
      Object.keys(rootState[ADMIN][CONFIGURATION].translations)
    ])
  },
  async [FETCH_CONTENT_DATA]({ commit, rootState }) {
    const { data } = await fetch_website_content()

    commit(`${ADMIN}/${CLEAR_IMAGES_FOR_DELETE}`, { store_type: CONTENT }, { root: true })
    commit(
      UPDATE_CONTENT_DATA,
      // Checks if Content data already loaded, if not, add possibly updated data by Products section
      rootState[ADMIN][CONTENT].footer_content ? data : {
        ...data,
        popular_products: rootState[ADMIN][CONTENT].popular_products,
        highlighted_products: rootState[ADMIN][CONTENT].highlighted_products,
      }
    )
  },
}
