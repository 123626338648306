import Vue from "vue"
import {
  UPDATE_USER_AUTH_DATA, UPDATE_FB_PAGES, UPDATE_CONNECTED_ENTITIES, UPDATE_FB_GROUPS,
  UPDATE_FACEBOOK_PAGE_POSTS, UPDATE_FACEBOOK_GROUP_POSTS, UPDATE_CONNECTED_ENTITY,
  UPDATE_IG_ACCOUNTS, UPDATE_INSTAGRAM_POSTS, UPDATE_FB_AD_ACCOUNTS, UPDATE_AD_CAMPAINGS,
  UPDATE_AD_AUDIENCES, UPDATE_ADS, UPDATE_AD_INSIGHTS, UPDATE_FB_PIXELS, UPDATE_FB_PIXEL_STATS,
  UPDATE_GA_REPORT, UPDATE_GA_VIEWS
} from "./constants";

export default {
  [UPDATE_USER_AUTH_DATA](state, [data, provider]) {
    state[`${provider}_user_auth_data`] = data
  },
  [UPDATE_FB_PAGES](state, pages) {
    state.fb_pages = pages
  },
  [UPDATE_FB_GROUPS](state, groups) {
    state.fb_groups = groups
  },
  [UPDATE_FACEBOOK_PAGE_POSTS](state, [id, posts]) {
    Vue.set(state.fb_pages_posts, id, posts)
  },
  [UPDATE_FACEBOOK_GROUP_POSTS](state, [id, posts]) {
    Vue.set(state.fb_groups_posts, id, posts)
  },
  [UPDATE_CONNECTED_ENTITIES](state, [key, data]) {
    state[`connected_${key}`] = data
  },
  [UPDATE_CONNECTED_ENTITY](state, [entity, id, key, data]) {
    const index_of_entity = state[`connected_${entity}`].findIndex(({ id: entity_id }) => id === entity_id)

    Vue.set(state[`connected_${entity}`][index_of_entity], key, data)
  },
  [UPDATE_IG_ACCOUNTS](state, accounts) {
    state.ig_accounts = accounts
  },
  [UPDATE_INSTAGRAM_POSTS](state, [id, posts]) {
    Vue.set(state.ig_accounts_posts, id, posts)
  },
  [UPDATE_FB_AD_ACCOUNTS](state, accounts) {
    state.fb_ad_accounts = accounts
  },
  [UPDATE_AD_CAMPAINGS](state, [campaigns, source]) {
    state[`${source}_ad_campaigns`] = campaigns
  },
  [UPDATE_AD_AUDIENCES](state, [campaigns, source]) {
    state[`${source}_ad_audiences`] = campaigns
  },
  [UPDATE_ADS](state, [ad_account_id, ads]) {
    Vue.set(state.ads, ad_account_id, ads)
  },
  [UPDATE_AD_INSIGHTS](state, [source, ad_insights]) {
    state[`${source}_ad_insights`] = ad_insights
  },
  [UPDATE_FB_PIXELS](state, pixels) {
    state.fb_pixels = pixels
  },
  [UPDATE_FB_PIXEL_STATS](state, stats) {
    state.fb_pixel_stats = stats
  },
  [UPDATE_GA_REPORT](state, [report, report_type]) {
    Vue.set(state.ga_reports, report_type, report)
  },
  [UPDATE_GA_VIEWS](state, views) {
    state.google_analytics_views = views
  },
}
