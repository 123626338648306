import { admin_domain } from "../other_constants";

const Marketplace = () => import("../../applications/Marketplace/components/App.vue")
const Homepage = () => import("../../applications/Marketplace/components/routes/Homepage.vue")
const Products = () => import("../../applications/Marketplace/components/routes/Products.vue")
const Categories = () => import("../../applications/Marketplace/components/routes/Categories.vue")
const Basket = () => import("../../applications/Shared/components/Basket.vue")
const Order = () => import("../../applications/Shared/components/Order.vue")
const Login = () => import("../../applications/Shared/components/Login.vue")
const Profile = () => import("../../applications/Shared/components/User_profile.vue")

export default [
  {
    path: "/",
    name: "Marketplace",
    component: Marketplace,
    children: [
      {
        path: "/external",
        name: "Marketplace",
        component: Marketplace,
        children: [
          {
            path: "paypal_buyer_protection",
            beforeEnter() {
              window.open("https://www.paypal.com/us/webapps/mpp/paypal-safety-and-security", "_blank");
            },
          },
          {
            path: "new_seller_registration",
            beforeEnter() {
              window.open(`https://${admin_domain}/new_user`, "_blank");
            },
          },
          {
            path: "log_into_admin",
            beforeEnter() {
              window.open(`https://${admin_domain}/`, "_blank");
            },
          }
        ]
      }, {
        path: '/',
        components: {
          marketplace_content: Homepage
        }
      },
      {
        path: "/products/*",
        name: "Products",
        components: {
          marketplace_content: Products
        }
      },
      {
        path: "/basket",
        name: "Basket",
        components: {
          marketplace_content: Basket
        }
      },
      {
        path: "/order/:order_id/:store_id",
        name: "Order",
        components: {
          marketplace_content: Order
        }
      },
      {
        path: "/categories",
        name: "Categories",
        components: {
          marketplace_content: Categories
        }
      },
      {
        path: "/categories/*",
        name: "Categories",
        components: {
          marketplace_content: Categories
        }
      },
      {
        path: "/login",
        name: "Login",
        components: {
          marketplace_content: Login
        }
      },
      {
        path: "/profile",
        name: "Profile",
        components: {
          marketplace_content: Profile
        }
      }
    ]
  },
]
