// mutations
export const ADD_NEW_MENU_ITEM = "ADD_NEW_MENU_ITEM"
export const ADD_NEW_MENU_SUB_ITEM = "ADD_NEW_MENU_SUB_ITEM"
export const UPDATE_MENU_ITEM_NAME = "UPDATE_MENU_ITEM_NAME"
export const UPDATE_MENU_ITEMS_CASE = "UPDATE_MENU_ITEMS_CASE"
export const DELETE_OR_RESTORE_MENU_ITEM = "DELETE_OR_RESTORE_MENU_ITEM"
export const UPDATE_FOOTER_CONTENT = "UPDATE_FOOTER_CONTENT"
export const UPDATE_COLLECTION_BANNER = "UPDATE_COLLECTION_BANNER"
export const DELETE_OR_RESTORE_COLLECTION_BANNER = "DELETE_OR_RESTORE_COLLECTION_BANNER"
export const ADD_COLLECTION_BANNER = "ADD_COLLECTION_BANNER"
export const CREATE_SPECIAL_MESSAGE_BANNER = "CREATE_SPECIAL_MESSAGE_BANNER"
export const DELETE_SPECIAL_MESSAGE_BANNER = "DELETE_SPECIAL_MESSAGE_BANNER"
export const UPDATE_STORY_BANNER_IMAGES = "UPDATE_STORY_BANNER_IMAGES"
export const UPDATE_STORY_BANNER_MESSAGE = "UPDATE_STORY_BANNER_MESSAGE"
export const DELETE_STORY_BANNER = "DELETE_STORY_BANNER"
export const UPDATE_STORY_BANNER_TITLE = "UPDATE_STORY_BANNER_TITLE"
export const UPDATE_STORY_BANNER_BUTTONS = "UPDATE_STORY_BANNER_BUTTONS"
export const UPDATE_POPULAR_PRODUCTS = "UPDATE_POPULAR_PRODUCTS"
export const UPDATE_HIGHLIGHTED_PRODUCTS = "UPDATE_HIGHLIGHTED_PRODUCTS"
export const UPDATE_IMPORTANT_MESSAGE_BAR = "UPDATE_IMPORTANT_MESSAGE_BAR"
export const EDITED_PAGE_TRANSLATIONS = "EDITED_PAGE_TRANSLATIONS"
export const UPDATE_SPECIAL_MESSAGE_BANNER_LASER = "UPDATE_SPECIAL_MESSAGE_BANNER_LASER"
export const UPDATE_SPECIAL_MESSAGE_BANNER_USE_ON_PAGES = "UPDATE_SPECIAL_MESSAGE_BANNER_USE_ON_PAGES"
export const UPDATE_ABOUT_PAGE_LASER_USAGE = "UPDATE_ABOUT_PAGE_LASER_USAGE"
export const UPDATE_ABOUT_PAGE_ABILITY = "UPDATE_ABOUT_PAGE_ABILITY"
export const DELETE_ABOUT_PAGE_TILES = "DELETE_ABOUT_PAGE_TILES"
export const UPDATE_ABOUT_PAGE_TILE = "UPDATE_ABOUT_PAGE_TILE"
export const ADD_ABOUT_PAGE_TILES = "ADD_ABOUT_PAGE_TILES"
export const UPDATE_ABOUT_PAGE_TEXTS = "UPDATE_ABOUT_PAGE_TEXTS"
export const UPDATE_ABOUT_PAGE_TOP_PARAGRAPH = "UPDATE_ABOUT_PAGE_TOP_PARAGRAPH"
export const ADD_ABOUT_PAGE_BOTTOM_PARAGRAPH = "ADD_ABOUT_PAGE_BOTTOM_PARAGRAPH"
export const UPDATE_ABOUT_PAGE_BOTTOM_PARAGRAPH = "UPDATE_ABOUT_PAGE_BOTTOM_PARAGRAPH"
export const DELETE_ABOUT_PAGE_BOTTOM_PARAGRAPH = "DELETE_ABOUT_PAGE_BOTTOM_PARAGRAPH"
export const UPDATE_ABOUT_PAGE_BANNER = "UPDATE_ABOUT_PAGE_BANNER"
export const DELETE_ABOUT_PAGE_BANNER = "DELETE_ABOUT_PAGE_BANNER"
export const CONVERT_ABOUT_PAGE_TILE_TO_IMAGE = "CONVERT_ABOUT_PAGE_TILE_TO_IMAGE"
export const CONVERT_ABOUT_PAGE_TILE_TO_TEXT = "CONVERT_ABOUT_PAGE_TILE_TO_TEXT"
export const SWITCH_ABOUT_PAGE_LANGUAGE = "SWITCH_ABOUT_PAGE_LANGUAGE"
export const FETCH_CONTENT_DATA = "FETCH_CONTENT_DATA"
export const UPDATE_CONTENT_DATA = "UPDATE_CONTENT_DATA"
export const REMOVE_ABOUT_PAGE_LANGUAGE = "REMOVE_ABOUT_PAGE_LANGUAGE"
export const TOGGLE_ABOUT_PAGE_ABILITY = "TOGGLE_ABOUT_PAGE_ABILITY"
export const SET_EMPTY_ABOUT_PAGE = "SET_EMPTY_ABOUT_PAGE"
export const UPDATE_POPULAR_PRODUCTS_POSITIONING = "UPDATE_POPULAR_PRODUCTS_POSITIONING"
export const UPDATE_HIGHLIGHTED_PRODUCTS_POSITIONING = "UPDATE_HIGHLIGHTED_PRODUCTS_POSITIONING"
export const UPDATE_MENU_ITEMS = "UPDATE_MENU_ITEMS"
export const REARRANGE_SPECIAL_MESSAGE_BANNER = "REARRANGE_SPECIAL_MESSAGE_BANNER"
export const UPDATE_SPECIAL_MESSAGE_BANNER = "UPDATE_SPECIAL_MESSAGE_BANNER"
export const UPDATE_CONTENT_DATA_BY_KEY = "UPDATE_CONTENT_DATA_BY_KEY"

// actions
export const SAVE_CONTENT = "SAVE_CONTENT"

export const base_state = () => ({
  footer_content: "", // HTML string
  page_translations_edited: false,
  header_items: [],
  error_list: [],
  popular_products: [],
  highlighted_products: [],
  images_to_delete: [],
  banners: {}
})
