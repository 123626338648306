import get_correct_translation_value from "../../Shared/methods/get_correct_translation_value"
import update_with_percentage from "./update_with_percentage"

export default (basket_items, language, discount_code) => {
  return basket_items.reduce((total, basket_item) => {
    let final_product_price = Number(
      get_correct_translation_value(basket_item, "price", [language], false, false, "prices")
    ) * Number(basket_item.quantity)
    let is_discount_applicable

    if (discount_code && discount_code.type) {
      if (discount_code.key) {
        // Check if discount can be applied to product
        is_discount_applicable = discount_code.key === "collections" ?
          basket_item.collections.some(({ id }) => discount_code.ids.includes(id)) :
          discount_code.ids.includes(basket_item[discount_code.key])
      }

      if (is_discount_applicable || !discount_code.key) {
        // Change value to be positive
        const correct_value = Number(discount_code.translations && discount_code.type !== "percentage" ?
          get_correct_translation_value(discount_code, "value", [language], false) :
          discount_code.amount) * -1

        final_product_price = discount_code.type === "percentage" ?
          update_with_percentage(final_product_price, correct_value) : final_product_price - correct_value
      }
    }
    
    return total + final_product_price
  }, 0)
}
