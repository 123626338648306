import mutations from "./mutations"
import actions from "./actions"
import getters from "./getters"
import { base_state } from "./constants"

export default {
  namespaced: true,
  state: base_state(),
  mutations,
  actions,
  getters
}
