import { currency_map, default_language, lang_to_currency_mapper } from "../../../../Shared/constants/other"
import { GET_USER_CURRENCY, GET_USER_LANGUAGE } from "./constants"

export default {
  [GET_USER_CURRENCY](state) {
    return state.user_info.currency || lang_to_currency_mapper[state.user_info.language_code] || currency_map.USD
  },
  [GET_USER_LANGUAGE](state) {
    return state.user_info.language_code || default_language
  }
}
