import Vue from "vue"
import {
  UPDATE_TRANSLATIONS, REMOVE_TRANSLATION, UPDATE_PAGE_TYPE_TRANSLATION,
  ADD_PAGE_TYPE_TRANSLATION, UPDATE_PROJECT_STYLE, UPDATE_PROJECT_REST_CONFIG,
  UPDATE_SHOPIFY_CONFIG, UPDATE_CUSTOM_SHIPPING_METHODS, UPDATE_ERRORS,
  UPDATE_PROJECT_CONFIG, UPDATE_ANALYTICS_CONFIG, UPDATE_PAYPAL_CONFIG,
  DELETE_PAGE_TYPE_TRANSLATION, UPDATE_LEGAL_PAGE_TRANSLATIONS,
  REMOVE_LEGAL_PAGE_TRANSLATION, UPDATE_CONFIGURATION_DATA, UPDATE_SHIPPING_DISCOUNTS,
  UPDATE_SHIPPING_ALLOWED_COUNTRIES, UPDATE_SHIPPING_MAX_DISCOUNT, UPDATE_PROJECT_ADDRESS,
  UPDATE_PROJECT_NAME, UPDATE_PRODUCTS_UPDATED_STATE, UPDATE_OTHER_SHIPPING_OPTIONS
} from "./constants"
import default_translations_object from "../../../constants/empty_objects/default_translations_object"
import empty_shopify_configuration from "../../../constants/empty_objects/empty_shopify_configuration"
import empty_project_config from "../../../constants/empty_objects/empty_project_config"
import empty_analytics_config from "../../../constants/empty_objects/empty_analytics_config"
import empty_paypal_config from "../../../constants/empty_objects/empty_paypal_config"
import project_styles from "../../../../../data/project_styles"
import { default_language } from "../../../../Shared/constants/other"
import { ADMIN, page_type_translation_objects, PRODUCTS, translation_objects } from "../../../constants/others_constants"
import { UPDATE_PRODUCT_CURRENCIES } from "../products/constants"

export default {
  [UPDATE_PRODUCTS_UPDATED_STATE](state, value = false) {
    state.products_updated = value
  },
  [UPDATE_TRANSLATIONS](state, [key, value, language_code, sub_key, set_translations]) {
    // If new language, first add default translations
    if (set_translations || !state.translations[language_code]) Vue.set(
      state.translations,
      language_code,
      JSON.parse(JSON.stringify(translation_objects[language_code] || default_translations_object))
    )

    if (!set_translations) {
      Vue.set(
        sub_key === undefined ? state.translations[language_code] : state.translations[language_code][key],
        sub_key === undefined ? key : sub_key,
        value
      )
    }

    if (key === "currency_code" || set_translations) {
      this.commit(
        `${ADMIN}/${PRODUCTS}/${UPDATE_PRODUCT_CURRENCIES}`,
        [language_code, state.translations[language_code].currency_code],
      )

      state.products_updated = true
    }
  },
  [UPDATE_LEGAL_PAGE_TRANSLATIONS](state, [key, value, language_code]) {
    // If new language, first add default translations
    if (!state.other_page_translations) Vue.set(
      state,
      "other_page_translations",
      {}
    )
    if (!state.other_page_translations[language_code]) Vue.set(
      state.other_page_translations,
      language_code,
      {
        [key]: ""
      }
    )

    Vue.set(state.other_page_translations[language_code], key, value)
  },
  [REMOVE_LEGAL_PAGE_TRANSLATION](state, language_code) {
    Vue.set(state.other_page_translations, language_code, undefined)
    Vue.set(state, "other_page_translations", JSON.parse(JSON.stringify(state.other_page_translations))) // removes undefined keys
  },
  [REMOVE_TRANSLATION](state, language_code) {
    Vue.set(state.translations, language_code, undefined)
    Vue.set(state, "translations", JSON.parse(JSON.stringify(state.translations))) // removes undefined keys
  },
  [UPDATE_PAGE_TYPE_TRANSLATION](state, [page_type, handle, key, value, language]) {
    const base_language = state.translations[default_language] ? default_language : Object.keys(state.translations)[0]
    const correct_translations = page_type_translation_objects[language || base_language]

    // Setting values if undefined
    if (!state[page_type]) Vue.set(state, page_type, {})
    if (!state[page_type][handle]) Vue.set(
      state[page_type],
      handle,
      { [language || base_language]: JSON.parse(JSON.stringify(correct_translations))[page_type]
    })

    if (!state[page_type][handle][language]) Vue.set(
      state[page_type][handle],
      language || base_language,
      JSON.parse(JSON.stringify(correct_translations))[page_type]
    )

    if (key) Vue.set(state[page_type][handle][language], key, value)
  },
  [DELETE_PAGE_TYPE_TRANSLATION](state, [page_type, handle]) {
    Vue.delete(state[page_type], handle)
  },
  [ADD_PAGE_TYPE_TRANSLATION](state, [page_type, product_handle, value, language]) {
    if (!state[page_type]) Vue.set(state, page_type, {})

    Vue.set(state[page_type], product_handle, { [language]: JSON.parse(JSON.stringify(value)) })
  },
  [UPDATE_PROJECT_NAME](state, name) {
    Vue.set(state, "project_name", name)
  },
  [UPDATE_PROJECT_STYLE](state, [style_key, value, is_color]) {
    if (!Object.keys(state.project_styles).length) Vue.set(state, "project_styles", project_styles)

    Vue.set(state.project_styles[is_color ? "colors" : "sizes"], style_key, value)
  },
  [UPDATE_PROJECT_REST_CONFIG](state, [key, value, thumb_image]) {
    Vue.set(
      state.rest_config,
      key,
      thumb_image ? {
        image_url: value,
        thumb_url: thumb_image
      } : value
    )
  },
  [UPDATE_SHOPIFY_CONFIG](state, [parent_key, key, value]) {
    if (!Object.keys(state.shopify_config).length) Vue.set(state, "shopify_config", empty_shopify_configuration)

    Vue.set(parent_key ? state.shopify_config[parent_key] : state.shopify_config, key, value)
  },
  [UPDATE_CUSTOM_SHIPPING_METHODS](state, shipping_methods) {
    if (!state.shipping_options) Vue.set(state, "shipping_options", {})

    Vue.set(state.shipping_options, "custom_methods", shipping_methods)
  },
  [UPDATE_SHIPPING_DISCOUNTS](state, shipping_discounts) {
    if (!state.shipping_options) Vue.set(state, "shipping_options", {})

    Vue.set(state.shipping_options, "shipping_discounts", shipping_discounts)
  },
  [UPDATE_SHIPPING_MAX_DISCOUNT](state, max_discount) {
    if (!state.shipping_options) Vue.set(state, "shipping_options", {})
    if (!state.shipping_options.settings) Vue.set(state.shipping_options, "settings", {})

    Vue.set(state.shipping_options.settings, "maximum_shipping_discount", max_discount)
  },
  [UPDATE_SHIPPING_ALLOWED_COUNTRIES](state, allowed_countries) {
    if (!state.shipping_options) Vue.set(state, "shipping_options", {})
    if (!state.shipping_options.settings) Vue.set(state.shipping_options, "settings", {})

    Vue.set(state.shipping_options.settings, "allowed_countries", allowed_countries)
  },
  [UPDATE_PROJECT_ADDRESS](state, [key, value]) {
    if (!state.shipping_options) Vue.set(state, "shipping_options", {})
    if (!state.shipping_options.project_address) Vue.set(state.shipping_options, "project_address", {})

    Vue.set(state.shipping_options.project_address, key, value)
  },
  [UPDATE_OTHER_SHIPPING_OPTIONS](state, [type, values]) {
    if (!state.shipping_options) Vue.set(state, "shipping_options", {})
    if (!state.shipping_options.other_methods) Vue.set(state.shipping_options, "other_methods", {})

    Vue.set(state.shipping_options.other_methods, type, values)
  },
  [UPDATE_PROJECT_CONFIG](state, [key, value]) {
    if (!Object.keys(state.project_config).length) Vue.set(state, "project_config", empty_project_config)

    Vue.set(state.project_config, key, value)
  },
  [UPDATE_ANALYTICS_CONFIG](state, [key, value]) {
    if (!Object.keys(state.analytics_config).length) Vue.set(state, "analytics_config", empty_analytics_config)
  
    Vue.set(state.analytics_config, key, value)
  },
  [UPDATE_PAYPAL_CONFIG](state, [key, value]) {
    if (!Object.keys(state.paypal_config).length) Vue.set(state, "paypal_config", empty_paypal_config)

    Vue.set(state.paypal_config, key, value)
  },
  [UPDATE_ERRORS](state, error_list) {
    Vue.set(state, "error_list", error_list)
  },
  [UPDATE_CONFIGURATION_DATA](state, data) {
    Object.entries(data).forEach(([key, value]) => Vue.set(state, key, value))
  }
}
