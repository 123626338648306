export default (raw = false) => {
  const { userAgent, language, languages, platform, hardwareConcurrency, deviceMemory } = window.navigator;
  const plugins = Object.entries(window.navigator.plugins).map(([, plugin]) => plugin.name);
  const { colorDepth, availWidth, availHeight } = window.screen;
  const timezoneOffset = new Date().getTimezoneOffset();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const touchSupport = 'ontouchstart' in window;

  const data = {
      userAgent,
      language,
      languages,
      platform,
      hardwareConcurrency,
      deviceMemory,
      plugins,
      colorDepth,
      availWidth,
      availHeight,
      timezoneOffset,
      timezone,
      touchSupport,
  };
  const stringyfied_data = JSON.stringify(data)

  return raw ? data : btoa(stringyfied_data);
};