import shopify_checkout from "shopify-buy"
import { fetch_firebase_data, fetch_shopify_data, get_products_by_ids, get_shopify_public_config } from "../../../Shared/methods/ajax/endpoints"
import { count_store_visitor } from "../../constants/endpoints"
import {
  FETCH_PRODUCTS_BY_IDS, UPDATE_PRODUCTS_DATA, UPDATE_PRODUCTS_BEING_FETCHED, UPDATE_SHOPIFY_CHECKOUT,
  BASE_DATA_FETCH, UPDATE_DATA, UPDATE_HEADER_ITEMS, UPDATE_COLLECTIONS, UPDATE_IMPORTANT_MESSAGE_BAR,
  HAS_STORE_ABOUT_PAGE, UPDATE_TRANSLATIONS, UPDATE_SHIPPING_OPTIONS, UPDATE_BANNERS, UPDATE_POPULAR_PRODUCTS,
  UPDATE_HIGHLIGHTED_PRODUCTS, UPDATE_FOOTER, UPDATE_STYLES, UPDATE_ANALYTICS_CONFIG, UPDATE_PROJECT_CONFIG, UPDATE_REST_CONFIG,
  TOGGLE_SHOPIFY_USE, TOGGLE_DEMO_STATE, UPDATE_SHOPIFY_INSTANCE, COUNT_VISITOR_INTERACTION, UPDATE_VISITOR_INTERATION_CHECKS
} from "./constants"

export default {
  async [FETCH_PRODUCTS_BY_IDS]({ commit, state }, { ids = [], fetch_by_collections = false }) {
    if (!fetch_by_collections) {
      // Filter out products that are already loaded or being fetched by other requests
      ids = ids.filter(id =>
        !state.products.some(({ id: product_id }) => id === product_id) &&
        !state.products_being_fetched.includes(id)
      )
  
      if (!ids.length) return
      commit(UPDATE_PRODUCTS_BEING_FETCHED, Array.from(
        new Set([...ids, ...state.products_being_fetched]))
      )
    }

    const { data } = await get_products_by_ids({ ids, fetch_by_collections })

    commit(UPDATE_PRODUCTS_DATA, [...data, ...state.products])

    if (!fetch_by_collections) {
      commit(
        UPDATE_PRODUCTS_BEING_FETCHED,
        // Filter out products that are fetched by this request
        state.products_being_fetched.filter(
          id => !ids.some(product_id => id === product_id)
        )
      )
    }
  },
  async [BASE_DATA_FETCH]({ commit, dispatch }) {
    const { data: firebase_data } = await fetch_firebase_data()
    let collections = firebase_data.imported_collections

    if(firebase_data.should_use_shopify_data) {
      const { data: shopify_public } = await get_shopify_public_config()
      // Initiate Shopify instance
      const shopify_instance = shopify_checkout.buildClient({
        storefrontAccessToken: shopify_public.storefront_access_token,
        domain: shopify_public.shopify_store_url
      })
      commit(UPDATE_SHOPIFY_INSTANCE, shopify_instance)

      // Initiate Shopify checkout
      const checkout = await shopify_instance.checkout.create()
      commit(UPDATE_SHOPIFY_CHECKOUT, checkout)

      // Fetch shopify data
      const { data: shopify_data } = await fetch_shopify_data()

      const products = shopify_data.products
      collections = shopify_data.collections

      commit(UPDATE_PRODUCTS_DATA, products)
    }

    dispatch(UPDATE_DATA, { firebase_data, collections })
  },
  [UPDATE_DATA]({ commit }, { firebase_data, collections = [] }) {
    commit(UPDATE_HEADER_ITEMS, firebase_data.header_items)
    commit(UPDATE_COLLECTIONS, collections)
    commit(UPDATE_TRANSLATIONS, firebase_data.translations)
    commit(UPDATE_SHIPPING_OPTIONS, firebase_data.shipping_options)
    commit(UPDATE_BANNERS, firebase_data.banners)
    commit(UPDATE_POPULAR_PRODUCTS, firebase_data.popular_products)
    commit(UPDATE_HIGHLIGHTED_PRODUCTS, firebase_data.highlighted_products)
    commit(UPDATE_IMPORTANT_MESSAGE_BAR, firebase_data.important_message_bar)
    commit(UPDATE_FOOTER, firebase_data.footer_content)
    commit(UPDATE_STYLES, firebase_data.project_styles)
    commit(UPDATE_ANALYTICS_CONFIG, firebase_data.analytics_config)
    commit(UPDATE_PROJECT_CONFIG, firebase_data.project_config)
    commit(UPDATE_REST_CONFIG, firebase_data.rest_config)
    commit(TOGGLE_SHOPIFY_USE, firebase_data.should_use_shopify_data)
    commit(TOGGLE_DEMO_STATE, firebase_data.is_demo_account)
    commit(HAS_STORE_ABOUT_PAGE, firebase_data.has_about_page)
  },
  [COUNT_VISITOR_INTERACTION]({ state, commit }, interaction_type) {
    // Dont call if already called
    if (Object.values(state.visitor_interaction_checks).every(val => val)) return
    
    commit(UPDATE_VISITOR_INTERATION_CHECKS, interaction_type)

    if (Object.values(state.visitor_interaction_checks).every(val => val)) count_store_visitor()
  }
}
