// Main
import Vue from "vue"
import Vuex from "vuex"

// Modules
import Client from "./applications/Client/stores/Client"
import Admin from "./applications/Admin/stores/Admin/index"
import Styles from "./applications/Client/stores/Styles"
import Marketplace from "./applications/Marketplace/stores/index"
import Shared from "./applications/Shared/stores/Shared"

// Mutations
export const UPDATE_ENVIRONMENT = "UPDATE_ENVIRONMENT"
export const UPDATE_GLOBAL_LOADER = "UPDATE_GLOBAL_LOADER"
export const ADD_LOADSCRIPT_GLOBAL_VAR = "ADD_LOADSCRIPT_GLOBAL_VAR"
export const UPDATE_GLOBAL_USER = "UPDATE_GLOBAL_USER"
export const SAVE_LAST_VISITED_PAGE = "SAVE_LAST_VISITED_PAGE"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Client,
    Admin,
    Styles,
    Shared,
    Marketplace
  },
  state: {
    is_marketplace_environment: false,
    global_loader: false,
    user: null,
    $load_script: null,
    last_visited_page: null
  },
  mutations: {
    [SAVE_LAST_VISITED_PAGE](state, data) {
      state.last_visited_page = data
    },
    [UPDATE_ENVIRONMENT](state, value) {
      state.is_marketplace_environment = value
    },
    [UPDATE_GLOBAL_LOADER](state, value = true) {
      state.global_loader = value
    },
    [UPDATE_GLOBAL_USER](state, user) {
      state.user = user
    },
    [ADD_LOADSCRIPT_GLOBAL_VAR](state, load_script) {
      state.$load_script = load_script
    }
  }
})
