import axios from "axios"
import { add_projects_social_entities, fetch_social } from "../../../constants/endpoints/firebase"
import { ADMIN, CONFIGURATION, CONFIGURATION_STORE, severities } from "../../../constants/others_constants"
import facebook_authorization from "../../../methods/facebook_authorization"
import { ADD_GLOBAL_ERROR, LOGIN_VIA_TOKEN, UPDATE_LOADER } from "../constants"
import { translate } from "../../../../Shared/translator"
import {
  FACEBOOK_AUTH, FETCH_FACEBOOK_PAGES, UPDATE_USER_AUTH_DATA, UPDATE_FB_PAGES, CONNECT_SOCIAL_MEDIA_ENTITIES,
  UPDATE_CONNECTED_ENTITIES, FETCH_SOCIAL_MEDIA, FETCH_FACEBOOK_GROUPS, UPDATE_FB_GROUPS, PUBLISH_FACEBOOK_POSTS,
  FETCH_FACEBOOK_POSTS, UPDATE_FACEBOOK_GROUP_POSTS, UPDATE_FACEBOOK_PAGE_POSTS, SWITCH_FACEBOOK_ACCOUNT,
  REAUTHENTICATE_FACEBOOK_PAGE, UPDATE_CONNECTED_ENTITY, FETCH_INSTAGRAM_ACCOUNTS, UPDATE_IG_ACCOUNTS,
  FETCH_INSTAGRAM_POSTS, UPDATE_INSTAGRAM_POSTS, PUBLISH_INSTAGRAM_POSTS, FETCH_FACEBOOK_AD_ACCOUNTS,
  UPDATE_FB_AD_ACCOUNTS, PUBLISH_FACEBOOK_AD, FETCH_FACEBOOK_AD_CAMPAIGNS, UPDATE_AD_CAMPAINGS, CREATE_FACEBOOK_CAMPAIGN,
  UPDATE_AD_AUDIENCES, FETCH_FACEBOOK_AUDIENCES, FETCH_FACEBOOK_ADS, UPDATE_ADS, FETCH_FACEBOOK_AD_INSIGHTS,
  UPDATE_AD_INSIGHTS, FETCH_FACEBOOK_PIXELS, UPDATE_FB_PIXELS, FETCH_FACEBOOK_PIXELS_STATS, UPDATE_FB_PIXEL_STATS,
  FETCH_GA_REPORT, GOOGLE_AUTH, UPDATE_GA_REPORT, SWITCH_GOOGLE_ACCOUNT, FETCH_GA_VIEWS, UPDATE_GA_VIEWS
} from "./constants"
import { message_durations } from "../../../../Shared/constants/other"
import {
  social_media_provider_names, social_platform_entities_mapper, fb_api_url, IG_media_upload_not_finished_error_subcode,
  IG_video_upload_iteration_wait, DEFAULT_FB_BID_STRATEGY, fb_ad_billing_event_names, fb_media_file_types, fb_bid_strategies_requiring_cap,
  fb_objective_rules, google_client_id, google_auth_scopes, google_analytics_data_types
} from "../../../constants/social_media_constants"
import upload_and_remove_media from "../../../methods/upload_and_remove_media"
import { assemble_facebook_ad_audiences, create_facebook_ad_creative, upload_facebook_ad_media } from "./action_helpers"
import google_authorization from "../../../methods/google_authorization"
import { UPDATE_ANALYTICS_CONFIG } from "../configuration/constants"

export default {
  async [FACEBOOK_AUTH]({ commit, state }, change_account) {
    try {
      const user_auth_data = await facebook_authorization(state.fb_user_auth_data, change_account)

      commit(UPDATE_USER_AUTH_DATA, [user_auth_data, social_media_provider_names.fb])
    } catch ({ response: { data = { error: {} } } = {}, message }) {
      commit(`${ADMIN}/${ADD_GLOBAL_ERROR}`, { message: data.error.error_user_msg || data.error.message || message }, { root: true })
    }
  },
  async [FETCH_FACEBOOK_PAGES]({ commit, state, dispatch }) {
    commit(`${ADMIN}/${UPDATE_LOADER}`, true, { root: true })

    try {
      await dispatch(FACEBOOK_AUTH)

      if (state.fb_user_auth_data) {
        let { data: { data }, error } = await axios.get(
          `${fb_api_url}/${
            state.fb_user_auth_data.id
          }/accounts?fields=instagram_business_account,picture,name,tasks,access_token&access_token=${
            state.fb_user_auth_data.accessToken
          }`
        )

        if (error) throw new Error(error.message)

        data = data.map(({ picture, ...page }) => ({
          ...page,
          picture: picture ? picture.data.url : null
        }))

        commit(UPDATE_FB_PAGES, data)
      }
    } catch ({ response: { data = { error: {} } } = {}, message }) {
      commit(`${ADMIN}/${ADD_GLOBAL_ERROR}`, { message: data.error.error_user_msg || data.error.message || message }, { root: true })
    }

    commit(`${ADMIN}/${UPDATE_LOADER}`, false, { root: true })
  },
  async [FETCH_FACEBOOK_GROUPS]({ commit, state, dispatch }) {
    commit(`${ADMIN}/${UPDATE_LOADER}`, true, { root: true })
    await dispatch(FACEBOOK_AUTH)

    try {
      if (state.fb_user_auth_data) {
        const { data: { data }, error } = await axios.get(
          `${fb_api_url}/${
            state.fb_user_auth_data.id
          }/groups?fields=name,picture,member_count&access_token=${
            state.fb_user_auth_data.accessToken
          }`
        )

        if (error) throw new Error(error.message)

        const groups = data
          .filter(({ member_count }) => member_count !== undefined)
          .map(({ picture = { data: {} }, ...rest } = {}) => ({ picture: picture.data.url, ...rest }))

        commit(UPDATE_FB_GROUPS, groups)
      }
    } catch ({ response: { data = { error: {} } } = {}, message }) {
      commit(`${ADMIN}/${ADD_GLOBAL_ERROR}`, { message: data.error.error_user_msg || data.error.message || message }, { root: true })
    }
    commit(`${ADMIN}/${UPDATE_LOADER}`, false, { root: true })
  },
  async [FETCH_INSTAGRAM_ACCOUNTS]({ commit, state, dispatch }) {
    commit(`${ADMIN}/${UPDATE_LOADER}`, true, { root: true })

    try {
      await dispatch(FACEBOOK_AUTH)

      if (state.fb_user_auth_data) {
        if (!state.fb_pages.length) await dispatch(FETCH_FACEBOOK_PAGES)

        let instagram_accounts = await Promise.all(state.fb_pages.map(async ({ instagram_business_account }) => {
          if (instagram_business_account) {
            const { data } = await axios.get(
              `${fb_api_url}/${
                instagram_business_account.id
              }?fields=id,ig_id,username,profile_picture_url&access_token=${
                state.fb_user_auth_data.accessToken
              }`
            )

            return {
              id: data.id,
              name: data.username,
              picture: data.profile_picture_url
            }
          }

          return
        }))

        instagram_accounts = instagram_accounts.filter(val => val) // Filter out empty values

        commit(UPDATE_IG_ACCOUNTS, instagram_accounts)
      }
    } catch ({ response: { data = { error: {} } } = {}, message }) {
      commit(`${ADMIN}/${ADD_GLOBAL_ERROR}`, { message: data.error.error_user_msg || data.error.message || message }, { root: true })
    }

    commit(`${ADMIN}/${UPDATE_LOADER}`, false, { root: true })
  },
  async [FETCH_FACEBOOK_AD_ACCOUNTS]({ commit, state, dispatch }) {
    commit(`${ADMIN}/${UPDATE_LOADER}`, true, { root: true })

    try {
      await dispatch(FACEBOOK_AUTH)

      if (state.fb_user_auth_data) {
        let { data: { data }, error } = await axios.get(
          `${fb_api_url}/${state.fb_user_auth_data.id}/adaccounts?fields=name&access_token=${state.fb_user_auth_data.accessToken}`
        )

        if (error) throw new Error(error.message)

        commit(UPDATE_FB_AD_ACCOUNTS, data)
      }
    } catch ({ response: { data = { error: {} } } = {}, message }) {
      commit(`${ADMIN}/${ADD_GLOBAL_ERROR}`, { message: data.error.error_user_msg || data.error.message || message }, { root: true })
    }

    commit(`${ADMIN}/${UPDATE_LOADER}`, false, { root: true })
  },
  async [FETCH_FACEBOOK_AD_CAMPAIGNS]({ commit, state, dispatch }, ad_accounts) {
    commit(`${ADMIN}/${UPDATE_LOADER}`, true, { root: true })

    try {
      await dispatch(FACEBOOK_AUTH)

      if (state.fb_user_auth_data) {
        let campaigns = await Promise.all(ad_accounts.map(async ({ id: ad_account_id }) => {
          const { data: { data }, error } = await axios.get(
            `${fb_api_url}/${ad_account_id}/campaigns?fields=name,bid_strategy,objective&access_token=${state.fb_user_auth_data.accessToken}`
          )

          if (error) throw new Error(error.message)

          return [ad_account_id, data]
        }))
        campaigns = campaigns.reduce((total, [ad_account_id, loaded_campaigns]) => ({
          ...total,
          [ad_account_id]: loaded_campaigns
        }), {})

        commit(UPDATE_AD_CAMPAINGS, [
          {
            ...state[`${social_media_provider_names.fb}_ad_campaigns`],
            ...campaigns
          },
          social_media_provider_names.fb
        ])
      }
    } catch ({ response: { data = { error: {} } } = {}, message }) {
      commit(`${ADMIN}/${ADD_GLOBAL_ERROR}`, { message: data.error.error_user_msg || data.error.message || message }, { root: true })
    }

    commit(`${ADMIN}/${UPDATE_LOADER}`, false, { root: true })
  },
  async [SWITCH_FACEBOOK_ACCOUNT]({ dispatch }) {
    dispatch(FACEBOOK_AUTH, true)
  },
  async [FETCH_FACEBOOK_POSTS]({ commit, dispatch, state, rootState }, [{ id }, is_group]) {
    commit(`${ADMIN}/${UPDATE_LOADER}`, true, { root: true })

    try {
      await dispatch(is_group ? FETCH_FACEBOOK_GROUPS : FETCH_FACEBOOK_PAGES)

      const entity = [...state.fb_pages, ...state.fb_groups].find(({ id: entity_id } = {}) => id === entity_id)

      if (!entity) throw new Error(
        await translate("social_media_posts.page_or_group_not_found")
      )

      const { data, error } = await axios.get(
        `${fb_api_url}/${id}/feed?fields=picture,message,created_time,attachments{media,media_type,subattachments},is_published&limit=10&access_token=${
          is_group ? state.fb_user_auth_data.accessToken : entity.access_token
        }`
      )
      if (error) throw new Error(error.message)

      commit(is_group ? UPDATE_FACEBOOK_GROUP_POSTS : UPDATE_FACEBOOK_PAGE_POSTS, [id, data.data])
    } catch ({ response: { data } }) {
      commit(`${ADMIN}/${ADD_GLOBAL_ERROR}`, { message: data.error.message, duration: message_durations.long }, { root: true })
    }

    commit(`${ADMIN}/${UPDATE_LOADER}`, false, { root: true })
  },
  async [FETCH_INSTAGRAM_POSTS]({ commit, dispatch, state }, [{ id }]) {
    commit(`${ADMIN}/${UPDATE_LOADER}`, true, { root: true })

    try {
      await dispatch(FACEBOOK_AUTH)

      let { data: { data: instagram_posts }, error } = await axios.get(
        `${fb_api_url}/${id}/media?fields=media_url,caption,timestamp,media_type&limit=10&access_token=${state.fb_user_auth_data.accessToken}`
      )

      if (error) throw new Error(error.message)

      instagram_posts = instagram_posts.map(({ caption, id, media_url, media_type, timestamp }) => ({
        created_time: timestamp,
        message: caption,
        ...(media_type && {
          attachments: { data: [{ media_type, media: { image: { src: media_url } } }] }
        }),
        is_published: true,
        id
      }))

      commit(UPDATE_INSTAGRAM_POSTS, [id, instagram_posts])
    } catch ({ response: { data } }) {
      commit(`${ADMIN}/${ADD_GLOBAL_ERROR}`, { message: data.error.message, duration: message_durations.long }, { root: true })
    }

    commit(`${ADMIN}/${UPDATE_LOADER}`, false, { root: true })
  },
  async [FETCH_FACEBOOK_AD_INSIGHTS]({ commit, dispatch, state }) {
    commit(`${ADMIN}/${UPDATE_LOADER}`, true, { root: true })

    if (!state.connected_fb_ad_accounts.length) return commit(`${ADMIN}/${UPDATE_LOADER}`, false, { root: true })

    try {
      await dispatch(FACEBOOK_AUTH)

      const assigned_ad_insights = await Promise.all(state.connected_fb_ad_accounts.map(async ({ id: ad_account_id }) => {
        const { data: { data: ad_insights }, error } = await axios.get(
          `${fb_api_url}/${ad_account_id}/insights?time_increment=30&date_preset=last_30d&level=adset&fields=clicks,
          conversion_rate_ranking,conversions,cost_per_conversion,cpc,cpm,ctr,frequency,impressions,purchase_roas,
          reach,spend,adset_id&access_token=${state.fb_user_auth_data.accessToken}`
        )

        if (error) throw new Error(error.message)

        return [ad_account_id, ad_insights]
      }))

      commit(
        UPDATE_AD_INSIGHTS,
        [social_media_provider_names.fb, assigned_ad_insights.reduce((tot, [id, insights]) => ({ ...tot, [id]: insights }), {})]
      )
    } catch ({ response: { data = { error: {} } } = {}, message }) {
      commit(`${ADMIN}/${ADD_GLOBAL_ERROR}`, { message: data.error.error_user_msg || data.error.message || message }, { root: true })
    }

    commit(`${ADMIN}/${UPDATE_LOADER}`, false, { root: true })
  },
  async [FETCH_FACEBOOK_PIXELS]({ commit, dispatch, state }) {
    commit(`${ADMIN}/${UPDATE_LOADER}`, true, { root: true })

    if(!state.connected_fb_ad_accounts.length) return commit(`${ADMIN}/${UPDATE_LOADER}`, false, { root: true })

    try {
      await dispatch(FACEBOOK_AUTH)

      const facebook_pixels = await Promise.all(state.connected_fb_ad_accounts.map(async ({ id: ad_account_id }) => {
        const { data: { data: pixels }, error } = await axios.get(
          `${fb_api_url}/${ad_account_id}/adspixels?fields=name&access_token=${state.fb_user_auth_data.accessToken}`
        )

        if (error) throw new Error(error.message)

        return [ad_account_id, pixels]
      }))

      commit(UPDATE_FB_PIXELS, facebook_pixels.reduce((tot, [id, pixels]) => ({ ...tot, [id]: pixels }), {}))
    } catch ({ response: { data = { error: {} } } = {}, message }) {
      commit(`${ADMIN}/${ADD_GLOBAL_ERROR}`, { message: data.error.error_user_msg || data.error.message || message }, { root: true })
    }

    commit(`${ADMIN}/${UPDATE_LOADER}`, false, { root: true })
  },
  async [FETCH_FACEBOOK_PIXELS_STATS]({ commit, dispatch, state }, start_time) {
    commit(`${ADMIN}/${UPDATE_LOADER}`, true, { root: true })

    const all_pixels = Object.values(state.fb_pixels).flat()

    if (!all_pixels.length) return commit(`${ADMIN}/${UPDATE_LOADER}`, false, { root: true })

    try {
      await dispatch(FACEBOOK_AUTH)

      const assigned_pixel_stats = await Promise.all(all_pixels.map(async ({ id }) => {
        const { data: { data: pixel_stats }, error } = await axios.get(
          `${fb_api_url}/${id}/stats?start_time=${start_time}&access_token=${state.fb_user_auth_data.accessToken}`
        )

        if (error) throw new Error(error.message)

        return [id, pixel_stats]
      }))

      commit(UPDATE_FB_PIXEL_STATS, assigned_pixel_stats)
    } catch ({ response: { data = { error: {} } } = {}, message }) {
      commit(`${ADMIN}/${ADD_GLOBAL_ERROR}`, { message: data.error.error_user_msg || data.error.message || message }, { root: true })
    }

    commit(`${ADMIN}/${UPDATE_LOADER}`, false, { root: true })
  },
  async [FETCH_FACEBOOK_AUDIENCES](
    { commit, state, dispatch },
    ad_accounts
  ) {
    commit(`${ADMIN}/${UPDATE_LOADER}`, true, { root: true })

    try {
      await dispatch(FACEBOOK_AUTH)

      if (state.fb_user_auth_data) {
        let audiences = await Promise.all(ad_accounts.map(async ({ id: ad_account_id }) => {
          const { data: { data: custom_audiences } } = await axios.get(
            `${fb_api_url}/${ad_account_id}/customaudiences?fields=name&access_token=${state.fb_user_auth_data.accessToken}`
          )
          const { data: { data: saved_audiences } } = await axios.get(
            `${fb_api_url}/${ad_account_id}/saved_audiences?fields=name&access_token=${state.fb_user_auth_data.accessToken}`
          )

          return [ad_account_id, [...custom_audiences.map(audience => ({ ...audience, is_custom: true })), ...saved_audiences]]
        }))
        audiences = audiences.reduce((total, [ad_account_id, loaded_audiences]) => ({
          ...total,
          [ad_account_id]: loaded_audiences
        }), {})

        commit(UPDATE_AD_AUDIENCES, [
          {
            ...state[`${social_media_provider_names.fb}_ad_audiences`],
            ...audiences
          },
          social_media_provider_names.fb
        ])
      }
    } catch ({ response: { data = { error: {} } } = {}, message }) {
      commit(`${ADMIN}/${ADD_GLOBAL_ERROR}`, {
        message: data.error.error_user_msg || data.error.message || message
      }, { root: true })
    }

    commit(`${ADMIN}/${UPDATE_LOADER}`, false, { root: true })
  },
  async [FETCH_FACEBOOK_ADS](
    { commit, state, dispatch },
    ad_account_id
  ) {
    commit(`${ADMIN}/${UPDATE_LOADER}`, true, { root: true })

    try {
      await dispatch(FACEBOOK_AUTH)

      if (state.fb_user_auth_data) {
        let { data: { data: ads } } = await axios.get(
          `${fb_api_url}/${ad_account_id}/ads?fields=name,adcreatives{image_url},created_time,status&access_token=${
            state.fb_user_auth_data.accessToken
          }`
        )

        ads = Object.values(ads.reduce((total, { id, adcreatives, ...rest }) => {
          return {
            ...total,
            [id]: {
              id,
              images: [
                ...((total[id] || {}).images || []),
                ...(adcreatives ? adcreatives.data.map(({ image_url }) => image_url).filter(val => val) : [])
              ],
              ...rest
            }
          }
        }, {}))

        commit(UPDATE_ADS, [ad_account_id, ads])
      }
    } catch ({ response: { data = { error: {} } } = {}, message }) {
      commit(`${ADMIN}/${ADD_GLOBAL_ERROR}`, {
        message: data.error.error_user_msg || data.error.message || message
      }, { root: true })
    }

    commit(`${ADMIN}/${UPDATE_LOADER}`, false, { root: true })
  },
  async [REAUTHENTICATE_FACEBOOK_PAGE]({ commit, dispatch, state }, entity_id) {
    await dispatch(FACEBOOK_AUTH)

    try {
      const { data, error } = await axios.get(
        `${fb_api_url}/${entity_id}?fields=access_token&access_token=${state.fb_user_auth_data.accessToken}`
      )
      if (error) throw new Error(error.message)

      commit(
        UPDATE_CONNECTED_ENTITY,
        [`fb_${social_platform_entities_mapper[social_media_provider_names.fb][0]}`, entity_id, "access_token", data.access_token]
      )
    } catch ({ response: { data = { error: {} } } = {}, message }) {
      commit(`${ADMIN}/${ADD_GLOBAL_ERROR}`, { message: data.error.error_user_msg || data.error.message || message }, { root: true })
    }
  },
  async [PUBLISH_INSTAGRAM_POSTS](
    { commit, state, dispatch, rootState },
    [post_message, entities, media]
  ) {
    const media_id = new Date().toISOString()
    let media_url
    commit(`${ADMIN}/${UPDATE_LOADER}`, true, { root: true })

    try {
      await dispatch(FACEBOOK_AUTH)
      await dispatch(`${ADMIN}/${LOGIN_VIA_TOKEN}`, false, { root: true })

      // Creating media URLs
      if (media && media.file) media_url = await upload_and_remove_media({
        media_file: media.file,
        media_id,
        project_id: rootState[ADMIN][CONFIGURATION].project_config.project_id
      })
      // If Img is already uploaded and using its url
      else if (media) media_url = media.src

      // Uploading media to respective accounts
      const uploaded_media_entities = await Promise.all(entities.map(
        async ({ id }) => {
          const { data: { id: creation_id } } = await axios.post(`${fb_api_url}/${id}/media`, {
            caption: post_message,
            [`${media.type === fb_media_file_types.videos ? "video" : "image"}_url`]: media_url,
            media_type: (media.type === fb_media_file_types.videos ? "video" : "image").toUpperCase(),
            access_token: state.fb_user_auth_data.accessToken
          })

          return {
            creation_id,
            id
          }
        }
      ))

      /**
       * Recursive function which tries publishing media on IG and if the "Media doesnt exist yet, try again later"
       * error occurs, it tries again in IG_video_upload_iteration_wait period until it succeeds
       */
      const publish_media_on_IG_account = async ({ id, creation_id }) => {
        try {
          await axios.post(`${fb_api_url}/${id}/media_publish`, {
            creation_id,
            access_token: state.fb_user_auth_data.accessToken
          })
        } catch ({ request }) {
          const error_object = JSON.parse(request.response)

          if (
            error_object.error &&
            error_object.error.error_subcode === IG_media_upload_not_finished_error_subcode
          ) {
            console.clear()
            await new Promise((resolve) => setTimeout(() => {
              resolve(publish_media_on_IG_account({ id, creation_id }))
            }, IG_video_upload_iteration_wait))
          }
        }
      }

      // Publishing media on respective accounts
      await Promise.all(uploaded_media_entities.map(publish_media_on_IG_account))

      commit(`${ADMIN}/${ADD_GLOBAL_ERROR}`, {
        message: await translate("social_media_posts.post_published_message", {
          provider: await translate("social_media_accounts.providers.ig")
        }),
        severity: severities.SUCCESS
      }, { root: true })
    } catch ({ response: { data = { error: {} } } = {}, message }) {
      commit(`${ADMIN}/${ADD_GLOBAL_ERROR}`, { message: data.error.error_user_msg || data.error.message || message }, { root: true })
    }

    // Remove media no matter what happens
    if (media_url && media.file) upload_and_remove_media({
      media_id,
      project_id: rootState[ADMIN][CONFIGURATION].project_config.project_id,
      is_video: media.type === fb_media_file_types.videos,
      remove_media: true
    })

    commit(`${ADMIN}/${UPDATE_LOADER}`, false, { root: true })
  },
  async [PUBLISH_FACEBOOK_POSTS](
    { commit, state, dispatch, rootState },
    [post_message, entities, photos = [], video, link]
  ) {
    commit(`${ADMIN}/${UPDATE_LOADER}`, true, { root: true })

    try {
      await dispatch(FACEBOOK_AUTH)
      await Promise.all(entities.map(async ({ id, entity }) => {
        const is_group = entity === social_platform_entities_mapper[social_media_provider_names.fb][1]
        let access_token = state.fb_user_auth_data.accessToken
        let form_data_attached_media
        let media_ids
        const media = [...photos, ...(video ? [video] : [])]

        if (!is_group) {
          await dispatch(REAUTHENTICATE_FACEBOOK_PAGE, id)
          access_token = (state[`connected_fb_${entity}`].find(({ id: entity_id }) => id === entity_id) || {}).access_token
        }

        if (photos || video) {
          form_data_attached_media = media.map(({ file, type, src }) => {
            let form_data

            if (file) {
              form_data = new FormData()
              form_data.append("source", file)
              form_data.append("access_token", access_token)
              form_data.append("published", type === fb_media_file_types.videos) // Video should be published immediately, images shoudlnt

              if (type === fb_media_file_types.videos) form_data.append("description", post_message + (link ? `\n\n${link}` : ""))
            }

            return { form_data, type, src }
          })

          media_ids = await Promise.all(form_data_attached_media.map(async ({ form_data, type, src }) => {
            const { data: { id: media_id } = {}} = await axios.post(
              `${fb_api_url}/${id}/${type}`,
              form_data || {
                url: src,
                published: false,
                access_token
              }
            )

            return media_id
          }))
        }

        if (!video) {
          const { error } = await axios.post(`${fb_api_url}/${id}/feed`,{
            attached_media: media_ids.map(media_fbid => ({ media_fbid })),
            message: post_message + (media.length ? `\n\n${link}` : ""), // If media attached, add link to message body
            link: media.length ? undefined : link, // If media attached, remove link
            access_token
          })

          if (error) throw new Error(error.message)
        }
      }))

      commit(`${ADMIN}/${ADD_GLOBAL_ERROR}`, {
        message: await translate("social_media_posts.post_published_message", {
          provider: await translate("social_media_accounts.providers.fb")
        }),
        severity: severities.SUCCESS
      }, { root: true })
    } catch ({ response: { data = { error: {} } } = {}, message }) {
      commit(`${ADMIN}/${ADD_GLOBAL_ERROR}`, { message: data.error.error_user_msg || data.error.message || message }, { root: true })
    }

    commit(`${ADMIN}/${UPDATE_LOADER}`, false, { root: true })
  },
  async [PUBLISH_FACEBOOK_AD](
    { commit, state, dispatch, rootState },
    [ad_data, ad_accounts, ad_campaigns]
  ) {
    commit(`${ADMIN}/${UPDATE_LOADER}`, true, { root: true })
    const un_finished_campaign_ad_creations = []
    const messages = []

    try {
      await dispatch(FACEBOOK_AUTH)
      await Promise.all(ad_accounts.map(async ({ id: ad_account_id, name: ad_account_name }) => {
        let access_token = state.fb_user_auth_data.accessToken
        let current_audiences = ad_data.selected_audiences[ad_account_id]
        let creative_id
        let media_objects

        // Upload images and videos
        try {
          media_objects = await upload_facebook_ad_media(ad_data, access_token, ad_account_id)
        } catch ({ response: { data = { error: {} }, config } = {}, message }) {
          if (config.data) un_finished_campaign_ad_creations.push({
            id: ad_account_id,
            message: data.error.error_user_msg || data.error.message || message,
            name: ad_account_name,
            is_ad_account: true
          })
        }

        // Creating adCreative
        try {
          creative_id = await create_facebook_ad_creative(ad_data, access_token, ad_account_id, media_objects)
        } catch ({ response: { data = { error: {} }, config } = {}, message }) {
          if (config.data) un_finished_campaign_ad_creations.push({
            id: ad_account_id,
            message: data.error.error_user_msg || data.error.message || message,
            name: ad_account_name,
            is_ad_account: true
          })
        }

        // If there are any "saved audiences", we need to get their targeting objects to be able to use them in an adSet
        if (current_audiences.some(({ is_custom }) => !is_custom)) current_audiences = await assemble_facebook_ad_audiences(
          current_audiences,
          access_token
        )

        await Promise.all(ad_campaigns.map(async ({ id: campaign_id, bid_strategy, objective, name }) => {
          try {
            // Creating adset
            const { data: { id: ad_set_id } = {}} = await axios.post(
              `${fb_api_url}/${ad_account_id}/adsets`,
              {
                name: ad_data.name,
                campaign_id,
                daily_budget: ad_data.daily_budget,
                // If user using bid cap
                ...(fb_bid_strategies_requiring_cap.includes(bid_strategy) && { bid_amount: ad_data.bid_cap }),
                // Pixel ID
                ...(ad_data.promoted_object_type && { [ad_data.promoted_object_type]: ad_data.promoted_object_id }),
                promoted_object: {
                  ...(fb_objective_rules.pixel_id.includes(objective) ?
                    // Initially Pixel ID, later if user has more options
                    {
                      pixel_id: ad_data.promoted_object_id,
                      custom_event_type: ad_data.promoted_object_event_type
                    } :
                    { page_id: ad_data.selected_ad_page.id }
                  )
                },
                lifetime_budget: ad_data.lifetime_budget,
                status: ad_data.fb_ad_status,
                start_time: ad_data.start_date,
                end_time: ad_data.end_date,
                billing_event: ad_data.billing_event || fb_ad_billing_event_names.IMPRESSIONS,
                targeting: {
                  ...current_audiences.targeting,
                  custom_audiences: current_audiences.custom_audiences
                },
                access_token
              }
            )

            // Creating ad
            await axios.post(
              `${fb_api_url}/${ad_account_id}/ads`,
              {
                name: ad_data.name,
                adset_id: ad_set_id,
                creative: { creative_id },
                status: ad_data.fb_ad_status,
                access_token
              }
            )
            commit(`${ADMIN}/${ADD_GLOBAL_ERROR}`, {
              message: await translate("social_media_ads.ad_published", {
                provider: await translate("social_media_accounts.providers.fb"),
                name
              }),
              severity: severities.SUCCESS
            }, { root: true })
          } catch ({ response: { data = { error: {} }, config } = {}, message }) {
            if (config.data && JSON.parse(config.data).campaign_id) un_finished_campaign_ad_creations.push({
              id: JSON.parse(config.data).campaign_id,
              message: data.error.error_user_msg || data.error.message || message,
              name
            })
            messages.push({ message: data.error.error_user_msg || data.error.message || message })
          }
        }))
      }))
    } catch ({ response: { data = { error: {} }, config } = {}, message }) {
      messages.push({ message: data.error.error_user_msg || data.error.message || message })
    }

    commit(`${ADMIN}/${UPDATE_LOADER}`, false, { root: true })
    // TODO create multi message global error
    messages.forEach(message => commit(`${ADMIN}/${ADD_GLOBAL_ERROR}`, message, { root: true }))

    return un_finished_campaign_ad_creations
  },
  async [CREATE_FACEBOOK_CAMPAIGN](
    { commit, state, dispatch, rootState },
    [campaign_data, ad_account_id]
  ) {
    commit(`${ADMIN}/${UPDATE_LOADER}`, true, { root: true })

    try {
      await dispatch(FACEBOOK_AUTH)

      const { error } = await axios.post(
        `${fb_api_url}/${ad_account_id}/campaigns`,
        {
          access_token: state.fb_user_auth_data.accessToken,
          ...campaign_data,
          bid_strategy: DEFAULT_FB_BID_STRATEGY,
        }
      )
      await dispatch(FETCH_FACEBOOK_AD_CAMPAIGNS, [{ id: ad_account_id }])

      if (error) throw new Error(error.message)

      commit(`${ADMIN}/${ADD_GLOBAL_ERROR}`, {
        message: await translate("social_media_ads.campaign_created", {
          provider: await translate("social_media_accounts.providers.fb")
        }),
        severity: severities.SUCCESS
      }, { root: true })
    } catch ({ response: { data = { error: {} } } = {}, message }) {
      commit(
        `${ADMIN}/${ADD_GLOBAL_ERROR}`, {
        message: data.error.error_user_msg || data.error.message || message,
        duration: message_durations.long
      }, { root: true })
    }

    commit(`${ADMIN}/${UPDATE_LOADER}`, false, { root: true })
  },
  async [GOOGLE_AUTH]({ rootState, commit }, runLoader = true) {
    if (runLoader) commit(`${ADMIN}/${UPDATE_LOADER}`, true, { root: true })
  
    try {
      const user_auth_data = await google_authorization(rootState.$load_script)
  
      commit(UPDATE_USER_AUTH_DATA, [user_auth_data, social_media_provider_names.google])
    } catch ({ message }) {
      commit(`${ADMIN}/${ADD_GLOBAL_ERROR}`, { message }, { root: true })
    }
    finally {
      if (runLoader) commit(`${ADMIN}/${UPDATE_LOADER}`, false, { root: true })
    }
  },
  async [SWITCH_GOOGLE_ACCOUNT]({ commit }) {
    commit(`${ADMIN}/${UPDATE_LOADER}`, true, { root: true })

    const user_auth_data = await google_authorization(null, true)

    commit(UPDATE_USER_AUTH_DATA, [user_auth_data, social_media_provider_names.google])
    commit(`${ADMIN}/${UPDATE_LOADER}`, false, { root: true })
  },
  async [FETCH_GA_VIEWS]({ commit, rootState, state, dispatch }) {
    commit(`${ADMIN}/${UPDATE_LOADER}`, true, { root: true })

    try {
      if (!rootState[ADMIN][CONFIGURATION].analytics_config.ga_project_id) throw new Error(
        await translate("analytics.missing_GA_id")
      )

      await dispatch(GOOGLE_AUTH, false)

      if (state.google_user_auth_data) {
        if (!gapi.client.analytics) {
          await gapi.auth.authorize({
            client_id: google_client_id,
            scope: google_auth_scopes.join(" "),
            immediate: true
          });
          await gapi.client.load("analytics", "v3")
        }

        const { result: accountSummaries } = await gapi.client.analytics.management.accountSummaries.list({})

        commit(
          UPDATE_GA_VIEWS,
          accountSummaries.items
            .reduce((tot, { webProperties = [], name }) => [
              ...tot,
              ...webProperties.reduce(
                (tot2, { profiles = [], name: appName }) => [
                  ...tot2,
                  ...profiles.map(entity => ({ ...entity, accountName: name, appName }))
                ], []
              )
            ], [])
        )
      }
    }
    catch ({ message }) {
      commit(`${ADMIN}/${ADD_GLOBAL_ERROR}`, { message }, { root: true })
    }
    finally {
      commit(`${ADMIN}/${UPDATE_LOADER}`, false, { root: true })
    }
  },
  async [FETCH_GA_REPORT]({ commit, dispatch, state }, start_time) {
    try {
      if (!state.connected_google_analytics_views.length) {
        // Set default empty values
        Array.from(google_analytics_data_types).forEach(type => commit(UPDATE_GA_REPORT, [[], type]))

        throw new Error(
          await translate("analytics.missing_GA_views")
        )
      }

      await dispatch(GOOGLE_AUTH, false)

      if (state.google_user_auth_data) {
        // Reset data
        Array.from(google_analytics_data_types).forEach(type => commit(UPDATE_GA_REPORT, [null, type]))

        // available Metrics and Dimensions can be found here:
        // https://ga-dev-tools.web.app/dimensions-metrics-explorer/

        let final_reports = []

        const reports = await Promise.all(state.connected_google_analytics_views.map(async ({ id }) => {
          const base_data = {
            viewId: id,
            dateRanges: [{ startDate: start_time, endDate: "today" }]
          }
          const customer_gender_info_sessions = {
            metrics: [{ expression: "ga:visitors" }],
            dimensions: [{ name: "ga:userGender" }],
          }
          const customer_age_info_sessions = {
            metrics: [{ expression: "ga:visitors" }],
            dimensions: [{ name: "ga:userAgeBracket" }],
          }
          const country_info = {
            metrics: [{ expression: "ga:visitors" }],
            dimensions: [{ name: "ga:country" }],
          }
          const new_and_returning = {
            metrics: [{ expression: "ga:visitors" }],
            dimensions: [{ name: "ga:date" }, { name: "ga:visitorType" }],
          }
          const customer_source = {
            metrics: [{ expression: "ga:visitors" }],
            dimensions: [{ name: "ga:source" }, { name: "ga:visitorType" }],
          }
  
          return await Promise.all([
              customer_gender_info_sessions,
              customer_age_info_sessions,
              country_info,
              new_and_returning,
              customer_source
            ].map(async (reportRequests) => {
              const { result: { reports: reportsData } } = await gapi.client.request({
                path: "/v4/reports:batchGet",
                root: "https://analyticsreporting.googleapis.com",
                method: "POST",
                body: {
                  reportRequests: {
                    ...base_data,
                    ...reportRequests
                  }
                }
              })
  
              return reportsData[0].data.rows || []
            })
          )
        }))

        reports
          .forEach(report =>
            Array.from(Array(report.length).keys())
              .forEach(index =>
                final_reports[index] = [...(final_reports[index] || []), ...report[index]]
              )
          )

        Array.from(google_analytics_data_types).forEach((type, index) => commit(UPDATE_GA_REPORT, [final_reports[index], type]))
      }
    }
    catch ({ response: { data = { error: {} } } = {}, message }) {
      commit(`${ADMIN}/${ADD_GLOBAL_ERROR}`, { message: data.error.error_user_msg || data.error.message || message }, { root: true })
    }
  },
  async [CONNECT_SOCIAL_MEDIA_ENTITIES]({ commit }, [entities, key]) {
    commit(`${ADMIN}/${UPDATE_LOADER}`, true, { root: true })
    try {
      const { data } = await add_projects_social_entities({ entities, key })

      commit(UPDATE_CONNECTED_ENTITIES, [key, data])
    }  catch (error) {
      commit(`${ADMIN}/${ADD_GLOBAL_ERROR}`, error, { root: true })
    }

    commit(`${ADMIN}/${UPDATE_LOADER}`, false, { root: true })
  },
  async [FETCH_SOCIAL_MEDIA]({ commit, rootState }) {
    commit(`${ADMIN}/${UPDATE_LOADER}`, true, { root: true })
    try {
      const is_analytics_config_loaded = Object.keys(rootState[ADMIN][CONFIGURATION].analytics_config).length
      const { data: { social_media_accounts, analytics_config } } = await fetch_social(!is_analytics_config_loaded)

      Object.entries(social_media_accounts).forEach(([key, data]) => commit(UPDATE_CONNECTED_ENTITIES, [key, data]))
      if (!is_analytics_config_loaded) Object
        .entries(analytics_config)
        .forEach(([key, data]) => commit(`${CONFIGURATION_STORE}/${UPDATE_ANALYTICS_CONFIG}`, [key, data], { root: true }))
    }  catch (error) {
      commit(`${ADMIN}/${ADD_GLOBAL_ERROR}`, error, { root: true })
    }

    commit(`${ADMIN}/${UPDATE_LOADER}`, false, { root: true })
  },
}
