export default (total_targeting, current_targeting) => {
  const get_key_value = route => route
    .split(".")
    .reduce((obj, key) => typeof obj === "object" ? obj[key] || obj : obj, total_targeting)

  const assemble_targeting = (value, key = "", route = "") => {
    route += key ? `.${key}` : ""

    if (typeof value === "object") {
      // If value is an Array, spread the values together and remove duplicates
      if (value.length) return Array.from(new Set([...value, ...(get_key_value(route) || [])]))

      // If value is an Object, go through each key and apply assemble_targeting function
      return Object.entries(value).reduce((tot, [inner_key, inner_value]) => ({
        ...tot,
        [inner_key]: assemble_targeting(inner_value, inner_key, route)
      }), {})
    }
    else {
      const current_targeting_value = get_key_value(route)
      route = ""
      
      // If value is a Number and it exists in the total_targeting object
      if (current_targeting_value && typeof value  === "number") {
        if (key.includes("_min")) value = value < current_targeting_value? value : current_targeting_value
        if (key.includes("_max")) value = value > current_targeting_value ? value : current_targeting_value
      }

      return value
    }
  }

  return assemble_targeting(current_targeting)
}