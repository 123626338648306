export const ga_event_categories = {
  checkout: "checkout",
  basket: "basket",
  pwa_prompt: "pwa_prompt",
  marketplace_basket: "marketplace_basket",
  product_category_banner: "product_category_banner",
  collection_banner: "collection_banner",
  highlighted_products: "highlighted_products",
  story_banner: "story_banner",
  liked_products: "liked_products",
  product: "product",
  product_slider: "product_slider"
}

export const ga_event_actions = {
  delete: "delete",
  add: "add",
  set: "set",
  change_quantity: "change_quantity",
  view: "view",
  completed: "completed",
  add_to_cart: "add_to_cart",
  added_price: "added_price",
  remove: "remove",
  click: "click",
  purchase: "purchase",
  open: "open",
  close: "close",
}
