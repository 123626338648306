const Default_page = () => import("../../applications/Client/components/base/Default_page.vue")

export const client_default_routes = {
  Default_page: "Home",
  Home: "Home",
  Basket: "Basket",
  Order: "Order",
  Thank_you: "Thank_you",
  Contact_us: "Contact_us",
  Legal_page: "Legal_page",
  About: "About"
}

export const default_route_strings = {
  basket: "basket",
  order: "order",
  thanks_you: "thanks-you",
  contact_us: "contact-us",
  legal_page: "legal-page",
  about: "about"
}

export default [
  {
    path: ":type?/:subtype?/:subsubtype?",
    name: client_default_routes.Default_page,
    component: Default_page
  },
  {
    path: default_route_strings.basket,
    name: client_default_routes.Basket,
    component: Default_page,
    alias: ["/basket", "/Basket"]
  },
  {
    path: `${default_route_strings.order}/:order_id/:store_id?`,
    name: client_default_routes.Order,
    component: Default_page,
    alias: ["/Order/:order_id/:store_id?", "/orders/:order_id/:store_id?", "/Orders/:order_id/:store_id?",]
  },
  {
    path: default_route_strings.thanks_you,
    name: client_default_routes.Thank_you,
    component: Default_page,
    alias: ["/thank-you", "/Thank-you"]
  },
  {
    path: default_route_strings.contact_us,
    name: client_default_routes.Contact_us,
    component: Default_page,
    alias: ["/contact-us", "/Contact-us"]
  },
  {
    path: `${default_route_strings.legal_page}/:type`,
    name: client_default_routes.Legal_page,
    component: Default_page,
    alias: ["/legal-page", "/Legal-page", "/legal-page/:type", "/Legal-page/:type"]
  },
  {
    path: default_route_strings.about,
    name: client_default_routes.About,
    component: Default_page,
    alias: ["/about", "/About"]
  },
]
